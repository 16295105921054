import { createActions } from 'redux-actions';

export default createActions({
  VIEWER3D: {
    SHOW_MODEL: undefined,
    HIDE_MODEL: undefined,
    LOAD_MODEL: undefined,
    SET_MODEL_SCALE: undefined,
    SET_MODEL_COLOR: undefined,
    SET_MODEL_OPACITY: undefined,
    SET_MODEL_OFFSET_TYPE: undefined,
    SET_MODEL_OFFSET_INDEX: undefined,

    SET_SHOW_BETWEEN_LINES: undefined,
    SET_SHOW_TIME_SLICE: undefined,
    SET_SHOW_PAST_ONLY: undefined,
    SET_SHOW_FUTURE_ONLY: undefined,

    SET_MIN_LINE: undefined,
    SET_MAX_LINE: undefined,
    SET_AUTOMATICALLY_UPDATE_LINES: undefined,
    SET_ISOLATE_SLIDER: undefined,
    SET_TIME_SLICE: undefined,

    SET_RAPID_COLOR: undefined,
    SET_FEED_COLOR: undefined,

    SET_OFFSET: undefined,
    RESET: undefined
  }
});
