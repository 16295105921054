import { connect } from 'react-redux';
import actions from '../actions/interpreter';

import GCodeLoader from '../components/GCodeLoader';
import { InterpreterCache } from '../gcode/interpreter-cache';

export default connect(
  (state, ownProps) => ({ 
    machineState: state.machineState
  }),
  (dispatch, ownProps) => ({
    dispatch
  }),
  (stateProps, dispatchProps, ownProps) => {
    const {
      machineState
    } = stateProps;
    const {
      dispatch
    } = dispatchProps;

    return {
      onFileSelect: (file) => {
        dispatch(actions.interpreter.setFileName(file.name));
        const interpreterCache = InterpreterCache.getInstance();

        interpreterCache.interpret(file, machineState);
      }
    }
  }
)(GCodeLoader);
