import { Group, Line, DynamicDrawUsage, BufferGeometry, BufferAttribute } from 'three';
import BackPlotShader from './backplot-shader';

export const RAPID_COLOR = new Float32Array([ 0, 0, .5 ]);
export const FEED_COLOR = new Float32Array([ 1, 1, 1 ]);

export const FAST_COLOR = new Float32Array([ 1, .5, 0 ]);
export const SLOW_COLOR = new Float32Array([ 0, 0, 1 ]);

// An object that stores all the THREE.js and WebGL data
// for a BackPlot. When passed another BackPlotLineObject
// it first copies that data over and allocates twice the
// amount that was allocated in the passed object.
class BackPlotLineObject {
  constructor(capacity_or_object, material) {
    const copyData = (typeof capacity_or_object === "object");
    if(copyData) {
      this.capacity = capacity_or_object.capacity*2;
    } else {
      this.capacity = capacity_or_object;
    }

    this.numPoints = 0;
    this.geometry = new BufferGeometry();
    this.vertices = new Float32Array(3*this.capacity); // x, y, z
    this.colors = new Float32Array(3*this.capacity);   // r, g, b
    this.normals = new Float32Array(3*this.capacity);   // x, y, z
    this.lines = new Float32Array(this.capacity); // line number of the move this point is a part of
    this.times = new Float32Array(this.capacity); // time that the program will be at this point
    this.colorIndices = new Float32Array(this.capacity);

    if(copyData) {
      const obj = capacity_or_object;

      this.vertices.set(obj.vertices);
      this.colors.set(obj.colors);
      this.normals.set(obj.normals);
      this.lines.set(obj.lines);
      this.times.set(obj.times);
      this.colorIndices.set(obj.colorIndices);

      this.geometry.setDrawRange(obj.geometry.drawRange.start,
                                 obj.geometry.drawRange.count);

      this.numPoints = obj.numPoints;
    }

    this.geometry.setAttribute('position', new BufferAttribute(this.vertices, 3));
    this.geometry.setAttribute('color', new BufferAttribute(this.colors, 3));
    this.geometry.setAttribute('normal', new BufferAttribute(this.normals, 3));
    this.geometry.setAttribute('line', new BufferAttribute(this.lines, 1));
    this.geometry.setAttribute('time', new BufferAttribute(this.times, 1));
    this.geometry.setAttribute('colorIndex', new BufferAttribute(this.colorIndices, 1));

    if(copyData) {
      this.geometry.attributes.position.updateRange.offset = 0;
      this.geometry.attributes.color.updateRange.offset = 0;
      this.geometry.attributes.normal.updateRange.offset = 0;
      this.geometry.attributes.line.updateRange.offset = 0;
      this.geometry.attributes.time.updateRange.offset = 0;
      this.geometry.attributes.colorIndex.updateRange.offset = 0;

      this.geometry.attributes.position.updateRange.count = 3*this.numPoints;
      this.geometry.attributes.color.updateRange.count = 3*this.numPoints;
      this.geometry.attributes.normal.updateRange.count = 3*this.numPoints;
      this.geometry.attributes.line.updateRange.count = this.numPoints;
      this.geometry.attributes.time.updateRange.count = this.numPoints;
      this.geometry.attributes.colorIndex.updateRange.count = this.numPoints;

      this.geometry.attributes.position.needsUpdate = true;
      this.geometry.attributes.color.needsUpdate = true;
      this.geometry.attributes.normal.needsUpdate = true;
      this.geometry.attributes.line.needsUpdate = true;
      this.geometry.attributes.time.needsUpdate = true;
      this.geometry.attributes.colorIndex.needsUpdate = true;
    }

    this.geometry.attributes.position.setUsage(DynamicDrawUsage);
    this.geometry.attributes.color.setUsage(DynamicDrawUsage);
    this.geometry.attributes.normal.setUsage(DynamicDrawUsage);
    this.geometry.attributes.line.setUsage(DynamicDrawUsage);
    this.geometry.attributes.time.setUsage(DynamicDrawUsage);
    this.geometry.attributes.colorIndex.setUsage(DynamicDrawUsage);
    this.geometry.setDrawRange(0, 0);

    this.object = new Line(this.geometry, material);
    this.object.raycast = () => {};
    this.object.frustumCulled = false;
  }

  clear() {
    this.numPoints = 0;
    this.updateDrawRange();
  }

  updateDrawRange() {
    this.geometry.setDrawRange(0, this.numPoints);
  }

  updateAttribute(attrName, vertexIndex, count) {
    const attr = this.geometry.attributes[attrName];
    if(attr.updateRange.count > -1) {
      attr.updateRange.count += count;
      attr.needsUpdate = true;
    } else {
      attr.updateRange.offset = vertexIndex;
      attr.updateRange.count = count;
      attr.needsUpdate = true;
    }
  }

  addPoint(pt) {
    const vertexIndex = 3*this.numPoints;
    this.vertices[vertexIndex] = pt.position[0];
    this.vertices[vertexIndex+1] = pt.position[1];
    this.vertices[vertexIndex+2] = pt.position[2];

    this.colors[vertexIndex] = pt.color[0];
    this.colors[vertexIndex+1] = pt.color[1];
    this.colors[vertexIndex+2] = pt.color[2];

    this.normals[vertexIndex] = pt.normal[0];
    this.normals[vertexIndex+1] = pt.normal[1];
    this.normals[vertexIndex+2] = pt.normal[2];

    this.lines[this.numPoints] = pt.line;
    this.times[this.numPoints] = pt.time;
    this.colorIndices[this.numPoints] = pt.colorIndex;

    this.updateAttribute("position", vertexIndex, 3);
    this.updateAttribute("color", vertexIndex, 3);
    this.updateAttribute("normal", vertexIndex, 3);
    this.updateAttribute("line", this.numPoints, 1);
    this.updateAttribute("time", this.numPoints, 1);
    this.updateAttribute("colorIndex", this.numPoints, 1);

    this.numPoints++;
    this.updateDrawRange();
  }
};

export default class BackPlot {
  static instance;

  static getInstance() {
    if(!this.instance) {
      this.instance = new BackPlot();
    }

    return this.instance;
  }

  constructor() {
    this.material = BackPlotShader();

    this.object = new Group();

    this.lineObject = new BackPlotLineObject(100000, this.material);

    this.object.frustumCulled = false;
    this.object.add(this.lineObject.object);
  }

  clear() {
    this.lineObject.clear();
  }
  setMaxXZ(maxXZ) {
    this.material.uniforms.maxXZ.value = maxXZ;
  }

  setMaxY(maxY) {
    this.material.uniforms.maxY.value = maxY;
  }

  setOffset(offset) {
    this.material.uniforms.offset.value = offset;
  }

  setMinLine(minLine) {
    this.material.uniforms.minLine.value = minLine;
  }
  setMaxLine(maxLine) {
    this.material.uniforms.maxLine.value = maxLine;
  }
  setMinTime(minTime) {
    this.material.uniforms.minTime.value = minTime;
  }
  setMaxTime(maxTime) {
    this.material.uniforms.maxTime.value = maxTime;
  }

  addPoints(pts) {
    pts.forEach((pt) => {
      if(this.lineObject.numPoints === this.lineObject.capacity) {
        this.object.remove(this.lineObject.object);

        this.lineObject = new BackPlotLineObject(this.lineObject, this.material);

        this.object.add(this.lineObject.object);
      }

      this.lineObject.addPoint(pt);
    });
  }
}
