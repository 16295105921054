import React, { useCallback, useState } from 'react';

import { styled } from '@mui/material/styles';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import machines from '../machines';

const PREFIX = 'MachineMenu';

const classes = {
  machinePane: `${PREFIX}-machinePane`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.machinePane}`]: {
    position: "fixed",
    right: 0,
    bottom: 0,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));

const machinesList = Object.entries(machines).filter(([id,machine]) => !machine.isSuperSecret || window.location.search.includes("showSuperSecretMachines")).sort((a,b) => {
  if(a[1].name < b[1].name) {
    return -1;
  } else if(a[1].name > b[1].name) {
    return 1;
  }
  return 0;
});

function MachineMenu(props) {
  const {
    machine,
    onChangeMachine,
    running
  } = props;

  const name = machines[machine].name;
  const [ menuOpen, setMenuOpen ] = useState(false);
  const [ anchorEl, setAnchorEl ] = useState(null);

  const handleClickOutOfMenu = useCallback(() => {
    setMenuOpen(false);
  }, [ setMenuOpen ]);

  const handleOpenMenu = useCallback((e) => {
    setMenuOpen(true);
    setAnchorEl(e.currentTarget);
  }, [ setMenuOpen, setAnchorEl ]);

  const handleMachineChange = useCallback((id) => (e) => {
    setMenuOpen(false);
    if(onChangeMachine) {
      onChangeMachine(id);
    }
  }, [ setMenuOpen, onChangeMachine ]);

  return (
    <Root className={classes.machinePane}>
      <Button disabled={running} variant="contained" onClick={handleOpenMenu}>{ name }</Button>
      <Menu open={menuOpen} anchorEl={anchorEl} onClose={handleClickOutOfMenu}>
        { machinesList.map(([ id, m ]) => <MenuItem disabled={running} key={id} onClick={handleMachineChange(id)}>{ m.name }</MenuItem> ) }
      </Menu>
    </Root>
  );
}

export default (MachineMenu);
