import actions from '../../actions/ui';
import { handleActions } from 'redux-actions';
import produce from 'immer';

const reducer = handleActions(
  {
    [actions.ui.controlsOverlay.setVisible]: produce((state, action) => { state.visible = action.payload }),
    [actions.ui.controlsOverlay.setAlwaysVisible]: produce((state,action) => { state.alwaysVisible = action.payload })
  },
  {
    alwaysVisible: false,
    visible: false
  }
);

export default reducer;
