import { FIVE_AXIS } from '../constants/machine-state/kinematics-mode';
import * as messages from '../gcode/interpreter-messages';
import { joints2table, joints2tcpc, tcpc2joints, rwo2joints, jointsDir2workDir } from './pocketnc_kinematics';
import { EPS } from '../constants';
import {
  selectJoints,
  selectKinematicsMode,
  selectKinematicsData,
  selectPosition,
  selectActiveWorkOffsets,
  selectActiveToolLengthOffset
} from '../selectors/machine-state';

import V2 from '../components/viewer3d/V2';

export const toolLength = (toolOffset) => {
  return 6.26+toolOffset;
};

export const computeRotatedWorkOffsets = (machineState) => {
  const position = selectPosition(machineState);
  const workOffsets = selectActiveWorkOffsets(machineState);
  const kinematicsData = selectKinematicsData(machineState);

  const Dy = kinematicsData.dy || 0;
  const Dz = kinematicsData.dz || 0;

  const Wx = workOffsets.X;
  const Wy = workOffsets.Y;
  const Wz = workOffsets.Z;
  const Wa = workOffsets.A*Math.PI/180;
  const Wb = workOffsets.B*Math.PI/180;

  const A = position.A*Math.PI/180 - Wa;
  const B = position.B*Math.PI/180 - Wb;

  const joints = rwo2joints(0,0,0,A,B,Wx,Wy,Wz,Wa,Wb,Dy,Dz,0)

  return { 
    X: joints.x,
    Y: joints.y,
    Z: joints.z,
    A: workOffsets.A,
    B: workOffsets.B,
    C: 0
  };
};

export const toolDirection = (machineState) => {
  const joints = selectJoints(machineState);
  const workOffsets = selectActiveWorkOffsets(machineState);

  const A = joints[3]*Math.PI/180;
  const B = joints[4]*Math.PI/180;
  const workB = workOffsets.B*Math.PI/180;

  // assumes A work offset is 0
  // TODO - SOFT-1176

  const workDir = jointsDir2workDir(0,0,1,A,B,0,workB);

  return { 
    X: workDir.x,
    Y: workDir.y,
    Z: workDir.z
  };
}

export const workPieceSpace = (machineState) => {
  const joints = selectJoints(machineState);
  const toolLengthOffset = selectActiveToolLengthOffset(machineState);
  const kinematicsData = selectKinematicsData(machineState);

  const xy = kinematicsData.xy || 0;
  const xz = kinematicsData.xz || 0;

  const yx = kinematicsData.yx || 0;
  const yz = kinematicsData.yz || 0;

  const zx = kinematicsData.zx || 0;
  const zy = kinematicsData.zy || 0;

  const Dy = kinematicsData.dx || 0;
  const Dz = kinematicsData.dz || 0;

  const toolZ = toolLengthOffset;

  const jointsX = joints[0] + yx * joints[1] + zx * joints[2];
  const jointsY = joints[1] + xy * joints[0] + zy * joints[2];
  const jointsZ = joints[2] + xz * joints[0] + yz * joints[1];
  const jointsA = joints[3]*Math.PI/180;
  const jointsB = joints[4]*Math.PI/180;

  const table = joints2table(jointsX, jointsY, jointsZ, jointsA, jointsB, Dy, Dz, toolZ);

  return {
    X: table.x,
    Y: table.y,
    Z: table.z
  };
}

export const forwardFiveAxis = (machineState, machineSpace) => {
  const joints = selectJoints(machineState);
  const toolLengthOffset = machineSpace ? 0 : selectActiveToolLengthOffset(machineState);
  const workOffsets = selectActiveWorkOffsets(machineState);
  const kinematicsData = selectKinematicsData(machineState);

  const xy = kinematicsData.xy || 0;
  const xz = kinematicsData.xz || 0;

  const yx = kinematicsData.yx || 0;
  const yz = kinematicsData.yz || 0;

  const zx = kinematicsData.zx || 0;
  const zy = kinematicsData.zy || 0;

  const Dy = kinematicsData.dy || 0;
  const Dz = kinematicsData.dz || 0;

  const workX = workOffsets.X;
  const workY = workOffsets.Y;
  const workZ = workOffsets.Z;
  const workA = workOffsets.A*Math.PI/180;
  const workB = workOffsets.B*Math.PI/180;
  const toolZ = toolLengthOffset;

  const jointsX = joints[0] + yx * joints[1] + zx * joints[2];
  const jointsY = joints[1] + xy * joints[0] + zy * joints[2];
  const jointsZ = joints[2] + xz * joints[0] + yz * joints[1];
  const jointsA = joints[3]*Math.PI/180;
  const jointsB = joints[4]*Math.PI/180;

  const tcpc = joints2tcpc(jointsX, jointsY, jointsZ, jointsA, jointsB, workX, workY, workZ, workA, workB, Dy, Dz, toolZ);

  return {
    X: tcpc.x + workX,
    Y: tcpc.y + workY,
    Z: tcpc.z + workZ,
    A: joints[3],
    B: joints[4],
    C: 0
  };
};

export const inverseFiveAxis = (machineState, machineSpace) => {
  const position = selectPosition(machineState);
  const toolLengthOffset = machineSpace ? 0 : selectActiveToolLengthOffset(machineState);
  const workOffsets = selectActiveWorkOffsets(machineState);
  const kinematicsData = selectKinematicsData(machineState);

  const xy = kinematicsData.xy || 0;
  const xz = kinematicsData.xz || 0;

  const yx = kinematicsData.yx || 0;
  const yz = kinematicsData.yz || 0;

  const zx = kinematicsData.zx || 0;
  const zy = kinematicsData.zy || 0;

  const Dy = kinematicsData.dy || 0;
  const Dz = kinematicsData.dz || 0;

  const workX = workOffsets.X;
  const workY = workOffsets.Y;
  const workZ = workOffsets.Z;
  const workA = workOffsets.A*Math.PI/180;
  const workB = workOffsets.B*Math.PI/180;
  const toolZ = toolLengthOffset;

  const X = position.X - workX;
  const Y = position.Y - workY;
  const Z = position.Z - workZ;
  const A = position.A*Math.PI/180 - workA;
  const B = position.B*Math.PI/180 - workB;

  const joints = tcpc2joints(X,Y,Z,A,B,workX,workY,workZ,workA,workB,Dy,Dz,toolZ);

  const Px = joints.x;
  const Py = joints.y;
  const Pz = joints.z;

  return [ 
    Px - Py*yx - Pz*zx,
    Py - Px*xy - Pz*zy,
    Pz - Px*xz - Py*yz,
    position.A,
    position.B
  ];
};

export const forwardIdentity = (machineState, machineSpace)  => {
  const joints = selectJoints(machineState);
  const toolLengthOffset = machineSpace ? 0 : selectActiveToolLengthOffset(machineState);
  const kinematicsData = selectKinematicsData(machineState);

  const xy = kinematicsData.xy || 0;
  const xz = kinematicsData.xz || 0;

  const yx = kinematicsData.yx || 0;
  const yz = kinematicsData.yz || 0;

  const zx = kinematicsData.zx || 0;
  const zy = kinematicsData.zy || 0;

  return { 
    X: joints[0] + yx*joints[1] + zx*joints[2], 
    Y: joints[1] + xy*joints[0] + zy*joints[2], 
    Z: joints[2] + xz*joints[0] + yz*joints[1] - toolLengthOffset, 
    A: joints[3], 
    B: joints[4],
    C: 0
  };
};

export const inverseIdentity = (machineState, machineSpace)  => {
  const position = selectPosition(machineState);
  const toolLengthOffset = machineSpace ? 0 : selectActiveToolLengthOffset(machineState);
  const kinematicsData = selectKinematicsData(machineState);

  const xy = kinematicsData.xy || 0;
  const xz = kinematicsData.xz || 0;

  const yx = kinematicsData.yx || 0;
  const yz = kinematicsData.yz || 0;

  const zx = kinematicsData.zx || 0;
  const zy = kinematicsData.zy || 0;

  const jointsX = position.X;
  const jointsY = position.Y;
  const jointsZ = position.Z + toolLengthOffset;

  const joints = [
    jointsX - jointsY*yx - jointsZ*zx, 
    jointsY - jointsX*xy - jointsZ*zy, 
    jointsZ - jointsX*xz - jointsY*yz, 
    position.A, 
    position.B
  ]

  return joints;
};

export const kinematics = {
  inverseKinematics: (machineState, machineSpace) => {
    const kinematicsMode = selectKinematicsMode(machineState);

    if(kinematicsMode === FIVE_AXIS) {
      return inverseFiveAxis(machineState, machineSpace);
    } else {
      return inverseIdentity(machineState, machineSpace);
    }
  },
  forwardKinematics: (machineState, machineSpace) => {
    const kinematicsMode = selectKinematicsMode(machineState);

    if(kinematicsMode === FIVE_AXIS) {
      return forwardFiveAxis(machineState, machineSpace);
    } else {
      return forwardIdentity(machineState, machineSpace);
    }
  }
};

export const limits = {
  extents: {
    max: [ 2.55, 2.55, 0.1, 135, 9999 ],
    min: [ -2.0, -2.5, -3.45, -25, -9999]
  },
  // x,y,z in inches/second
  // a,b in degrees/second
  velocities: [ 1, 1, 1, 37.5, 37.5 ],
  accelerations: [ 10, 10, 10, 300, 300 ]
};

export const jointLabels = [ "X", "Y", "Z", "A", "B" ];

export const getLimitErrors = (joints, limits) => {
  const errors = [];
  for(var i = 0; i < joints.length; i++) {
    const joint = joints[i];
    if(joint < limits.extents.min[i]-EPS) {
      // suggestion for z
      // suggestion: "lengthen tool by " + Math.abs(joint-limits.extents.min[i])
      errors.push(messages.MinimumLimitError(jointLabels[i], Math.abs(joint-limits.extents.min[i])));
    } else if(joint > limits.extents.max[i]+EPS) {
      // suggestsion for z
      // suggestion: "shorten tool by " + Math.abs(joint-limits.extends.max[i])
      errors.push(messages.MaximumLimitError(jointLabels[i], Math.abs(joint-limits.extents.max[i])));
    }
  }

  return errors.length > 0 ? errors : [];
};

export const name = "V2-10";
export const Machine = V2;
