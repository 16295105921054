import { connect } from 'react-redux';
import actions from '../actions';

import { selectDemo } from '../selectors';
import ControlsOverlay from '../components/ControlsOverlay';
import { InterpreterCache } from '../gcode/interpreter-cache';
import { FEATURES, GCODE_CLOSE } from '../constants/demo';

export default connect(
  (state, ownProps) => {
    const isolateSlider = state.viewer3d.isolateSlider;
    const showBetweenLines = state.viewer3d.showBetweenLines;

    const minLine = state.viewer3d.minLine;
    const maxLine = state.viewer3d.maxLine;

    let minValue = 0;
    let maxValue = 100;
    if(isolateSlider && showBetweenLines) {

      const interpreterCache = InterpreterCache.getInstance();

      const timeRange = [
        interpreterCache.timeAtLine(minLine),
        interpreterCache.timeAtLine(maxLine)
      ];
      const approximateTime = state.interpreter.approximateTime;

      if(approximateTime > 0) {
        minValue = timeRange[0]/approximateTime*100;
        maxValue = timeRange[1]/approximateTime*100;
      }
    }

    return {
      demo: selectDemo(state),
      isolateSlider: isolateSlider && showBetweenLines,
      minLine,
      maxLine,
      visible: state.ui.controlsOverlay.visible,
      alwaysVisible: state.ui.controlsOverlay.alwaysVisible || (state.ui.demo.step === FEATURES && state.ui.demo.featuresSubStep <= GCODE_CLOSE),
      gcodePaneVisible: state.ui.gcodePane.visible,
      loadedValue: state.interpreter.processingProgress,
      minValue,
      maxValue,
      value: state.interpreter.approximateTime > 0 ? state.interpreter.currentTime/state.interpreter.approximateTime*100 : 0,
      currentLine: state.interpreter.currentLine,
      currentTime: state.interpreter.currentTime,
      paused: state.interpreter.paused,
      running: state.interpreter.running,
      timeMultiplierValue: state.interpreter.timeMultiplier
    };
  },
  (dispatch, ownProps) => ({
    onShow: () => dispatch(actions.ui.controlsOverlay.setVisible(true)),
    onHide: () => dispatch(actions.ui.controlsOverlay.setVisible(false)),
    onTogglePane: () => dispatch(actions.ui.gcodePane.togglePane()),
    onHidePane: () => dispatch(actions.ui.gcodePane.hide()),
    onHome: () => dispatch(actions.ui.controlsOverlay.home()),
    onShuffle: () => dispatch(actions.ui.controlsOverlay.shuffle()),
    onPrevious: () => dispatch(actions.interpreter.previousLine()),
    onPlay: () => dispatch(actions.interpreter.unpause()),
    onRun: () => dispatch(actions.interpreter.run()),
    onStop: () => dispatch(actions.interpreter.stop()),
    onPause: () => dispatch(actions.interpreter.pause()),
    onNext: () => dispatch(actions.interpreter.nextLine()),
    onNextToolPath: (currentLine) => {
      const interpreterCache = InterpreterCache.getInstance();
      const { max, firstLine } = interpreterCache.getNextToolPathRange(currentLine);
      dispatch(actions.viewer3d.setMinLine(firstLine));
      dispatch(actions.viewer3d.setMaxLine(max));
      if(firstLine > currentLine) {
        dispatch(actions.interpreter.setCurrentLine(firstLine));
      } else {
        dispatch(actions.interpreter.setCurrentLine(max));
      }
    },
    onPreviousToolPath: (currentLine) => {
      const interpreterCache = InterpreterCache.getInstance();
      const { max, firstLine } = interpreterCache.getPreviousToolPathRange(currentLine);
      dispatch(actions.viewer3d.setMinLine(firstLine));
      dispatch(actions.viewer3d.setMaxLine(max));
      dispatch(actions.interpreter.setCurrentLine(firstLine));
    },
    onSlider: (v) => {
      if(typeof v === 'object') {
        dispatch(actions.interpreter.setCurrentLineWithPercentageLineBounded(v));
      } else {
        dispatch(actions.interpreter.setCurrentLineWithPercentage(v));
      }
    },
    onTimeSlider: (v) => dispatch(actions.interpreter.setTimeMultiplier(v)),
    onNextDemoStep: () => dispatch(actions.ui.demo.next()),
    onNextFeaturesSubStep: () => dispatch(actions.ui.demo.nextFeaturesSubStep()),
    onSetFeaturesSubStep: (step) => dispatch(actions.ui.demo.setFeaturesSubStep(step))
  })
)(ControlsOverlay);
