import { FIVE_AXIS } from '../constants/machine-state/kinematics-mode';
import * as messages from '../gcode/interpreter-messages';
import { joints2table, joints2tcpc, tcpc2joints, rwo2joints, jointsDir2workDir } from './solo_kinematics';
import { Vector3 } from 'three';
import { EPS } from '../constants';
import {
  selectJoints,
  selectKinematicsMode,
  selectKinematicsData,
  selectPosition,
  selectActiveWorkOffsets,
  selectActiveToolLengthOffset
} from '../selectors/machine-state';
//import produce from 'immer';

import Solo from '../components/viewer3d/Solo';

export const isSuperSecret = false;

export const toolLength = (toolLengthOffset) => {
  return 8+toolLengthOffset;
};

export const forwardFiveAxis = (machineState, machineSpace) => {
  const joints = selectJoints(machineState);
  const toolLengthOffset = machineSpace ? 0 : selectActiveToolLengthOffset(machineState);
  const workOffsets = selectActiveWorkOffsets(machineState);
  const kinematicsData = selectKinematicsData(machineState);

  const xy = kinematicsData.xy || 0;
  const xz = kinematicsData.xz || 0;

  const yx = kinematicsData.yx || 0;
  const yz = kinematicsData.yz || 0;

  const zx = kinematicsData.zx || 0;
  const zy = kinematicsData.zy || 0;

  const Dx = kinematicsData.dx || 0;
  const Dz = kinematicsData.dz || 0;

  const workX = workOffsets.X;
  const workY = workOffsets.Y;
  const workZ = workOffsets.Z;
  const workB = workOffsets.B*Math.PI/180;
  const workC = workOffsets.C*Math.PI/180;
  const toolZ = toolLengthOffset;

  const jointsX = joints[0] + yx * joints[1] + zx * joints[2];
  const jointsY = joints[1] + xy * joints[0] + zy * joints[2];
  const jointsZ = joints[2] + xz * joints[0] + yz * joints[1];
  const jointsB = joints[3]*Math.PI/180;
  const jointsC = joints[4]*Math.PI/180;

  const tcpc = joints2tcpc(jointsX, jointsY, jointsZ, jointsB, jointsC, workX, workY, workZ, workB, workC, Dx, Dz, toolZ);

  return {
    X: tcpc.x + workX,
    Y: tcpc.y + workY,
    Z: tcpc.z + workZ,
    A: 0,
    B: joints[3],
    C: joints[4]
  };
}; 

export const inverseFiveAxis = (machineState, machineSpace) => {
  const position = selectPosition(machineState);
  const toolLengthOffset = machineSpace ? 0 : selectActiveToolLengthOffset(machineState);
  const workOffsets = selectActiveWorkOffsets(machineState);
  const kinematicsData = selectKinematicsData(machineState);

  const xy = kinematicsData.xy || 0;
  const xz = kinematicsData.xz || 0;

  const yx = kinematicsData.yx || 0;
  const yz = kinematicsData.yz || 0;

  const zx = kinematicsData.zx || 0;
  const zy = kinematicsData.zy || 0;

  const Dx = kinematicsData.dx || 0;
  const Dz = kinematicsData.dz || 0;

  const workX = workOffsets.X;
  const workY = workOffsets.Y;
  const workZ = workOffsets.Z;
  const workB = workOffsets.B*Math.PI/180;
  const workC = workOffsets.C*Math.PI/180;
  const toolZ = toolLengthOffset;

  const X = position.X - workX;
  const Y = position.Y - workY;
  const Z = position.Z - workZ;
  const B = position.B*Math.PI/180 - workB;
  const C = position.C*Math.PI/180 - workC;

  const joints = tcpc2joints(X,Y,Z,B,C,workX,workY,workZ,workB,workC,Dx,Dz,toolZ);

  const Px = joints.x;
  const Py = joints.y;
  const Pz = joints.z;

  return [ 
    Px - Py*yx - Pz*zx,
    Py - Px*xy - Pz*zy,
    Pz - Px*xz - Py*yz,
    position.B,
    position.C
  ];
};

export const computeRotatedWorkOffsets = (machineState) => {
  const position = selectPosition(machineState);
  const workOffsets = selectActiveWorkOffsets(machineState);
  const kinematicsData = selectKinematicsData(machineState);

  const Dx = kinematicsData.dx || 0;
  const Dz = kinematicsData.dz || 0;

  const Wx = workOffsets.X;
  const Wy = workOffsets.Y;
  const Wz = workOffsets.Z;
  const Wb = workOffsets.B*Math.PI/180;
  const Wc = workOffsets.C*Math.PI/180;

  const B = position.B*Math.PI/180 - Wb;
  const C = position.C*Math.PI/180 - Wc;

  const joints = rwo2joints(0,0,0,B,C,Wx,Wy,Wz,Wb,Wc,Dx,Dz,0)

  return { 
    X: joints.x,
    Y: joints.y,
    Z: joints.z,
    A: 0,
    B: workOffsets.B,
    C: workOffsets.C
  };
}

// tool direction in work offset space
export const toolDirection = (machineState) => {
  const joints = selectJoints(machineState);
  const workOffsets = selectActiveWorkOffsets(machineState);

  const B = joints[3]*Math.PI/180;
  const C = joints[4]*Math.PI/180;
  const workC = workOffsets.C*Math.PI/180;

  // assumes B work offset is 0
  // TODO - SOFT-1176

  const workDir = jointsDir2workDir(0,0,1,B,C,0,workC);

  return { 
    X: workDir.x,
    Y: workDir.y,
    Z: workDir.z
  };
}

// Used to compute out of square compensations in workPieceSpace
// for properly plotting the tip of the tool in the back plot.
// Ideally, this would be done in pentakins. See SOFT-1346.
const oosOffset = new Vector3();
const yAxis = new Vector3(0,1,0);
const zAxis = new Vector3(0,0,1);

export const workPieceSpace = (machineState) => {
  const joints = selectJoints(machineState);
  const toolLengthOffset = selectActiveToolLengthOffset(machineState);
  const kinematicsData = selectKinematicsData(machineState);

  const xy = kinematicsData.xy || 0;
  const xz = kinematicsData.xz || 0;

  const yx = kinematicsData.yx || 0;
  const yz = kinematicsData.yz || 0;

  const zx = kinematicsData.zx || 0;
  const zy = kinematicsData.zy || 0;

  const Dx = kinematicsData.dx || 0;
  const Dz = kinematicsData.dz || 0;

  const toolZ = toolLengthOffset;

  const jointsX = joints[0] + yx * joints[1] + zx * joints[2];
  const jointsY = joints[1] + xy * joints[0] + zy * joints[2];
  const jointsZ = joints[2] + xz * joints[0] + yz * joints[1];
  const jointsB = joints[3]*Math.PI/180;
  const jointsC = joints[4]*Math.PI/180;

  const table = joints2table(jointsX, jointsY, jointsZ, jointsB, jointsC, Dx, Dz, toolZ);

// Compute out of square compensations for properly plotting 
// the tip of the tool in the back plot. Ideally, this would 
// be done in pentakins. See SOFT-1346.
  oosOffset.x = zx*toolZ; 
  oosOffset.y = zy*toolZ;
  oosOffset.z = 0;
  oosOffset.applyAxisAngle(yAxis, -jointsB);
  oosOffset.applyAxisAngle(zAxis, -jointsC);

  return {
    X: table.x - oosOffset.x,
    Y: table.y - oosOffset.y,
    Z: table.z - oosOffset.z
  };
}

export const forwardIdentity = (machineState, machineSpace)  => {
  const joints = selectJoints(machineState);
  const toolLengthOffset = machineSpace ? 0 : selectActiveToolLengthOffset(machineState);
  const kinematicsData = selectKinematicsData(machineState);

  const xy = kinematicsData.xy || 0;
  const xz = kinematicsData.xz || 0;

  const yx = kinematicsData.yx || 0;
  const yz = kinematicsData.yz || 0;

  const zx = kinematicsData.zx || 0;
  const zy = kinematicsData.zy || 0;

  const position = { 
    X: joints[0] + yx*joints[1] + zx*joints[2], 
    Y: joints[1] + xy*joints[0] + zy*joints[2], 
    Z: joints[2] + xz*joints[0] + yz*joints[1] - toolLengthOffset, 
    A: 0,
    B: joints[3], 
    C: joints[4] 
  };

  return position;
};

export const inverseIdentity = (machineState, machineSpace)  => {
  const position = selectPosition(machineState);
  const toolLengthOffset = machineSpace ? 0 : selectActiveToolLengthOffset(machineState);
  const kinematicsData = selectKinematicsData(machineState);

  const xy = kinematicsData.xy || 0;
  const xz = kinematicsData.xz || 0;

  const yx = kinematicsData.yx || 0;
  const yz = kinematicsData.yz || 0;

  const zx = kinematicsData.zx || 0;
  const zy = kinematicsData.zy || 0;

  const jointsX = position.X;
  const jointsY = position.Y;
  const jointsZ = position.Z + toolLengthOffset;

  const joints = [
    jointsX - jointsY*yx - jointsZ*zx, 
    jointsY - jointsX*xy - jointsZ*zy, 
    jointsZ - jointsX*xz - jointsY*yz, 
    position.B, 
    position.C
  ]

  return joints;
};

export const kinematics = {
  inverseKinematics: (machineState, machineSpace) => {
    const kinematicsMode = selectKinematicsMode(machineState);

    if(kinematicsMode === FIVE_AXIS) {
      return inverseFiveAxis(machineState, machineSpace);
    } else {
      return inverseIdentity(machineState, machineSpace);
    }
  },
  forwardKinematics: (machineState, machineSpace) => {
    const kinematicsMode = selectKinematicsMode(machineState);

    if(kinematicsMode === FIVE_AXIS) {
      return forwardFiveAxis(machineState, machineSpace);
    } else {
      return forwardIdentity(machineState, machineSpace);
    }
  }
};

export const limits = {
  extents: {
    max: [ 6.623, 3.38785, .01, 145, 99999 ],
    min: [ -3.03136, -3.5136, -6.24, -145, -99999]
  },
  // x,y,z in inches/second
  // a,b in degrees/second
  velocities: [ 9.84, 9.84, 9.84, 114.7, 114.7 ],
  accelerations: [ 656, 656, 656, 16000, 16000 ]
};

export const jointLabels = [ "X", "Y", "Z", "B", "C" ];

export const getLimitErrors = (joints, limits) => {
  const errors = [];
  for(var i = 0; i < joints.length; i++) {
    const joint = joints[i];
    if(joint < limits.extents.min[i]-EPS) {
      // suggestion for z
      // suggestion: "lengthen tool by " + Math.abs(joint-limits.extents.min[i])
      errors.push(messages.MinimumLimitError(jointLabels[i], Math.abs(joint-limits.extents.min[i])));
    } else if(joint > limits.extents.max[i]+EPS) {
      // suggestsion for z
      // suggestion: "shorten tool by " + Math.abs(joint-limits.extends.max[i])
      errors.push(messages.MaximumLimitError(jointLabels[i], Math.abs(joint-limits.extents.max[i])));
    }
  }

  return errors.length > 0 ? errors : [];
};

export const name = "Solo";
export const Machine = Solo;
