import { combineReducers } from 'redux';

import gcodePane from './gcode-pane';
import controlsOverlay from './controls-overlay';
import titleBar from './title-bar';
import demo from './demo';
import options from './options';

const reducer = combineReducers({
  gcodePane,
  controlsOverlay,
  titleBar,
  demo,
  options
});

export default reducer;
