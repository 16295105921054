import { createSelector } from 'reselect';
import * as messagesSelectors from './messages';
import { MM, IN } from '../constants/machine-state/units';

export const messages = messagesSelectors;

export const interpreter = (state) => state.interpreter;
export const machineState = (state) => state.machineState;
export const lines = (state) => state.interpreter.lines;
export const linesPerIndex = (state) => state.interpreter.linesPerIndex;
export const approximateTime = (state) => state.interpreter.times[state.interpreter.times.length-1];
export const initialUnits = (state) => state.interpreter.initialState.units;
export const programUnits = createSelector( [ initialUnits, messages.lastSetUnitsMessage ], (initialUnits, lastSetUnitsMessage) => {
  if(lastSetUnitsMessage) {
    if(lastSetUnitsMessage.code === "G21") {
      return MM;
    } else {
      return IN;
    }
  }
  return initialUnits;
})
export const loadedTool = (state) => {
  const toolState = state.tool;
  const toolTable = toolState.table;
  const loaded = toolState.loaded;
  return toolTable[loaded];
};

export const selectDemo = (state) => state.ui.demo;
