import actions from '../../actions';
import { handleActions } from 'redux-actions';
import produce from 'immer';
import { MAX_STEPS, MAX_SUB_STEPS } from '../../constants/demo';

const reducer = handleActions(
  {
    [actions.ui.demo.enable]: produce((state, action) => { state.enabled = true }),
    [actions.ui.demo.disable]: produce((state, action) => { state.enabled = false }),
    [actions.ui.demo.next]: produce((state, action) => { state.step = Math.min(state.step+1, MAX_STEPS-1) }),
    [actions.ui.demo.previous]: produce((state, action) => { state.step = Math.max(0, state.step-1) }),
    [actions.ui.demo.reset]: produce((state, action) => { state.step = 0 }),
    [actions.ui.demo.set]: produce((state,action) => {state.step = action.payload }),
    [actions.ui.demo.setQr]: produce((state,action) => { state.qr = action.payload }),
    [actions.ui.demo.setFeaturesSubStep]: produce((state,action) => { state.featuresSubStep = action.payload }),
    [actions.ui.demo.nextFeaturesSubStep]: produce((state,action) => { state.featuresSubStep = Math.min(state.featuresSubStep+1, MAX_SUB_STEPS) })
  },
  {
    enabled: false,
    step: 0,
    qr: null,
    featuresSubStep: 0
  }
);

export default reducer;
