import actions from '../../actions/interpreter';
import { handleActions } from 'redux-actions';

// An index is a cached machineState associated with a specific line of gcode.
// These variables balance speed of computation with memory usage by adjusting how many indices 
// we keep track of. To look up the machine state for a given line of the gcode program we'll 
// look up the closest previous index as a starting state, then reinterpret up to the desired line.
export const MAX_LINES_PER_INDEX = 300;
export const TARGET_INDICES = 100000;

export const defaultState = {
  messages: [],
  initialState: {},
  approximateTime: 0,
  fileName: "sim.ngc",
  numLines: 0,
  loading: false,
  loadingProgress: 0,
  processing: false,
  processingProgress: 0,
  currentLine: 0,
  currentTime: 0,
  paused: true,
  running: false,
  timeMultiplier: 1
};

export const reducer = handleActions(
  {
    [actions.interpreter.setApproximateTime]: (state, action) => ({ ...state, approximateTime: action.payload }),
    [actions.interpreter.setNumLines]: (state, action) => ({ ...state, numLines: action.payload }),
    [actions.interpreter.setInitialState]: (state, action) => ({ ...state, initialState: action.payload }),
    [actions.interpreter.setFileName]: (state, action) => ({ ...state, fileName: action.payload }),
    [actions.interpreter.startProcessing]: (state, action) => ({
      ...state,
      processingProgress: 0,
      paused: true,
      running: true,
      processing: true,
      messages: []
    }),
    [actions.interpreter.endProcessing]: (state, action) => ({ ...state, processing: false }),
    [actions.interpreter.setProcessingProgress]: (state, action) => ({ ...state, processingProgress: action.payload }),
    [actions.interpreter.pause]: (state, action) => ({ ...state, paused: true }),
    [actions.interpreter.stop]: (state, action) => ({ ...state, running: false }),
    [actions.interpreter.unpause]: (state, action) => ({ ...state, paused: false }),
    [actions.interpreter.run]: (state, action) => ({ ...state, running: true, currentLine: 0, currentTime: 0 }),
    [actions.interpreter.setTimeMultiplier]: (state, action) => ({ ...state, timeMultiplier: action.payload }),
    [actions.interpreter.setCurrentLineAndTimeNoUpdates]: (state,{ payload: { currentLine, currentTime }}) => ({ ...state, currentLine, currentTime }),
    [actions.interpreter.appendMessages]: (state,action) => {
      const messages = [];
      for(let i = 0; i < state.messages.length; i++) {
        messages.push(state.messages[i]);
      }
      for(let i = 0; i < action.payload.length; i++) {
        messages.push(action.payload[i]);
      }

      return {
        ...state,
        messages: messages
      };
    },
    [actions.interpreter.incrementTime]: (state, action) => {
      const {
        currentTime,
        timeMultiplier,
        approximateTime
      } = state;
      if(currentTime+action.payload*timeMultiplier > approximateTime) {
        return { ...state, paused: true };
      }
      return state;
    },
    [actions.interpreter.nextLine]: (state, action) => ({ ...state, paused: true }),
    [actions.interpreter.previousLine]: (state, action) => ({ ...state, paused: true }),
    [actions.interpreter.setCurrentLineWithPercentage]: (state, action) => ({ ...state, paused: true }),
    [actions.interpreter.setCurrentLineWithPercentageLineBounded]: (state, action) => ({ ...state, paused: true }),
    [actions.interpreter.setCurrentLineWithTime]: (state, action) => ({ ...state, paused: true }),
    [actions.interpreter.setCurrentLine]: (state, action) => ({ ...state, paused: true })
  },
  defaultState
);

export default reducer;
