import { connect } from 'react-redux';
import Progress from '../components/Progress';

export default connect(
  (state, ownProps) => {
    const value = state.interpreter.processingProgress;
    const thickness = 3.6;
    const loading = state.interpreter.processing;

    return { value, thickness, loading };
  },
  (dispatch, ownProps) => ({
  })
)(Progress);
