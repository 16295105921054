import machineState from './machine-state';
import viewer3d from './viewer3d';

export const EPS = .000001; // epsilon, for floating point comparisons

const constants = {
  machineState,
  viewer3d
};

export default constants;
