import React from 'react';

import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import classnames from 'classnames';

import InputBase from '@mui/material/InputBase';

const PREFIX = 'TextFieldWithButton';

const classes = {
  label: `${PREFIX}-label`,
  disabled: `${PREFIX}-disabled`,
  container: `${PREFIX}-container`,
  containerDisabled: `${PREFIX}-containerDisabled`,
  button: `${PREFIX}-button`,
  textField: `${PREFIX}-textField`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.label}`]: {
    display: "flex"
  },

  [`& .${classes.disabled}`]: {
    color: "lightgray"
  },

  [`& .${classes.container}`]: {
    height: 40,
    borderWidth: 2,
    borderRadius: 5,
    borderColor: "gray",
    borderStyle: "solid"
  },

  [`& .${classes.containerDisabled}`]: {
    height: 40,
    borderWidth: 2,
    borderRadius: 5,
    borderColor: "lightgray",
    borderStyle: "solid"
  },

  [`& .${classes.button}`]: {
    border: "none",
    height: "100%",
    float: "right",
    textAlign: "center",
    padding: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    minWidth: 32,
    paddingLeft: 4,
    paddingRight: 2,
  },

  [`& .${classes.textField}`]: {
    display: "flex",
    height: "100%",
    marginLeft: theme.spacing(1)
  }
}));

const TextFieldWithButton = (props) => {
  const {
    label,
    value,
    onClick,
    onChange,
    disabled,
    buttonContent,
    buttonProps,
    containerProps,
    textFieldProps
  } = props;



  return (
    (<Root>
      <div className={classnames(classes.label, { [classes.disabled]: disabled })}>{ label }</div>
      <Box
        className={classnames({ [classes.container]: !disabled, 
                                [classes.containerDisabled]: disabled })}
        {...containerProps}
      >
      { buttonContent ?
        <Button
            color="inherit"
            className={classes.button}
            onClick={onClick}
            {...buttonProps}
            disabled={disabled}
          >
          {buttonContent}
          </Button>
          : null }
          <InputBase
            className={classes.textField}
            disabled={disabled}
            value={value}
            onChange={onChange}
            {...textFieldProps}
          />
        </Box>
    </Root>)
  );
};
export default TextFieldWithButton;
