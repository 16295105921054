import { all } from 'redux-saga/effects';
import interpreter from './interpreter';
import loadQueryParams from './load-query-params';
import simulationZipSaga from './simulation-zip-processing-queue';

export default function* rootSaga() {
  yield all([ interpreter(), 
              loadQueryParams(),
              simulationZipSaga()
              ]);
};
