import { useEffect } from 'react';
import BackPlot from '../../viewer3d/backplot';
import { Color } from 'three';

export default function Model(props) {
  const {
    currentTime,
    showBetweenLines,
    showTimeSlice,
    showPastOnly,
    showFutureOnly,
    
    minLine,
    maxLine,

    timeSlice,

    rapidColor,
    feedColor,

    offset
  } = props;
  const backplot = BackPlot.getInstance();

  useEffect(() => {
    if(showTimeSlice) {
      backplot.material.uniforms.minTime.value = currentTime- (showFutureOnly ? 0 : timeSlice);
      backplot.material.uniforms.maxTime.value = currentTime+ (showPastOnly ? 0 : timeSlice);
    } else {
      backplot.material.uniforms.minTime.value = showFutureOnly ? currentTime : 0;
      backplot.material.uniforms.maxTime.value = showPastOnly ? currentTime : 9999999999;
    }

    if(showBetweenLines) {
      backplot.material.uniforms.minLine.value = minLine;
      backplot.material.uniforms.maxLine.value = maxLine;
    } else {
      backplot.material.uniforms.minLine.value = 0;
      backplot.material.uniforms.maxLine.value = 9999999999;
    }

    backplot.material.uniforms.offset.value = offset;

    backplot.material.uniforms.colors.value[0].set(new Color(rapidColor).convertLinearToSRGB());
    backplot.material.uniforms.colors.value[1].set(new Color(feedColor).convertLinearToSRGB());
  }, [ backplot, 
       currentTime, 
       showBetweenLines,
       showTimeSlice,
       showPastOnly,
       showFutureOnly,
       minLine, 
       maxLine, 
       offset, 
       timeSlice,
       rapidColor,
       feedColor ]);

  return <primitive object={backplot.object}/>
};
