import { connect } from 'react-redux';
import { SOLO } from '../constants/machine-state/machine';

import DRO from '../components/DRO';
import * as selectors from '../selectors';
import { inchesToUnits } from '../util/units';

export default connect(
  (state, ownProps) => {
    const machine = state.machineState.machine;
    const motion = state.machineState.motion;
    const position = motion.position;
    const offsets = state.machineState.motion.g5x.offsets[state.machineState.motion.g5x.index];
    const units = selectors.programUnits(state);

    return {
      hideTitleBar: !state.ui.titleBar.visible,
      X: inchesToUnits(position.X-offsets.X,units).toFixed(4).padStart(8, ' '),
      Y: inchesToUnits(position.Y-offsets.Y,units).toFixed(4).padStart(8, ' '),
      Z: inchesToUnits(position.Z-offsets.Z,units).toFixed(4).padStart(8, ' '),
      A: machine !== SOLO ? (position.A-offsets.A).toFixed(4).padStart(8, ' ') : undefined,
      B: (position.B-offsets.B).toFixed(4).padStart(8, ' '),
      C: machine === SOLO ? (position.C-offsets.C).toFixed(4).padStart(8, ' ') : undefined,
      T: state.machineState.tool.loaded
    };
  },
  (dispatch, ownProps) => ({
  })
)(DRO);
