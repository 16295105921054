import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

function CopyEmbedWidget(props) {
  let url = document.location.toString();
  if(url.indexOf("?") > -1 && url.indexOf("gcodePane") === -1) {
    url += "&gcodePane=hide";
  }
  if(url.indexOf("?") > -1 && url.indexOf("titleBar") === -1) {
    url += "&titleBar=hide";
  }


  const embedData = `<iframe src="${url}" width="100%" height="500px" frameBorder="0"></iframe>`;
  const handleCopy = () => {
    if(navigator && navigator.clipboard) {
      navigator.clipboard.writeText(embedData);
    }
  };

  return <>
  Embed
  <div>
  <TextField
    inputProps={{ readOnly: "readonly" }}
    variant="outlined"
    defaultValue={embedData}
  />
  <Button onClick={handleCopy}>Copy Embed</Button>
  </div>
  </>;
}

export default CopyEmbedWidget;
