import { useState, useRef, useEffect } from 'react';
import { create } from 'zustand';
import { useFrame, useThree } from '@react-three/fiber';
import BlendCamera from './BlendCamera';
import { Vector2, Vector3, Quaternion, Matrix4, Ray } from 'three';
import now from 'performance-now';
import { useGesture } from '@use-gesture/react';


const EPS = .000001;

export const InteractiveLayer = 1 << 31;

// selectors
export const selectActiveParent = (state) => state.activeParent; 
export const selectParents = (state) => state.parents; 
export const selectTheta = (state) => state.theta; 
export const selectPhi = (state) => state.phi; 
export const selectRadius = (state) => state.radius; 
export const selectBlendPower = (state) => state.blendPower; 
export const selectBlend = (state) => state.blend; 
export const selectFilter = (state) => state.filter; 

export const selectSetOrthographic = (state) => state.setOrthographic; 
export const selectSetPerspective = (state) => state.setPerspective; 
export const selectSetAuto = (state) => state.setAuto; 
export const selectAlignWithDirection = (state) => state.alignWithDirection;
export const selectAddParent = (state) => state.addParent;
export const selectRemoveParent = (state) => state.removeParent;
export const selectAddLocalOrientationObject = (state) => state.addLocalOrientationObject;
export const selectRemoveLocalOrientationObject = (state) => state.removeLocalOrientationObject;
export const selectSetActiveParent = (state) => state.setActiveParent;
export const selectSetFilter = (state) => state.setFilter;
export const selectSetUpAndForward = (state) => state.setUpAndForward

// parent should be an object like so:
// {
//   name: "Label that appears in UI",
//   object: react-three/fiber primative ref object
// }
export const useCameraControls = create(set => ({
  activeParent: null,
  parents: [],
  theta: -45*Math.PI/180,
  phi: 45*Math.PI/180,
  orthographic: false,
  radius: 10,
  excessR: 0,

  upAndForwardOrientation: new Quaternion(),

  // Objects that should be updated with the current local orientation every frame
  // i.e. the view cube
  localOrientationObjects: [],

  // Linear interpolation between orthographic and perspective cameras does not provide
  // a linear transition. Putting the blend parameter through a power function helps to make
  // animations between one or the other more natural. Tweaking blendPower affects how 
  // slow/fast the transition happens.
  // Higher numbers make 
  blendPower: 1000,
  blend: 0,

  auto: true,

  filter: .1,

  setUpAndForward: (up, forward) => set(state => {
    const right = new Vector3();
    right.crossVectors(forward, up);
    const upAndForwardMatrix = new Matrix4();
    upAndForwardMatrix.set(right.x, right.y, right.z, 0,
                           up.x, up.y, up.z, 0,
                           -forward.x, -forward.y, -forward.z, 0,
                           0, 0, 0, 1);
    const upAndForwardOrientation = new Quaternion();
    upAndForwardOrientation.setFromRotationMatrix(upAndForwardMatrix);
    upAndForwardOrientation.conjugate();

    return ({ upAndForwardOrientation });
  }),
  setFilter: (filter) => set(state => ({ filter })),
  setActiveParent: (parent) => set(state => ({ activeParent: parent })),
  addLocalOrientationObject: (obj) => set(state => ({ localOrientationObjects: [ ...state.localOrientationObjects, obj ]  })),
  removeLocalOrientationObject: (obj) => set(state => {  
    const index = state.localOrientationObjects.indexOf(obj);
    if(index > -1) {
      const newObjects = [ ...state.localOrientationObjects ];
      newObjects.splice(index, 1);
      return { localOrientationObjects: newObjects };
    }
    return { };
  }),
  addParent: (parent) => set(state => ({ parents: [ ...state.parents, parent ]  })),
  removeParent: (parent) => set(state => {  
    const index = state.parents.indexOf(parent);
    if(index > -1) {
      const newParents = [ ...state.parents ];
      newParents.splice(index, 1);
      return { parents: newParents };
    }
    return { parents: state.parents };
  }),

  setCameraOrientation: (theta,phi) => set(state => ({
    phi: Math.max(EPS, Math.min(Math.PI-EPS, phi)),
    theta
  })),
  rotateCamera: (deltaTheta,deltaPhi) => set(state => ({
    phi: Math.max(EPS, Math.min(Math.PI-EPS, state.phi+deltaPhi)),
    theta: state.theta+deltaTheta
  })),
  zoomCamera: (deltaZoom) => set(state => {
    let radius = state.radius;

    if(deltaZoom < 0) {
      radius *= .95;
    } else if(deltaZoom > 0) {
      radius /= .95;
    }

    // capture excessR so when we reach our maximum zoom, we go out just a bit further
    // so it doesn't feel like nothing is happening, but we still cap our zoom amount
    // The final wheel or pinch event will have a delta of 0, so this will reset to 0
    // at the end of a gesture. The effect is there is always an indication that you're
    // zooming out, even when at the maximum.
    const excessR = radius - Math.min(100, Math.max(.1, radius));

    return {
      radius: Math.min(100, Math.max(.1, radius)),
      excessR
    };
  }),
  setRadius: (radius) => set(state => {
    return {
      radius: Math.min(100, Math.max(.1, radius))
    };
  }),
  setPerspective: () => set(state => ({
    blend: 0,
    blendPower: 1.5*state.radius,
    auto: false
  })),
  setOrthographic: () => set(state => ({
    blend: 1,
    blendPower: .4*state.radius,
    auto: false
  })),
  setAuto: () => set(state => ({
    auto: true
  })),
  alignWithDirection: (x,y,z) => set(state => {
    const radius = Math.sqrt( x*x+y*y+z*z);
    let phi = 0;
    let theta = 0;
    if(radius !== 0) {
      theta = Math.atan2(x,z);
      phi = Math.acos(Math.max(Math.min(y/radius, 1), -1));
    }

    if(phi <= EPS || phi >= Math.PI-EPS) {
      theta = state.theta;
    }

    return { phi, theta };
  })
}));

export const X = new Vector3(1,0,0);
export const Y = new Vector3(0,1,0);
export const Z = new Vector3(0,0,1);

export const NEG_X = new Vector3(-1,0,0);
export const NEG_Y = new Vector3(0,-1,0);
export const NEG_Z = new Vector3(0,0,-1);

// temporary variables for doing math
const rotatedX = new Vector3(1,0,0);
const rotatedZ = new Vector3(0,0,1);
const thetaOrientation = new Quaternion();
const phiOrientation = new Quaternion();

const parentOrientation = new Quaternion();
const parentPosition = new Vector3();
const localOrientation = new Quaternion();
const worldOrientation = new Quaternion();
const worldPosition = new Vector3();

const pointer = new Vector2();

export class FilterClock  {
  constructor() {
    this.startTime = now();
  }

  offsetStart(dt) {
    this.startTime += dt;
  }

  getElapsedTime() {
    return now()-this.startTime;
  }
}

function CameraControls(props) {
  const { gl, size, raycaster, camera, scene } = useThree();
  const [ clock ]  = useState(new FilterClock());
  const {
    targetFPS = 120
  } = props;

  useEffect(() => {
    raycaster.rayOrthographic = new Ray();
    raycaster.axis = new Vector3();
    if(!raycaster.originalSetFromCamera) {
      raycaster.originalSetFromCamera = raycaster.setFromCamera;
    }
    raycaster.setFromCamera = (function(coords, camera) {
      if( camera.isBlendPerspectiveOrthographicCamera) {
        if(camera.isPerspectiveCamera) {
          this.ray.origin.setFromMatrixPosition( camera.matrixWorld );
          this.ray.direction.set( coords.x, coords.y, 0.5 ).applyMatrix4( camera.perspectiveMatrixInverse ).applyMatrix4( camera.matrixWorld ).sub( this.ray.origin ).normalize();
        } else {
          this.ray.origin.set( coords.x, coords.y, ( camera.near + camera.far ) / ( camera.near - camera.far ) ).applyMatrix4( camera.orthographicMatrixInverse ).applyMatrix4( camera.matrixWorld ); // set origin in plane of camera
          this.ray.direction.set( 0, 0, - 1 ).transformDirection( camera.matrixWorld );
        }

        this.camera = camera;
      } else {
        this.originalSetFromCamera(coords,camera);
      }

    }).bind(raycaster);
  }, [ raycaster ]);

  const cameraRef = useRef();
  const {
    activeParent,
    localOrientationObjects,
    auto,
    blend,
    blendPower,
    filter,
    phi,
    theta,
    radius,
    excessR,
    rotateCamera,
    zoomCamera,
    upAndForwardOrientation
  } = useCameraControls();

  useEffect(() => {
    gl.domElement.style.touchAction = "none";
    const preventDefault = (e) => e.preventDefault();

    document.addEventListener('gesturestart', preventDefault)
    document.addEventListener('gesturechange', preventDefault);

    return () => {
      document.removeEventListener('gesturestart', preventDefault)
      document.removeEventListener('gesturechange', preventDefault);
    };
  }, [ gl ]);

  useGesture({
    onDrag: (e) => {
      const {
        first,
        delta: [ dx, dy ],
        xy: [ x, y ],
        cancel
      } = e;
      if(first) {
        const rect = e.target.getBoundingClientRect();
        const parentX = rect.left;
        const parentY = rect.top;
        const offsetX = x-parentX;
        const offsetY = y-parentY;
        pointer.set((offsetX / size.width) * 2 - 1, -(offsetY / size.height) * 2 + 1);
        raycaster.setFromCamera(pointer, camera)
//        const mask = raycaster.layers.mask;

//        raycaster.layers.mask = InteractiveLayer;
        const h = raycaster.intersectObjects([ scene ])[0];
//        raycaster.layers.mask = mask;
        if(h && h.object && h.object.layers.mask & InteractiveLayer) {
          cancel();
          return;
        }
      }
      if(e.touches < 2) {
        rotateCamera( -dx/size.width * 4*Math.PI, -dy/size.height * 4*Math.PI);
      }
    },
    onWheel: (e) => {
      const {
        delta
      } = e;
      const dy = delta[1];
      zoomCamera(dy);
    },
    onPinch: (e) => {
      const {
        delta: [ dx ]
      } = e;
      zoomCamera(-dx);
    }
  }, 
  { 
    target: gl.domElement, 
    eventOptions: { passive: false },
    drag: {
      pointer: { touch: true }
    },
    wheel: {
      preventDefault: true
    },
    pinch: {
      pointer: { touch: true }
    },
  });

  useFrame(() => {
    const cam = cameraRef.current;

    const performFiltering = () => {
      worldPosition.x = (radius+excessR)*Math.sin(phi)*Math.sin(theta);
      worldPosition.y = (radius+excessR)*Math.cos(phi);
      worldPosition.z = (radius+excessR)*Math.sin(phi)*Math.cos(theta);

      thetaOrientation.setFromAxisAngle(Y, theta);
      phiOrientation.setFromAxisAngle(rotatedX.copy(X).applyQuaternion(thetaOrientation), -Math.PI/2+phi);

      worldOrientation.copy(thetaOrientation);
      worldOrientation.premultiply(phiOrientation);

      rotatedZ.copy(Z).applyQuaternion(worldOrientation);

      localOrientation.copy(worldOrientation);
      localOrientation.conjugate();
      localOrientationObjects.forEach((obj) => {
        obj.quaternion.slerp(localOrientation, filter);
      });

      let localBlend = blend;
      let localBlendPower = blendPower;

      if(auto) {
        if( Math.abs(rotatedZ.dot(X)-1) < EPS  ||
            Math.abs(rotatedZ.dot(X)+1) < EPS  ||
            Math.abs(rotatedZ.dot(Y)-1) < EPS  ||
            Math.abs(rotatedZ.dot(Y)+1) < EPS  ||
            Math.abs(rotatedZ.dot(Z)-1) < EPS  ||
            Math.abs(rotatedZ.dot(Z)+1) < EPS) {
          // aligned with front, back, left, right, top or bottom, so make it orthographic
          localBlend = 1;
          localBlendPower = .4*radius;
        } else {
          // otherwise, do perspective
          localBlend = 0;
          localBlendPower = 1.5*radius;
        }
      }

      if(activeParent) {
        activeParent.object.getWorldQuaternion(parentOrientation);
        activeParent.object.getWorldPosition(parentPosition);
        worldPosition.applyQuaternion(upAndForwardOrientation);
        worldPosition.applyQuaternion(parentOrientation);
        worldPosition.add(parentPosition);

        worldOrientation.premultiply(upAndForwardOrientation);
        worldOrientation.premultiply(parentOrientation);
      }


      cam.quaternion.slerp(worldOrientation, filter);
      cam.position.lerp(worldPosition, filter);

      cam.blendPower = localBlendPower;
      cam.focusDistance = cam.focusDistance*(1-filter)+radius*filter;

      cam.blend = cam.blend*(1-filter)+localBlend*filter;
      if(localBlend === 0) {
        cam.setPerspective();
      } else {
        cam.setOrthographic();
      }
    }

    // Using exponential moving averages to smooth transitions looks nice,
    // but is highly framerate dependent. If we perform smoothing once per
    // frame, then a person with 30 frames per second would see their transitions
    // happen half as fast as a person with 60 frames per second. To normalize
    // transition times regardless of framerate, when we get to this point,
    // we perform as many filtering passes as are necessary to stay in line with
    // our target FPS (which can be adjusted with a prop above, but has a good
    // looking default). For example, if we have a frame rate of 240, half the time
    // we get here we'll not need to do anything, but if we have a frame rate of 30,
    // we'll generally have to run through this loop ~4 times.
    let deltaT = clock.getElapsedTime();
    const singleFrameTime = 1000/targetFPS;
    while(deltaT > singleFrameTime) {
      performFiltering();
      deltaT -= singleFrameTime;
      clock.offsetStart(singleFrameTime);
    }

    cam.updateProjectionMatrix();
  });

  return (<>
    <BlendCamera ref={cameraRef} makeDefault/>
  </>
  );
}

export default CameraControls;
