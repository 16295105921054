import { connect } from 'react-redux';

import CameraButtons from '../components/CameraButtons';
import actions from '../actions';
import { selectDemo } from '../selectors';

export default connect(
  (state, ownProps) => ({
    demo: selectDemo(state)
  }),
  (dispatch, ownProps) => ({
    parentCamera: (parentTo) => dispatch(actions.viewer3d.parentCamera(parentTo)),
    setPerspective: () => dispatch(actions.viewer3d.setPerspective()),
    setOrthographic: () => dispatch(actions.viewer3d.setOrthographic()),
    onNextDemoStep: () => dispatch(actions.ui.demo.next()),
    onNextFeaturesSubStep: () => dispatch(actions.ui.demo.nextFeaturesSubStep()),
  })
)(CameraButtons);
