import { connect } from 'react-redux';

import DemoOverlay from '../components/DemoOverlay';
import actions from '../actions';
import { selectDemo } from '../selectors';

export default connect(
  (state, ownProps) => ({
    demo: selectDemo(state)
  }),
  (dispatch, ownProps) => ({
    onSet: (step) => dispatch(actions.ui.demo.set(step))
  })
)(DemoOverlay);
