import React, { Component } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const PREFIX = 'Divider';

const classes = {
  divider: `${PREFIX}-divider`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.divider}`]: {
    position: "absolute",
    height: "100%",
    zIndex: theme.zIndex.drawer + 1,
    cursor: "col-resize"
  }
}));

class Divider extends Component {
  handleMouseUp = (e) => {
    this.dragging = false;
  };

  handleMouseDown = (e) => {
    e.preventDefault();
    this.dragging = true;
  };

  handleMouseMove = (e) => {
    const { onDrag } = this.props;

    if(this.dragging && onDrag) {
      e.preventDefault();
      onDrag(Math.min(Math.max(e.pageX, 0), window.innerWidth));
    }
  };

  componentDidMount() {
    window.addEventListener("mousemove", this.handleMouseMove);
    window.addEventListener("mouseup", this.handleMouseUp);
  }

  componentWillUnmount() {
    window.removeEventListener("mousemove", this.handleMouseMove);
    window.removeEventListener("mouseup", this.handleMouseUp);
  }

  render() {
    const {  left, width } = this.props;

    return <Root className={classes.divider} style={{left: left-width*.5, width: width }} onMouseDown={this.handleMouseDown}></Root>;
  }
}

Divider.propTypes = {
  width: PropTypes.number.isRequired,
  onDrag: PropTypes.func.isRequired
};

export default (Divider);
