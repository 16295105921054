import React, { useMemo, useState, useRef, useCallback, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { FEATURES, GCODE_OPEN, PLAY, PAUSE, NEXT_LINE, NEXT_TOOL_PATH, GCODE_CLOSE, MAX_SUB_STEPS } from '../constants/demo';
import Popper from '@mui/material/Popper';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import useCallbackRef from '../hooks/useCallbackRef';
import classnames from 'classnames';

import Slider from '@mui/material/Slider';

import LibraryBooks from '@mui/icons-material/LibraryBooks';
//import HomeIcon from '@mui/icons-material/Home';
//import ShuffleIcon from '@mui/icons-material/Shuffle';
import PlayIcon from '@mui/icons-material/PlayArrow';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import TimerIcon from '@mui/icons-material/AvTimer';
import PrevLineIcon from '@mui/icons-material/NavigateBefore';
import PrevToolPathIcon from '@mui/icons-material/FirstPage';
import NextLineIcon from '@mui/icons-material/NavigateNext';
import NextToolPathIcon from '@mui/icons-material/LastPage';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTheme } from '@mui/material/styles';
import pad from '../util/pad';

const PREFIX = 'ControlsOverlay';

const MAX_NEXT_LINE_CLICKS = 5;
const MAX_NEXT_TOOL_PATH_CLICKS = 2;

const classes = {
  toolbar: `${PREFIX}-toolbar`,
  overlay: `${PREFIX}-overlay`,
  button: `${PREFIX}-button`,
  blink: `${PREFIX}-blink`,
  left: `${PREFIX}-left`,
  center: `${PREFIX}-center`,
  right: `${PREFIX}-right`,
  break: `${PREFIX}-break`,
  sliderTime: `${PREFIX}-sliderTime`,
  sliderContainer: `${PREFIX}-sliderContainer`,
  timeSliderContainer: `${PREFIX}-timeSliderContainer`,
  timeIconContainer: `${PREFIX}-timeIconContainer`,
  timeIcon: `${PREFIX}-timeIcon`,
  timeMultiplierLabel: `${PREFIX}-timeMultiplierLabel`
};

const StyledContainer = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.toolbar}`]: theme.mixins.toolbar,

  [`& .${classes.overlay}`]: {
    zIndex: theme.zIndex.drawer + 3,
    position: "absolute",
    left: 0,
    bottom: 50,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    width: "670px",
    maxWidth: "100%",
    textAlign: "center",
    verticalAlign: "middle",
    backgroundColor: "rgba(100,100,100,.75)",
    borderRadius: 5,
    color: "white"
  },
  [`& .${classes.blink}`]: {
    animation: "blinkButton 2s infinite"
  },
  "@keyframes blinkButton": {
    "0%": { backgroundColor: "rgba(0,0,0,0)" },
    "50%": { backgroundColor: "rgba(3, 177,136,1)" },
    "100%": { backgroundColor: "rgba(0,0,0,0)" }
  },

  [`& .${classes.button}`]: {
    color: "#dddddd"
  },

  [`& .${classes.left}`]: {
    float: "left",
    width: "5%",
    textAlign: "left"
  },

  [`& .${classes.center}`]: {
    float: "left",
    width: "55%",
    textAlign: "right"
  },

  [`& .${classes.right}`]: {
    float: "left",
    width: "40%",
    marginBottom: 0,
    textAlign: "right"
  },

  [`& .${classes.break}`]: {
    lineHeight: 0,
    clear: "both"
  },

  [`& .${classes.sliderTime}`]: {
    float: "left",
    width: 50,
    marginLeft: 15,
    fontSize: 10,
    lineHeight: "32px",
    verticalAlign: "middle"
  },

  [`& .${classes.sliderContainer}`]: {
    float: "left",
    width: "calc(100% - 95px)",
    marginLeft: 15,
    marginRight: 15,
  },

  [`& .${classes.timeSliderContainer}`]: {
    width: "calc(100% - 100px)",
    float: "right",
    marginRight: 15
  },

  [`& .${classes.timeIconContainer}`]: {
    float: "right",
    lineHeight: "32px",
    cursor: "pointer"
  },

  [`& .${classes.timeIcon}`]: {
    color: "#dddddd",
    verticalAlign: "middle"
  },

  [`& .${classes.timeMultiplierLabel}`]: {
    fontSize: "10px",
    marginRight: 5
  }
}));

const enterDelay = 500;

function ControlsOverlay(props) {
  const {
    demo,
    visible,
    alwaysVisible,
    paused,
    running,
    value,
    minValue,
    maxValue,
    timeMultiplierValue,
    currentLine,
    currentTime,
    fadeTime, 
    visibleTimeout,
    isolateSlider,
    minLine,
    maxLine,
    onShow,
    onHide,
    onPlay,
    onPause,
    onRun,
    onPrevious,
    onNext,
    onPreviousToolPath,
    onNextToolPath,
    onStop,
    onTogglePane,
    onSlider,
    onTimeSlider,
    onNextDemoStep,
    onNextFeaturesSubStep
  } = props;

  const { enabled, step, featuresSubStep: subStep } = demo || {};
  const demoOpen = enabled && (step === FEATURES && subStep >= GCODE_OPEN && subStep <= GCODE_CLOSE);

  const nextSubStep = onNextFeaturesSubStep;

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const timeoutID = useRef();
  const resetTimeout = useCallback(() => {
    if(onHide) {
      if(timeoutID && timeoutID.current) {
        window.clearTimeout(timeoutID.current);
        timeoutID.current = null;
      }

      timeoutID.current = setTimeout(() => {
        onHide();
      }, visibleTimeout || 2000);
    }
  }, [ visibleTimeout, onHide, timeoutID ]);
  
  const handleMouseMove = useCallback((e) => {
    if(!e.buttons) {
      if(!visible && onShow) {
        onShow();
      }

      resetTimeout();
    }
  }, [ visible, onShow, resetTimeout ]);

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    }
  }, [ handleMouseMove ]);

  const [ gcodeRef, gcodeEl ] = useCallbackRef(null);
  const [ playRef, playEl ] = useCallbackRef(null);
  const [ playbackSpeedRef, playbackSpeedEl ] = useCallbackRef(null);
  const [ playbackSpeedResetRef, playbackSpeedResetEl ] = useCallbackRef(null);
  const [ pauseRef, pauseEl ] = useCallbackRef(null);
  const [ playbackSliderRef, playbackSliderEl ] = useCallbackRef(null);
  const [ stopRef, stopEl ] = useCallbackRef(null);
  const [ runRef, runEl ] = useCallbackRef(null);
  const [ nextLineRef, nextLineEl ] = useCallbackRef(null);
  const [ nextToolPathRef, nextToolPathEl ] = useCallbackRef(null);

  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ demoContent, setDemoContent ] = useState(null);
  const [ nextLineClicks, setNextLineClicks ] = useState(0);
  const [ nextToolPathClicks, setNextToolPathClicks ] = useState(0);

  useEffect(() => {
    if(demoOpen) {
      setNextLineClicks(0);
      setNextToolPathClicks(0);
    }
  }, [ demoOpen, setNextLineClicks, setNextToolPathClicks ]);

  const iconSx = useMemo(() => ({ fontSize: "18px", verticalAlign: "bottom" }), []);

  useEffect(() => {
    if(subStep === GCODE_OPEN) {
      setAnchorEl(gcodeEl);
      setDemoContent(<>The simulator plays through G code as if it were a video. <b style={{ color: theme.palette.primary.main }}>Press the <LibraryBooks sx={iconSx}/> button</b> to view the G code that is currently being executed.</>);
    } else if(subStep === PLAY) {
      setAnchorEl(playEl);
      setDemoContent(<><b style={{ color: theme.palette.primary.main }}>Press the <PlayIcon sx={iconSx}/> button</b> to start playing through the G code as if it were a video.</>);
    } else if(subStep === PAUSE) {
      setAnchorEl(pauseEl);
      setDemoContent(<><b style={{ color: theme.palette.primary.main }}>Press the <PauseIcon sx={iconSx}/> button</b> to stop the animation.</>);
    } else if(subStep === NEXT_LINE) {
      setAnchorEl(nextLineEl);
      setDemoContent(<><b style={{ color: theme.palette.primary.main }}>Press the <NextLineIcon sx={iconSx}/> button</b> to progress the program to the next line of your G code program. <b style={{ color: theme.palette.primary.main }}>Try it {(MAX_NEXT_LINE_CLICKS-nextLineClicks) + " time" + (MAX_NEXT_LINE_CLICKS-nextLineClicks === 1 ? " " : "s ")} to move on.</b></>);
    } else if(subStep === NEXT_TOOL_PATH) {
      setAnchorEl(nextToolPathEl);
      setDemoContent(<><b style={{ color: theme.palette.primary.main }}>Press the <NextToolPathIcon sx={iconSx}/> button</b> to progress the program to the first line of the next tool path. <b style={{ color: theme.palette.primary.main }}>Try it {(MAX_NEXT_TOOL_PATH_CLICKS-nextToolPathClicks) + " time" + (MAX_NEXT_TOOL_PATH_CLICKS-nextToolPathClicks === 1 ? " " : "s ")} to move on.</b></>);
    } else if(subStep === GCODE_CLOSE) {
      setAnchorEl(gcodeEl);
      setDemoContent(<><b style={{ color: theme.palette.primary.main }}>Press the <LibraryBooks sx={iconSx}/> button</b> again to close the G code pane.</>);
    }
  }, [ theme, iconSx, gcodeEl, playEl, playbackSpeedEl, playbackSpeedResetEl, pauseEl, playbackSliderEl, stopEl, runEl, nextLineEl, nextToolPathEl, subStep, setAnchorEl, setDemoContent, nextLineClicks, nextToolPathClicks ]);

  useEffect(() => {
    if(paused) {
      if(subStep === PAUSE) {
        nextSubStep();
      }
    }
  }, [ paused, subStep, nextSubStep ]);

  const handlePlay = useCallback((e) => {
    if(onPlay) {
      onPlay(e);
    }
    if(demoOpen) {
      if(subStep === PLAY) {
        nextSubStep();
      }
    }
    resetTimeout();
  }, [ onPlay, resetTimeout, nextSubStep, subStep, demoOpen ]);

  const handlePause = useCallback((e) => {
    if(onPause) {
      onPause(e);
    }
    resetTimeout();
  }, [ onPause, resetTimeout ]);

  const handleRun = useCallback((e) => {
    if(onRun) {
      onRun(e);
    }
    resetTimeout();
  }, [ onRun, resetTimeout ]);

  const handlePrevious = useCallback((e) => {
    if(onPrevious) {
      onPrevious(e);
    }
    resetTimeout();
  }, [ onPrevious, resetTimeout ]);

  const handleNext = useCallback((e) => {
    if(onNext) {
      onNext(e);
    }
    if(demoOpen) {
      if(subStep === NEXT_LINE) {
        if(nextLineClicks < MAX_NEXT_LINE_CLICKS-1) {
          setNextLineClicks(nextLineClicks+1);
        } else {
          nextSubStep();
        }
      }
    }
    resetTimeout();
  }, [ onNext, resetTimeout, demoOpen, subStep, nextSubStep, nextLineClicks, setNextLineClicks ]);

  const handleNextToolPath = useCallback((e) => {
    if(onNextToolPath) {
      onNextToolPath(currentLine);
    }
    if(demoOpen) {
      if(subStep === NEXT_TOOL_PATH) {
        if(nextToolPathClicks < MAX_NEXT_TOOL_PATH_CLICKS-1) {
          setNextToolPathClicks(nextToolPathClicks+1);
        } else {
          nextSubStep();
        }
      }
    }
    resetTimeout();
  }, [ onNextToolPath, resetTimeout, currentLine, demoOpen, subStep, nextSubStep, setNextToolPathClicks, nextToolPathClicks ]);

  const handlePreviousToolPath = useCallback((e) => {
    if(onPreviousToolPath) {
      onPreviousToolPath(currentLine);
    }
    resetTimeout();
  }, [ onPreviousToolPath, resetTimeout, currentLine ]);

  const handleStop = useCallback((e) => {
    if(onStop) {
      onStop(e);
    }
    resetTimeout();
  }, [ onStop, resetTimeout ]);

  const handleTogglePane = useCallback((e) => {
    if(onTogglePane) {
      onTogglePane(e);
    }
    if(demoOpen) {
      if(subStep === GCODE_OPEN || subStep === GCODE_CLOSE) {
        nextSubStep();
      } 
    }
    resetTimeout();
  }, [ onTogglePane, resetTimeout, demoOpen, subStep, nextSubStep ]);

  const handleOnSlider = useCallback((e, v) => {
    if(onSlider) {
      if(isolateSlider) {
        onSlider({ percentage: v, minLine, maxLine });
      } else {
        onSlider(v);
      }
    }
    resetTimeout();
  }, [ onSlider, resetTimeout, minLine, maxLine, isolateSlider ]);

  const handleResetTimeSlider = useCallback((e) => {
    if(onTimeSlider) {
      onTimeSlider(1);
    }
    resetTimeout();
  }, [ onTimeSlider, resetTimeout ]);

  const seconds = Math.floor(currentTime)%60;
  const minutes = Math.floor(currentTime/60)%60;
  const hours = Math.floor(currentTime/60/60);
  const currentTimeString = pad(hours, 2) + ":" + pad(minutes, 2) + ":" + pad(seconds, 2);

  const timePower = 1000;
  const timeSliderValue = timeMultiplierValue <= 1 ? .5*timeMultiplierValue : .5*Math.log(timeMultiplierValue)/Math.log(timePower)+.5; // make time slider a logarithmic scale

  const handleTimeSliderChange = useCallback((e,v) => {
    if(onTimeSlider) {
      if(v <= .5) {
        onTimeSlider(2*v);
      } else {
        onTimeSlider(Math.pow(timePower, 2*(v-.5)))
      }
    }
    resetTimeout();
  }, [ onTimeSlider, timePower, resetTimeout ]);

  const id = demoOpen ? "controls-overlay-demo" : undefined;

  return (
  <StyledContainer>
    <Fade in={visible || alwaysVisible} timeout={fadeTime || 500}>
      <div className={classes.overlay}>
      <div aria-describedby={id}>
        <div className={classes.left}>
          <Tooltip title="Toggle G Code Pane" enterDelay={enterDelay} placement="top" disableFocusListener={true}>
            <span>
            <IconButton classes={ { root: classnames({ [classes.blink]: demoOpen && (subStep === GCODE_OPEN || subStep === GCODE_CLOSE) })} } ref={gcodeRef} onClick={handleTogglePane} disabled={demoOpen ? (subStep !== GCODE_OPEN && subStep !== GCODE_CLOSE) : false} size="large"><LibraryBooks className={classes.button}/></IconButton>
            </span>
          </Tooltip>
        </div>
        <div className={classes.center}>
          <Tooltip title="Previous Tool Path" enterDelay={enterDelay} placement="top" disableFocusListener={true}>
            <span>
            <IconButton onClick={handlePreviousToolPath} disabled={demoOpen ? (subStep !== NEXT_LINE) : false} size={ isSmall ? "small" : "medium"}><PrevToolPathIcon className={classes.button}/></IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Previous Line" enterDelay={enterDelay} placement="top" disableFocusListener={true}>
            <span>
            <IconButton onClick={handlePrevious} disabled={demoOpen ? (subStep !== NEXT_LINE) : false} size={ isSmall ? "small" : "medium"}><PrevLineIcon className={classes.button}/></IconButton>
            </span>
          </Tooltip>
          { 
            running ? (paused 
          ? 
            <Tooltip title="Play" enterDelay={enterDelay} placement="top" disableFocusListener={true}>
              <span>
              <IconButton classes={ { root: classnames({ [classes.blink]: demoOpen && subStep === PLAY })} } ref={playRef} onClick={handlePlay} disabled={demoOpen ? (subStep !== PLAY) : false} size={ isSmall ? "small" : "medium" }><PlayIcon className={classes.button}/></IconButton>
              </span>
            </Tooltip>
          : 
            <Tooltip title="Pause" enterDelay={enterDelay} placement="top" disableFocusListener={true}>
              <span>
              <IconButton classes={ { root: classnames({ [classes.blink]: demoOpen && subStep === PAUSE })} } ref={pauseRef} onClick={handlePause} disabled={demoOpen ? (subStep !== PAUSE) : false} size={ isSmall ? "small" : "medium" }><PauseIcon className={classes.button}/></IconButton>
              </span>
            </Tooltip>)
            : null
          }
          {
            running ?
            (
            <Tooltip title="Stop Program" enterDelay={enterDelay} placement="top" disableFocusListener={true}>
              <span>
              <IconButton ref={stopRef} onClick={handleStop} disabled={demoOpen} size={ isSmall ? "small" : "medium" }><StopIcon className={classes.button}/></IconButton>
              </span>
            </Tooltip>
            )

            : 

            <Tooltip title="Run Program" enterDelay={enterDelay} placement="top" disableFocusListener={true}>
              <span>
              <IconButton ref={runRef} onClick={handleRun} disabled={demoOpen} size={ isSmall ? "small" : "medium" }><PlaylistPlayIcon className={classes.button}/></IconButton>
              </span>
            </Tooltip>
          }
          <Tooltip title="Next Line" enterDelay={enterDelay} placement="top" disableFocusListener={true}>
            <span>
            <IconButton classes={ { root: classnames({ [classes.blink]: demoOpen && subStep === NEXT_LINE })} } ref={nextLineRef} onClick={handleNext} disabled={demoOpen ? (subStep !== NEXT_LINE) : false} size={ isSmall ? "small" : "medium" }><NextLineIcon className={classes.button}/></IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Next Tool Path" enterDelay={enterDelay} placement="top" disableFocusListener={true}>
            <span>
            <IconButton classes={ { root: classnames({ [classes.blink]: demoOpen && subStep === NEXT_TOOL_PATH })} } ref={nextToolPathRef} onClick={handleNextToolPath} disabled={demoOpen ? (subStep !== NEXT_TOOL_PATH) : false} size={ isSmall ? "small" : "medium" }><NextToolPathIcon className={classes.button}/></IconButton>
            </span>
          </Tooltip>
        </div>
        <div className={classes.right}>
          <div className={classes.timeSliderContainer}>
            <Slider disabled={demoOpen} ref={playbackSpeedRef} size="small" sx={ { color: "rgb(216, 216, 216)" }} step={.001} min={0} max={1} value={timeSliderValue} onChange={handleTimeSliderChange}/>
          </div>
          <div className={classes.timeIconContainer}>
            <Tooltip title="Time Scale" enterDelay={enterDelay} placement="top" disableFocusListener={true}>
              <TimerIcon ref={playbackSpeedResetRef} onClick={handleResetTimeSlider} className={classes.timeIcon}/> 
            </Tooltip><span className={classes.timeMultiplierLabel}>{ timeMultiplierValue.toFixed(2) }</span>
          </div>
        </div>
        </div>
        <br className={classes.break}/>
        <div className={classes.sliderControls}>
          <div className={classes.sliderTime}>{ currentTimeString }</div>
          <div className={classes.sliderContainer}>
          <Slider disabled={demoOpen} ref={playbackSliderRef} size="small" sx={ { color: "rgb(216, 216, 216)" }} step={.001} min={minValue} max={maxValue} value={value} onChange={handleOnSlider}/>
          </div>
        </div>
        { anchorEl ? 
        <Popper id={id} 
                 open={demoOpen} 
                 anchorEl={anchorEl} placement="top" 
                 sx={{
                   paddingBottom: 1,
                   zIndex: 2000
                 }}>

        <Box sx={{ position: "relative", backgroundColor: "white", color: "black", borderRadius: "5px", bottom: "6px", border: "1px solid rgba(100,100,100,0.75)"}}>
          <Box sx={{ padding: 1, width: "300px" }}>
            { demoContent }
            <MobileStepper
              variant="text"
              steps={MAX_SUB_STEPS}
              position="static"
              activeStep={subStep}
              nextButton={<Button onClick={onNextDemoStep}>Exit Demo</Button>}
            />
          </Box>
          <Box sx={{ backgroundColor: "white", width: "12px", height: "12px", position: "absolute", bottom: "-8px", left: "50%", transform: "translate(-50%, 0) rotate(45deg) ", borderRight: "1px solid rgba(100,100,100,0.75)", borderBottom: "1px solid rgba(100,100,100,0.75)", content: '""' }}/>
        </Box>

        </Popper>
        : null }
      </div>
    </Fade>
  </StyledContainer>
  );
}

export default (ControlsOverlay);
