import { OFF, RAPID, LINEAR, ARC_CW, ARC_CCW } from '../constants/machine-state/motion';
import { XY, ZX, YZ } from '../constants/machine-state/plane-select';
import { EPS } from '../constants';

export const closestRotary = (current, desired, minRot, maxRot) => {
  let currentRem = current % 360;
  let desiredRem = desired % 360;

  if(currentRem < 0) {
    currentRem += 360;
  }

  if(desiredRem < 0) {
    desiredRem += 360;
  }

  let dr = currentRem-desiredRem;
  if(dr > 180) {
    dr -= 360;
  } else if(dr < -180) {
    dr += 360;
  }

  let newRot = desired+dr;

  if(newRot > maxRot) {
    newRot = newRot-360*Math.ceil((newRot-maxRot)/360);
  }

  if(newRot < minRot) {
    newRot = newRot+360*Math.ceil((minRot-newRot)/360);
  }

  return newRot;
}

export const clampMotion = (pos0, pos1, t) => {
  const tt = Math.floor(t);
  return {
    X: pos0.X*(1-tt)+pos1.X*tt,
    Y: pos0.Y*(1-tt)+pos1.Y*tt,
    Z: pos0.Z*(1-tt)+pos1.Z*tt,
    A: pos0.A*(1-tt)+pos1.A*tt,
    B: pos0.B*(1-tt)+pos1.B*tt,
    C: pos0.C*(1-tt)+pos1.C*tt
  };
};

const linearMotion = (pos0, pos1, t) => {
  return {
    X: pos0.X*(1-t)+pos1.X*t,
    Y: pos0.Y*(1-t)+pos1.Y*t,
    Z: pos0.Z*(1-t)+pos1.Z*t,
    A: pos0.A*(1-t)+pos1.A*t,
    B: pos0.B*(1-t)+pos1.B*t,
    C: pos0.C*(1-t)+pos1.C*t
  };
};

export const arcLength = (pos0, pos1, planeSelect, mode, turns) => {
  if(!turns) {
    turns = 1;
  }
  const radiusFormat = isRadiusFormatArc(pos1);

  const px0 = pos0.X;
  const py0 = pos0.Y;
  const pz0 = pos0.Z;

  const px1 = pos1.X;
  const py1 = pos1.Y;
  const pz1 = pos1.Z;

  let cx = pos1.I;
  let cy = pos1.J;
  let cz = pos1.K;

  if(radiusFormat) {
    const R = pos1.R;

    let xT = .5*(px1+px0);
    let yT = .5*(py1+py0);
    let xa = .5*(px1-px0);
    let ya = .5*(py1-py0);
    let a = Math.sqrt(xa*xa+ya*ya);
    let b = Math.sqrt(R*R-a*a);
    switch(planeSelect) {
      case XY:
        xT = .5*(px1+px0);
        yT = .5*(py1+py0);
        xa = .5*(px1-px0);
        ya = .5*(py1-py0);
        a = Math.sqrt(xa*xa+ya*ya);
        b = Math.sqrt(R*R-a*a);

        cx = xT + b*ya/a;
        cy = yT - b*xa/a;

        break;
      case ZX:
        xT = .5*(pz1+pz0);
        yT = .5*(px1+px0);
        xa = .5*(pz1-pz0);
        ya = .5*(px1-px0);
        a = Math.sqrt(xa*xa+ya*ya);
        b = Math.sqrt(R*R-a*a);

        cz = xT + b*ya/a;
        cx = yT - b*xa/a;
        break;
      case YZ:
        xT = .5*(py1+py0);
        yT = .5*(pz1+pz0);
        xa = .5*(py1-py0);
        ya = .5*(pz1-pz0);
        a = Math.sqrt(xa*xa+ya*ya);
        b = Math.sqrt(R*R-a*a);

        cy = xT + b*ya/a;
        cz = yT - b*xa/a;
        break;
      default:
        // not implemented - only G17, G18 and G19 are implemented
        break;
    }
  } else {
    cx = pos1.I;
    cy = pos1.J;
    cz = pos1.K;
  }

  let dx0;
  let dy0;
  let dx1;
  let dy1;
  let dz;

  switch(planeSelect) {
    case XY:
      dx0 = px0-cx;
      dy0 = py0-cy;

      dx1 = px1-cx;
      dy1 = py1-cy;

      dz = pz1-pz0;
      break;
    case ZX:
      dx0 = pz0-cz;
      dy0 = px0-cx;

      dx1 = pz1-cz;
      dy1 = px1-cx;

      dz = py1-py0;
      break;
    case YZ:
      dx0 = -(pz0-cz);
      dy0 = py0-cy;

      dx1 = -(pz1-cz);
      dy1 = py1-cy;

      dz = px1-px0;
      break;
    default:
      // not implemented - only G17, G18 and G19 are implemented
      break;
  }

  const mag0 = Math.sqrt(dx0*dx0+dy0*dy0);

  // TODO error checking
  // mag0 should equal mag1

  let angle0 = Math.atan2(dy0, dx0);
  if(angle0 < 0) {
    angle0 += 2*Math.PI;
  }

  let angle1 = Math.atan2(dy1, dx1);
  if(angle1 < 0) {
    angle1 += 2*Math.PI;
  }

  let theta;
  if(mode === ARC_CW) {
    theta = angle1-angle0;
    if(theta > EPS) {
      theta = -(2*Math.PI-theta);
    } else if(theta < EPS && theta > -EPS) {
      theta -= 2*Math.PI*turns;
    }
  } else {
    theta = angle1-angle0;
    if(theta < -EPS) {
      theta = 2*Math.PI+theta;
    } else if(theta > -EPS && theta < EPS) {
      theta += 2*Math.PI*turns;
    }
  }

  const depth = dz;
  const circumference = theta*mag0;

  return Math.sqrt(depth*depth+circumference*circumference);
};

export const arcRadii = (pos0, pos1, planeSelect, mode) => {
  if(isRadiusFormatArc(pos1)) {
    const R = pos1.R;
    return { r0: R, r1: R };
  } else {
    const px0 = pos0.X;
    const py0 = pos0.Y;
    const pz0 = pos0.Z;

    const cx = pos1.I;
    const cy = pos1.J;
    const cz = pos1.K;

    const px1 = pos1.X;
    const py1 = pos1.Y;
    const pz1 = pos1.Z;

    let dx0;
    let dy0;
    let dx1;
    let dy1;

    switch(planeSelect) {
      case XY:
        dx0 = px0-cx;
        dy0 = py0-cy;

        dx1 = px1-cx;
        dy1 = py1-cy;
        break;
      case ZX:
        dx0 = pz0-cz;
        dy0 = px0-cx;

        dx1 = pz1-cz;
        dy1 = px1-cx;
        break;
      case YZ:
        dx0 = -(pz0-cz);
        dy0 = py0-cy;

        dx1 = -(pz1-cz);
        dy1 = py1-cy;
        break;
      default:
        // not implemented - only G17, G18 and G19 are implemented
        break;
    }

    const r0 = Math.sqrt(dx0*dx0+dy0*dy0);
    const r1 = Math.sqrt(dx1*dx1+dy1*dy1);

    return { r0, r1 };
  }
}

export const isRadiusFormatArc = ({ R }) => {
  return R > EPS;
};

export const arcMotion = (pos0, pos1, t, planeSelect, mode, turns) => {
  if(!turns) {
    turns = 1;
  }

  const radiusFormat = isRadiusFormatArc(pos1);

  const px0 = pos0.X;
  const py0 = pos0.Y;
  const pz0 = pos0.Z;

  const px1 = pos1.X;
  const py1 = pos1.Y;
  const pz1 = pos1.Z;

  let cx;
  let cy;
  let cz;

  if(radiusFormat) {
    const R = pos1.R;

    let xT = .5*(px1+px0);
    let yT = .5*(py1+py0);
    let xa = .5*(px1-px0);
    let ya = .5*(py1-py0);
    let a = Math.sqrt(xa*xa+ya*ya);
    let b = Math.sqrt(R*R-a*a);
    switch(planeSelect) {
      case XY:
        xT = .5*(px1+px0);
        yT = .5*(py1+py0);
        xa = .5*(px1-px0);
        ya = .5*(py1-py0);
        a = Math.sqrt(xa*xa+ya*ya);
        b = Math.sqrt(R*R-a*a);

        cx = xT + b*ya/a;
        cy = yT - b*xa/a;

        break;
      case ZX:
        xT = .5*(pz1+pz0);
        yT = .5*(px1+px0);
        xa = .5*(pz1-pz0);
        ya = .5*(px1-px0);
        a = Math.sqrt(xa*xa+ya*ya);
        b = Math.sqrt(R*R-a*a);

        cz = xT + b*ya/a;
        cx = yT - b*xa/a;
        break;
      case YZ:
        xT = .5*(py1+py0);
        yT = .5*(pz1+pz0);
        xa = .5*(py1-py0);
        ya = .5*(pz1-pz0);
        a = Math.sqrt(xa*xa+ya*ya);
        b = Math.sqrt(R*R-a*a);

        cy = xT + b*ya/a;
        cz = yT - b*xa/a;
        break;
      default:
        // not implemented - only G17, G18 and G19 are implemented
        break;
    }
  } else {
    cx = pos1.I;
    cy = pos1.J;
    cz = pos1.K;
  }

  let dx0;
  let dy0;
  let dx1;
  let dy1;

  switch(planeSelect) {
    case XY:
      dx0 = px0-cx;
      dy0 = py0-cy;

      dx1 = px1-cx;
      dy1 = py1-cy;
      break;
    case ZX:
      dx0 = pz0-cz;
      dy0 = px0-cx;

      dx1 = pz1-cz;
      dy1 = px1-cx;
      break;
    case YZ:
      dx0 = -(pz0-cz);
      dy0 = py0-cy;

      dx1 = -(pz1-cz);
      dy1 = py1-cy;
      break;
    default:
      // not implemented - only G17, G18 and G19 are implemented
      break;
  }

  // TODO error checking, make sure mag0 equals mag1

  let angle0 = Math.atan2(dy0, dx0);
  if(angle0 < 0) {
    angle0 += 2*Math.PI;
  }

  let angle1 = Math.atan2(dy1, dx1);
  if(angle1 < 0) {
    angle1 += 2*Math.PI;
  }

  let theta;
  if(mode === ARC_CW) {
    theta = angle1-angle0;
    if(theta > EPS) {
      theta = -(2*Math.PI-theta);
    } else if(theta < EPS && theta > -EPS) {
      theta -= 2*Math.PI*turns;
    }
  } else {
    theta = angle1-angle0;
    if(theta < -EPS) {
      theta = 2*Math.PI+theta;
    } else if(theta > -EPS && theta < EPS) {
      theta += 2*Math.PI*turns;
    }
  }
  theta *= t;
  const Ctheta = Math.cos(theta);
  const Stheta = Math.sin(theta);

  let X;
  let Y;
  let Z;

  switch(planeSelect) {
    case XY:
      // rotation about z
      X = cx+(px0-cx)*Ctheta-(py0-cy)*Stheta;
      Y = cy+(px0-cx)*Stheta+(py0-cy)*Ctheta;
      Z = pz0*(1-t)+pz1*t;
      break;
    case ZX:
      // rotation about y
      X = cx+(px0-cx)*Ctheta+(pz0-cz)*Stheta;
      Y = py0*(1-t)+py1*t;
      Z = cz-(px0-cx)*Stheta+(pz0-cz)*Ctheta;
      break;
    case YZ:
      // rotation about x
      X = px0*(1-t)+px1*t;
      Y = cy+(py0-cy)*Ctheta-(pz0-cz)*Stheta;
      Z = cz+(py0-cy)*Stheta+(pz0-cz)*Ctheta;
      break;

    default:
      // not implemented
      break;
  }

  const A = pos0.A*(1-t)+pos1.A*t;
  const B = pos0.B*(1-t)+pos1.B*t;
  const C = pos0.C*(1-t)+pos1.C*t;

  const pos = { X, Y, Z, A, B, C };

  return pos;
};

export const calculatePosition = (motionMode, pos0, pos1, t, planeSelect, turns, issuedMotionCommand) => {
  if(issuedMotionCommand) {
    switch(motionMode) {
      case RAPID:
      case LINEAR:
        const p = linearMotion(pos0, pos1, t);
        return p;
      case OFF:
        return pos1
      case ARC_CW:
      case ARC_CCW:
        return arcMotion(pos0, pos1, t, planeSelect, motionMode, turns);
      default:
        console.error(motionMode, " motion mode not implemented, jumping to final position.");
        return pos1
    }
  } else {
    const p = clampMotion(pos0, pos1, t);
    return p
  }
};
