import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';

import Editor from './Editor';
import Summary from '../containers/Summary';

const PREFIX = 'GCodePane';

const classes = {
  appBar: `${PREFIX}-appBar`,
  summary: `${PREFIX}-summary`,
  hidden: `${PREFIX}-hidden`,
  toolbar: `${PREFIX}-toolbar`,
  tabNoTitleBar: `${PREFIX}-tabNoTitleBar`,
  tab: `${PREFIX}-tab`,
  paper: `${PREFIX}-paper`
};

const StyledContainer = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.summary}`]: {
    padding: "10px"
  },

  [`& .${classes.appBar}`]: {
    zIndex: 1200
  },

  [`& .${classes.hidden}`]: {
    display: "none"
  },

  [`& .${classes.toolbar}`]: theme.mixins.toolbar,

  [`& .${classes.tabNoTitleBar}`]: {
    height: "100%",
    width: "100%"
  },

  [`& .${classes.tab}`]: {
    height: "calc(100% - 112px)",
    width: "100%"
  },

  [`& .${classes.paper}`]: {
  }
}));

// From material ui demo: https://material-ui.com/demos/buttons/
function TabContainer(props) {
  const { children, dir, } = props;

  return (
  <Typography component="div" dir={dir} classes={ { root: classes.tabcontainer } }>
    {children}
  </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired
};

function GCodePane(props) {
  const { 
    width, 
    visible, 
    currentLine, 
    annotations,
    onScroll,
    onTabChange,
    onSetCurrentLine,
    currentTab,
    running,
    hideTitleBar,
    fileName,
    onSetFileName
  } = props;

  return (
  <StyledContainer>
    <Drawer variant="persistent" open={visible} PaperProps={ { style: { width: width, height: "100vh" }}}>
      { hideTitleBar ? null : <div className={classes.toolbar}></div> }
      <AppBar position="static" color="default" className={classes.appBar}>
        <Tabs value={currentTab} onChange={onTabChange} indicatorColor="primary" textColor="primary">
          <Tab label="GCode"/>
          <Tab label="Summary"/>
        </Tabs>
      </AppBar>
      <div className={classnames((hideTitleBar ? classes.tabNoTitleBar : classes.tab), { [classes.hidden]: currentTab !== 0 })}>
        <Editor annotations={annotations}
                currentLine={currentLine}
                onScroll={onScroll}
                currentTab={currentTab}
                running={running}
                fileName={fileName}
                onSetCurrentLine={onSetCurrentLine}
                onSetFileName={onSetFileName}/>
      </div>
      <div className={classnames(classes.tab, { [classes.hidden]: currentTab !== 1 })}>
        <Summary/>
      </div>
    </Drawer>
    </StyledContainer>
  );
}

GCodePane.propTypes = {
  width: PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
  selected: PropTypes.number,
  currentTab: PropTypes.number,
  onTabChange: PropTypes.func
};

export default (GCodePane);
