import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FileSelectButton from './FileSelectButton';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import { processingQueue } from '../sagas/simulation-zip-processing-queue';

class SimulationZipLoader extends Component {
  handleFileSelect = (f) => {
    const {
      binary,
      onLoading
    } = this.props;

    this.fileSize = f.size;
    this.fileName = f.name;
    if(binary) {
      this.read = 0;
      this.buffer = "";
    } else {
      this.data = undefined;
      this.buffer = undefined;
      this.offset = 0;
    }

    if(onLoading) {
      onLoading();
    }
  };

  handleFinished = () => {
    this.stopReadingFile = false;

    processingQueue.addSimulationZip(this.buffer);

  };

  handleRead = (chunk, size, totalSize) => {
    const {
      onProgress,
      onError
    } = this.props;

    if(!this.data) {
      this.buffer = new ArrayBuffer(totalSize);
      this.data = new Uint8Array(this.buffer);
      this.offset = 0;
    }

    try {
      this.data.set(new Uint8Array(chunk), this.offset);
    } catch(err) {
      if(onError) {
        onError(new Error("Error reading data chunk: " + err.message + ", " + this.offset));
      }
    }

    this.offset += size;
    const progress = Math.min(this.offset/this.fileSize*100, 100);

    if(onProgress) {
      onProgress(progress);
    }
  };

  stopReading = () => {
    return this.stopReadingFile;
  };

  render() {
    const {
      iconOnly,
      variant,
      size,
      shorten,
      color
    } = this.props;
    return (
      <FileSelectButton onFileSelect={this.handleFileSelect}
                        onFinished={this.handleFinished}
                        onRead={this.handleRead}
                        stopReading={this.stopReading}
                        chunkSize={64*1024}
                        binary={true}
                        size={size}
                        color={color}
                        variant={variant}>
        <OpenInBrowserIcon/>{iconOnly ? null : (shorten ? " Zip" : " Simulation Zip")}
      </FileSelectButton>
    );
  }
};

SimulationZipLoader.propTypes = {
  onProgress: PropTypes.func,
  onError: PropTypes.func,
  onLoadModel: PropTypes.func,
  onLoadPath: PropTypes.func,
  stopReading: PropTypes.func
};

export default SimulationZipLoader;
