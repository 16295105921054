// AUTOMATIC BUILD from pentakins 327cecbf1a479ffe960989494d18c20e3b94af7e
export function joints2table(jointX,jointY,jointZ,jointB,jointC,dx,dz,toolZ) {
  let x0,x1,x2,x3,x4,x5,x6,x7,x8,x9,x,y,z,b,c;
  x0 = Math.cos(jointC);
  x1 = dx*x0;
  x2 = Math.sin(jointC);
  x3 = Math.sin(jointB);
  x4 = x0*x3;
  x5 = Math.cos(jointB);
  x6 = jointX*x5;
  x7 = jointZ - toolZ;
  x8 = dx*x2;
  x9 = x2*x3;
  x = dz*x4 + jointY*x2 + x0*x6 - x1*x5 + x1 - x4*x7;
  y = -dz*x9 + jointY*x0 - x2*x6 + x5*x8 + x7*x9 - x8;
  z = -dx*x3 - dz*x5 + dz + jointX*x3 + x5*x7;
  b = jointB;
  c = jointC;
  return { x,y,z,b,c };
}
export function table2joints(tableX,tableY,tableZ,tableB,tableC,dx,dz,toolZ) {
  let x0,x1,x2,x3,x4,x,y,z,b,c;
  x0 = Math.cos(tableB);
  x1 = Math.sin(tableB);
  x2 = Math.sin(tableC);
  x3 = tableY*x2;
  x4 = Math.cos(tableC);
  x = -dx*x0 + dx - dz*x1 + tableX*x0*x4 + tableZ*x1 - x0*x3;
  y = tableX*x2 + tableY*x4;
  z = dx*x1 - dz*x0 + dz - tableX*x1*x4 + tableZ*x0 + toolZ + x1*x3;
  b = tableB;
  c = tableC;
  return { x,y,z,b,c };
}
export function joints2tcpc(jointsX,jointsY,jointsZ,jointsB,jointsC,workX,workY,workZ,workB,workC,dx,dz,toolZ) {
  let x0,x1,x2,x3,x4,x5,x6,x7,x8,x9,x10,x11,x12,x13,x14,x15,x16,x17,x18,x19,x20,x,y,z,b,c;
  x0 = Math.sin(workB);
  x1 = Math.cos(workB);
  x2 = Math.sin(jointsC);
  x3 = Math.sin(workC);
  x4 = x2*x3;
  x5 = Math.cos(jointsC);
  x6 = Math.cos(workC);
  x7 = x5*x6;
  x8 = x1*x4 + x1*x7;
  x9 = x2*x6;
  x10 = Math.sin(jointsB);
  x11 = Math.cos(jointsB);
  x12 = x0*x10 + x11*x8;
  x13 = x0*x11 - x10*x8;
  x14 = jointsZ - toolZ;
  x15 = x3*x5 - x9;
  x16 = dx*x15;
  x17 = x10*x15;
  x18 = -x0*x4 - x0*x7;
  x19 = x1*x10 + x11*x18;
  x20 = x1*x11 - x10*x18;
  x = -dx*x12 + dx*x8 + dz*x0 - dz*x13 + jointsX*x12 + jointsY*(-x1*x3*x5 + x1*x9) - workX + x13*x14;
  y = dz*x17 + jointsX*x11*x15 + jointsY*(x4 + x7) - workY - x11*x16 - x14*x17 + x16;
  z = dx*x18 - dx*x19 + dz*x1 - dz*x20 + jointsX*x19 + jointsY*(x0*x3*x5 - x0*x9) - workZ + x14*x20;
  b = jointsB - workB;
  c = jointsC - workC;
  return { x,y,z,b,c };
}
export function tcpc2joints(tcpcX,tcpcY,tcpcZ,tcpcB,tcpcC,workX,workY,workZ,workB,workC,dx,dz,toolZ) {
  let x0,x1,x2,x3,x4,x5,x6,x7,x8,x9,x10,x11,x12,x13,x14,x15,x16,x17,x18,x19,x20,x21,x22,x,y,z,b,c;
  x0 = tcpcB + workB;
  x1 = Math.cos(x0);
  x2 = Math.sin(x0);
  x3 = Math.sin(workC);
  x4 = tcpcC + workC;
  x5 = Math.cos(x4);
  x6 = x3*x5;
  x7 = Math.cos(workC);
  x8 = Math.sin(x4);
  x9 = x1*x6 - x1*x7*x8;
  x10 = Math.sin(workB);
  x11 = Math.cos(workB);
  x12 = x1*x3*x8 + x1*x5*x7;
  x13 = x10*x2 + x11*x12;
  x14 = -x10*x12 + x11*x2;
  x15 = x3*x8 + x5*x7;
  x16 = -x6 + x7*x8;
  x17 = x11*x16;
  x18 = x10*x16;
  x19 = -x2*x3*x5 + x2*x7*x8;
  x20 = -x2*x3*x8 - x2*x5*x7;
  x21 = x1*x10 + x11*x20;
  x22 = x1*x11 - x10*x20;
  x = -dx*x1 + dx - dz*x2 + tcpcX*x13 + tcpcY*x9 + tcpcZ*x14 + workX*x13 + workY*x9 + workZ*x14;
  y = tcpcX*x17 + tcpcY*x15 - tcpcZ*x18 + workX*x17 + workY*x15 - workZ*x18;
  z = dx*x2 - dz*x1 + dz + tcpcX*x21 + tcpcY*x19 + tcpcZ*x22 + toolZ + workX*x21 + workY*x19 + workZ*x22;
  b = x0;
  c = x4;
  return { x,y,z,b,c };
}
export function joints2rwo(jointsX,jointsY,jointsZ,jointsB,jointsC,workX,workY,workZ,workB,workC,dx,dz,toolZ) {
  let x0,x1,x2,x3,x4,x5,x6,x7,x8,x9,x10,x11,x12,x13,x14,x,y,z,b,c;
  x0 = Math.cos(jointsB);
  x1 = Math.sin(jointsB);
  x2 = Math.sin(jointsC);
  x3 = Math.cos(workC);
  x4 = x2*x3;
  x5 = Math.sin(workC);
  x6 = Math.cos(jointsC);
  x7 = Math.sin(workB);
  x8 = Math.cos(workB);
  x9 = x2*x5;
  x10 = x3*x6;
  x11 = x0*x10 + x0*x9;
  x12 = x5*x6;
  x13 = -x12 + x4;
  x14 = -x1*x10 - x1*x9;
  x = dx*x0 - dx + dz*x1 + jointsX - workX*(x1*x7 + x11*x8) - workY*(-x0*x4 + x0*x5*x6) - workZ*(x1*x8 - x11*x7);
  y = jointsY - workX*x13*x8 - workY*(x10 + x9) + workZ*x13*x7;
  z = -dx*x1 + dz*x0 - dz + jointsZ - toolZ - workX*(x0*x7 + x14*x8) - workY*(-x1*x12 + x1*x4) - workZ*(x0*x8 - x14*x7);
  b = jointsB - workB;
  c = jointsC - workC;
  return { x,y,z,b,c };
}
export function rwo2joints(rwoX,rwoY,rwoZ,rwoB,rwoC,workX,workY,workZ,workB,workC,dx,dz,toolZ) {
  let x0,x1,x2,x3,x4,x5,x6,x7,x8,x9,x10,x11,x12,x13,x,y,z,b,c;
  x0 = rwoB + workB;
  x1 = Math.cos(x0);
  x2 = Math.sin(x0);
  x3 = Math.sin(workC);
  x4 = rwoC + workC;
  x5 = Math.cos(x4);
  x6 = x3*x5;
  x7 = Math.cos(workC);
  x8 = Math.sin(x4);
  x9 = Math.sin(workB);
  x10 = Math.cos(workB);
  x11 = x1*x3*x8 + x1*x5*x7;
  x12 = -x6 + x7*x8;
  x13 = -x2*x3*x8 - x2*x5*x7;
  x = -dx*x1 + dx - dz*x2 + rwoX + workX*(x10*x11 + x2*x9) + workY*(x1*x6 - x1*x7*x8) + workZ*(x10*x2 - x11*x9);
  y = rwoY + workX*x10*x12 + workY*(x3*x8 + x5*x7) - workZ*x12*x9;
  z = dx*x2 - dz*x1 + dz + rwoZ + toolZ + workX*(x1*x9 + x10*x13) + workY*(-x2*x3*x5 + x2*x7*x8) + workZ*(x1*x10 - x13*x9);
  b = x0;
  c = x4;
  return { x,y,z,b,c };
}
export function rwo2work(rwoX,rwoY,rwoZ,rwoB,rwoC,workX,workY,workZ,workB,workC,dx,dz,toolZ) {
  let x0,x1,x2,x3,x4,x5,x6,x7,x8,x9,x10,x11,x12,x13,x14,x15,x16,x17,x18,x19,x20,x21,x22,x23,x24,x25,x26,x27,x28,x,y,z,b,c;
  x0 = Math.sin(workB);
  x1 = Math.sin(workC);
  x2 = Math.cos(workB);
  x3 = rwoC + workC;
  x4 = Math.sin(x3);
  x5 = Math.cos(workC);
  x6 = Math.cos(x3);
  x7 = x1*x2*x4 + x2*x5*x6;
  x8 = rwoB + workB;
  x9 = Math.sin(x8);
  x10 = x0*x9;
  x11 = Math.cos(x8);
  x12 = x10 + x11*x7;
  x13 = x0*x11 - x7*x9;
  x14 = x1*x4 + x5*x6;
  x15 = x1*x6;
  x16 = x15 - x4*x5;
  x17 = -x16;
  x18 = rwoY + workX*x17*x2 + workY*x14 - workZ*x0*x17;
  x19 = dx*x11;
  x20 = dz*x9;
  x21 = x1*x11*x4 + x11*x5*x6;
  x22 = dx + rwoX + workX*(x10 + x2*x21) + workY*(x11*x15 - x11*x4*x5) + workZ*(-x0*x21 + x2*x9) - x19 - x20;
  x23 = -x1*x4*x9 - x5*x6*x9;
  x24 = -x11*x2;
  x25 = dx*x9 - dz*x11 + dz + rwoZ + workX*(x0*x11 + x2*x23) + workY*(-x1*x6*x9 + x4*x5*x9) + workZ*(-x0*x23 - x24);
  x26 = -x0*x1*x4 - x0*x5*x6;
  x27 = x11*x26 + x2*x9;
  x28 = -x24 - x26*x9;
  x = -dx*x12 + dx*x7 + dz*x0 - dz*x13 + x12*x22 + x13*x25 + x18*(-x1*x2*x6 + x2*x4*x5);
  y = dx*x16 + x11*x16*x22 + x14*x18 - x16*x19 + x16*x20 - x16*x25*x9;
  z = dx*x26 - dx*x27 + dz*x2 - dz*x28 + x18*(x0*x15 - x0*x4*x5) + x22*x27 + x25*x28;
  b = workB;
  c = workC;
  return { x,y,z,b,c };
}
export function jointsDir2workDir(jointsDirX,jointsDirY,jointsDirZ,jointsB,jointsC,workB,workC) {
  let x0,x1,x2,x3,x4,x5,x6,x7,x8,x9,x10,x,y,z;
  x0 = Math.cos(workB);
  x1 = jointsC - workC;
  x2 = Math.sin(x1);
  x3 = jointsDirY*x2;
  x4 = Math.sin(jointsB);
  x5 = Math.sin(workB);
  x6 = x4*x5;
  x7 = Math.cos(x1);
  x8 = Math.cos(jointsB);
  x9 = x0*x8;
  x10 = x0*x4;
  x = jointsDirX*(x6 + x7*x9) + jointsDirZ*(-x10*x7 + x5*x8) + x0*x3;
  y = -jointsDirX*x2*x8 + jointsDirY*x7 + jointsDirZ*x2*x4;
  z = jointsDirX*(x10 - x5*x7*x8) + jointsDirZ*(x6*x7 + x9) - x3*x5;
  return { x,y,z };
}
export function workDir2jointsDir(workDirX,workDirY,workDirZ,workB,workC,jointsB,jointsC) {
  let x0,x1,x2,x3,x4,x5,x6,x7,x8,x9,x10,x,y,z;
  x0 = Math.cos(jointsB);
  x1 = jointsC - workC;
  x2 = Math.sin(x1);
  x3 = workDirY*x2;
  x4 = Math.sin(jointsB);
  x5 = Math.sin(workB);
  x6 = x4*x5;
  x7 = Math.cos(x1);
  x8 = Math.cos(workB);
  x9 = x0*x8;
  x10 = x4*x8;
  x = workDirX*(x6 + x7*x9) + workDirZ*(-x0*x5*x7 + x10) - x0*x3;
  y = workDirX*x2*x8 + workDirY*x7 - workDirZ*x2*x5;
  z = workDirX*(x0*x5 - x10*x7) + workDirZ*(x6*x7 + x9) + x3*x4;
  return { x,y,z };
}
export function joints2work(jointsX,jointsY,jointsZ,jointsB,jointsC,workB,workC,dx,dz) {
  let x0,x1,x2,x3,x4,x5,x6,x7,x8,x9,x10,x11,x12,x13,x14,x15,x16,x17,x,y,z;
  x0 = Math.sin(workB);
  x1 = Math.cos(workB);
  x2 = jointsC - workC;
  x3 = Math.cos(x2);
  x4 = dx*x3;
  x5 = Math.sin(x2);
  x6 = jointsY*x5;
  x7 = Math.sin(jointsB);
  x8 = x0*x7;
  x9 = Math.cos(jointsB);
  x10 = x1*x9;
  x11 = x10*x3 + x8;
  x12 = x1*x7;
  x13 = x0*x9 - x12*x3;
  x14 = dx*x5;
  x15 = x5*x7;
  x16 = x10 + x3*x8;
  x17 = -x0*x3*x9 + x12;
  x = -dx*x11 + dz*x0 - dz*x13 + jointsX*x11 + jointsZ*x13 + x1*x4 + x1*x6;
  y = -dz*x15 - jointsX*x5*x9 + jointsY*x3 + jointsZ*x15 + x14*x9 - x14;
  z = -dx*x17 + dz*x1 - dz*x16 + jointsX*x17 + jointsZ*x16 - x0*x4 - x0*x6;
  return { x,y,z };
}
export function work2joints(workX,workY,workZ,workB,workC,jointsB,jointsC,dx,dz) {
  let x0,x1,x2,x3,x4,x5,x6,x7,x8,x9,x10,x,y,z;
  x0 = Math.cos(jointsB);
  x1 = Math.sin(jointsB);
  x2 = jointsC - workC;
  x3 = Math.sin(x2);
  x4 = workY*x3;
  x5 = Math.sin(workB);
  x6 = x1*x5;
  x7 = Math.cos(x2);
  x8 = Math.cos(workB);
  x9 = x0*x8;
  x10 = x1*x8;
  x = -dx*x0 + dx - dz*x1 + workX*(x6 + x7*x9) + workZ*(-x0*x5*x7 + x10) - x0*x4;
  y = workX*x3*x8 + workY*x7 - workZ*x3*x5;
  z = dx*x1 - dz*x0 + dz + workX*(x0*x5 - x10*x7) + workZ*(x6*x7 + x9) + x1*x4;
  return { x,y,z };
}
