import { takeLatest, fork, take, put, select, call } from 'redux-saga/effects';
import { eventChannel, buffers } from 'redux-saga';
import { InterpreterCache } from '../gcode/interpreter-cache';
import actions from '../actions';
import { interpreter as selectInterpreter,
         machineState as selectMachineState } from '../selectors';
import BackPlot from '../viewer3d/backplot';

export function interpret(interpreterCache, fileData, machineState) {
  interpreterCache.interpret(fileData, machineState);
}

export function getInterpreterCache() {
  return InterpreterCache.getInstance();
}

export function* interpretFileData(fileData) {
  const interpreterCache = yield call(getInterpreterCache);
  const machineState = yield select(selectMachineState);

  yield call(interpret, interpreterCache, fileData, machineState);
}

function createInterpreterCacheEventChannel(interpreterCache) {
  const backplot = BackPlot.getInstance();

  return eventChannel(emit => {
    const onBegin = (machineState) => {
      emit(actions.interpreter.startProcessing());
      emit(actions.interpreter.setInitialState(machineState));
      backplot.clear();
    };

    const onUpdate = (update) => {
      const {
        progress,
        times,
        messages,
        points
      } = update;

      backplot.addPoints(points);

      emit(actions.interpreter.setProcessingProgress(progress));
      emit(actions.interpreter.setApproximateTime(times[times.length-1]));
      emit(actions.interpreter.appendMessages(messages));
    };

    const onTerminated = () => {
      const action = actions.interpreter.endProcessing()
      emit(action);
    };

    const onEnd = (numLines) => {
      emit(actions.interpreter.endProcessing());
      emit(actions.interpreter.setNumLines(numLines));
    };

    interpreterCache.addEventListener("begin", onBegin);
    interpreterCache.addEventListener("update", onUpdate);
    interpreterCache.addEventListener("terminated", onTerminated);
    interpreterCache.addEventListener("end", onEnd);

    return () => {
      interpreterCache.removeEventListener("begin", onBegin);
      interpreterCache.removeEventListener("update", onUpdate);
      interpreterCache.removeEventListener("terminated", onTerminated);
      interpreterCache.removeEventListener("end", onEnd);
    };
  }, buffers.expanding());
}

export function* singletonInterpreterCacheSaga() {
  const channel = createInterpreterCacheEventChannel(InterpreterCache.getInstance());
  while(true) {
    const action = yield take(channel);

    yield put(action);
  }
}

function requestAnimationFrame() {
  return new Promise((resolve) => 
    window.requestAnimationFrame(() => {
      resolve()
    })
  );
}

//const selectJoints = (state) => state.machineState.joints;

// handles all actions that would cause the program to pause or play
function* handleAnimationLoop(action) {
  let dt = .016;

  const interpreter = yield select(selectInterpreter);
  const {
    currentTime,
    approximateTime
  } = interpreter;

  if(currentTime >= approximateTime) {
    yield put(actions.interpreter.setCurrentLineAndTimeNoUpdates({ currentLine: 0, currentTime: 0 }));
  }
  while(true) {
    const interpreter = yield select(selectInterpreter);

    const running = interpreter.running;
    const paused = interpreter.paused;

//    let lastJoints = yield select(selectJoints);

    if(running && !paused) {
      const t0 = performance.now();
      yield call(requestAnimationFrame);
      yield put(actions.interpreter.incrementTime(dt));


/*
      const joints = yield select(selectJoints);
      const xv = (joints[0]-lastJoints[0])/dt;
      const yv = (joints[1]-lastJoints[1])/dt;
      const zv = (joints[2]-lastJoints[2])/dt;
      const bv = (joints[3]-lastJoints[3])/dt*Math.PI/180;
      const cv = (joints[4]-lastJoints[4])/dt*Math.PI/180;

      const B = joints[3]*Math.PI/180;
      const C = joints[4]*Math.PI/180;

      const CB = Math.cos(-B);
      const SB = Math.sin(-B);
      const CC = Math.cos(-C);
      const SC = Math.sin(-C);

      const omegaX = -SB*cv;
      const omegaY = bv;
      const omegaZ = CB*cv;

      const X = joints[0];
      const Y = joints[1];
      const Z = 8+joints[2];

      const rx = Y*omegaZ-Z*omegaY;
      const ry = Z*omegaX-X*omegaZ;
      const rz = X*omegaY-Y*omegaX;

      const vx = xv+rx;
      const vy = yv+ry;
      const vz = zv+rz;

      const feedrate = Math.sqrt(vx*vx+vy*vy+vz*vz);

      console.log(feedrate*60);
      */
      
      const t1 = performance.now();
      dt = (t1-t0)/1000;
//      lastJoints = joints;
    } else {
      break;
    }
  }
}

export function* animationSaga() {
  yield takeLatest([
    actions.interpreter.pause,
    actions.interpreter.unpause,
    actions.interpreter.run,
    actions.interpreter.nextLine,
    actions.interpreter.previousLine,
    actions.interpreter.setCurrentLineWithPercentage,
    actions.interpreter.setCurrentLineWithPercentageLineBounded
  ], handleAnimationLoop);
}

function* handleUpdateMachineStateWithLine(currentLine) {
  const interpreterCache = yield call(getInterpreterCache);

  const currentTime = interpreterCache.timeAtLine(currentLine);

  const autoUpdate = yield select((state) => state.viewer3d.automaticallyUpdateLines);

  if(autoUpdate) {
    const { firstLine, max } = interpreterCache.getCurrentToolPathRange(currentLine);
    yield put(actions.viewer3d.setMinLine(firstLine));
    yield put(actions.viewer3d.setMaxLine(max));
  }

  const machineState = interpreterCache.machineStateAtLineAndTime(currentLine, currentTime);
  yield put(actions.machineState.set(machineState));
  yield put(actions.interpreter.setCurrentLineAndTimeNoUpdates({ currentLine, currentTime }));
}

function* handleUpdateMachineStateWithTime(currentTime) {
  const interpreterCache = yield call(getInterpreterCache);

  const currentLine = interpreterCache.lineAtTime(currentTime);

  const autoUpdate = yield select((state) => state.viewer3d.automaticallyUpdateLines);

  if(autoUpdate) {
    const { firstLine, max } = interpreterCache.getCurrentToolPathRange(currentLine);
    yield put(actions.viewer3d.setMinLine(firstLine));
    yield put(actions.viewer3d.setMaxLine(max));
  }

  const machineState = interpreterCache.machineStateAtLineAndTime(currentLine, currentTime);
  yield put(actions.machineState.set(machineState));
  yield put(actions.interpreter.setCurrentLineAndTimeNoUpdates({ currentLine, currentTime }));
}

function* handleUpdateMachineState(action) {
  const interpreterState = yield select(selectInterpreter);

  yield call({
    [actions.interpreter.previousLine]: handlePreviousLine,
    [actions.interpreter.nextLine]: handleNextLine,
    [actions.interpreter.incrementTime]: handleIncrementTime,
    [actions.interpreter.setCurrentLineWithPercentage]: handleSetCurrentLineWithPercentage,
    [actions.interpreter.setCurrentLineWithPercentageLineBounded]: handleSetCurrentLineWithPercentageLineBounded,
    [actions.interpreter.setCurrentLineWithTime]: handleSetCurrentLineWithTime,
    [actions.interpreter.setCurrentLine]: handleSetCurrentLine
  }[action.type], interpreterState, action);
}

function* handleSetCurrentLine(interpreterState, action) {
  const {
    numLines
  } = interpreterState;
  yield call(handleUpdateMachineStateWithLine, Math.min(Math.max(action.payload, 0), numLines-1));
}

function* handlePreviousLine(interpreterState) {
  const { 
    currentLine 
  } = interpreterState;
  yield call(handleUpdateMachineStateWithLine, Math.max(currentLine-1, 0));
}

function* handleNextLine(interpreterState) {
  const { 
    currentLine,
    numLines
  } = interpreterState;
  yield call(handleUpdateMachineStateWithLine, Math.min(currentLine+1, numLines-1) );
}

function* handleIncrementTime(interpreterState, action) {
  const { 
    currentTime, 
    timeMultiplier 
  } = interpreterState;
  yield call(handleUpdateMachineStateWithTime, currentTime+action.payload*timeMultiplier);
}

function* handleSetCurrentLineWithPercentage(interpreterState, action) {
  const {
    approximateTime
  } = interpreterState;
  yield call(handleUpdateMachineStateWithTime, approximateTime*action.payload/100);
}

function* handleSetCurrentLineWithPercentageLineBounded(interpreterState, action) {
  const {
    approximateTime
  } = interpreterState;
  const {
    percentage,
    minLine,
    maxLine
  } = action.payload

  const interpreterCache = yield call(getInterpreterCache);
  const requestedLine = interpreterCache.lineAtTime(approximateTime*percentage/100)
  const boundedLine = Math.min(maxLine, Math.max(minLine, requestedLine));
  if(requestedLine !== boundedLine) {
    yield call(handleUpdateMachineStateWithLine, boundedLine);
  } else {
    yield call(handleUpdateMachineStateWithTime, approximateTime*percentage/100);
  }
}

function* handleSetCurrentLineWithTime(interpreterState, action) {
  yield call(handleUpdateMachineStateWithTime, action.payload);
}

export function* updateMachineState() {
  yield takeLatest([
    actions.interpreter.previousLine,
    actions.interpreter.nextLine,
    actions.interpreter.incrementTime,
    actions.interpreter.setCurrentLineWithPercentage,
    actions.interpreter.setCurrentLineWithPercentageLineBounded,
    actions.interpreter.setCurrentLineWithTime,
    actions.interpreter.setCurrentLine
  ], handleUpdateMachineState);
}

function* handleRun() {
  const interpreterCache = yield call(getInterpreterCache);
  const machineState = yield select(selectMachineState);
  interpreterCache.interpretEditorContents(machineState);
}

function* handleRunReplaceSelectionWithTCPC() {
  const interpreterCache = yield call(getInterpreterCache);
  const machineState = yield select(selectMachineState);
  interpreterCache.interpretEditorContentsReplacingSelectionWithTCPC(machineState);
}

function* runSaga() {
  yield takeLatest([
    actions.interpreter.run
  ], handleRun);
}

function* runWithSelectionReplacedWithTCPC() {
  yield takeLatest([
    actions.interpreter.runReplaceSelectionWithTcpc
  ], handleRunReplaceSelectionWithTCPC);
}

export default function* rootInterpreterSaga() {
  yield fork(singletonInterpreterCacheSaga);
  yield fork(animationSaga);
  yield fork(updateMachineState);
  yield fork(runSaga);
  yield fork(runWithSelectionReplacedWithTCPC);
}
