import { v4 as uuidv4 } from 'uuid';

class Analytics {
  static getInstance() {
    if(!Analytics.instance) {
      Analytics.instance = new Analytics();
    }

    return Analytics.instance;
  }

  constructor() {
    const sessionStorage = window.sessionStorage;

    let uuid = sessionStorage.getItem("uuid");
    if(!uuid) {
      uuid = uuidv4();
      sessionStorage.setItem("uuid", uuid);
    }

//    this.visitor = ua(process.env.REACT_APP_GOOGLE_ANALYTICS, uuid)
//    this.originalVisitor = this.visitor;

    if(process.env.REACT_APP_DEBUG_ANALYTICS === "true") {
      console.log("Created visitor with uuid", uuid);
      console.log("Account and Property ID", process.env.REACT_APP_GOOGLE_ANALYTICS);
    }

  }

  callback = (err) => {
    if(err) {
      console.error("Error while issuing analytics request", err);
    }
  };

  pageview(...args) {
    if(process.env.REACT_APP_DEBUG_ANALYTICS === "true") {
      console.log("Page View", ...args);

      args.push(this.callback);
    }
//    this.visitor = this.visitor.pageview(...args);
    return this;
  }

  send() {
//    this.visitor.send();
//    this.visitor = this.originalVisitor;

    return this;
  }

  event(...args) {
    if(process.env.REACT_APP_DEBUG_ANALYTICS === "true") {
      console.log("Event", ...args);
      args.push(this.callback);
    }
//    this.visitor = this.visitor.event(...args);

    return this;
  }

  exception(...args) {
    if(process.env.REACT_APP_DEBUG_ANALYTICS === "true") {
      console.log("Exception", ...args);
      args.push(this.callback);
    }
//    this.visitor = this.visitor.exception(...args);

    return this;
  }

  timing(...args) {
    if(process.env.REACT_APP_DEBUG_ANALYTICS === "true") {
      console.log("Timing", ...args);
      args.push(this.callback);
    }
//    this.visitor = this.visitor.timing(...args);

    return this;
  }
};

export default Analytics.getInstance;
