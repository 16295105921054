import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TouchIcon from '@mui/icons-material/TouchApp';
import SwipeIcon from '@mui/icons-material/Swipe';
import PinchIcon from '@mui/icons-material/Pinch';
import FeaturesIcon from '@mui/icons-material/DisplaySettings';
import DoneIcon from '@mui/icons-material/Done';
import { TRY_ME, SWIPE, PINCH, FEATURES, DONE } from '../constants/demo';
import { useGesture } from '@use-gesture/react';
import now from 'performance-now';
import QRCode from 'react-qr-code';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';

let startedDragging = Number.MAX_VALUE;
let startedPinching = Number.MAX_VALUE;

const setStartedDragging = (val) => startedDragging = val;
const setStartedPinching = (val) => startedPinching = val;

function DemoOverlay(props) {
  const {
    demo,
    onSet
  } = props;

  const { enabled, step, qr, featuresSubStep } = demo || {};
  const open = !!enabled && step === TRY_ME;
  const theme = useTheme();
  const showTimeline = !useMediaQuery(theme.breakpoints.down('sm'));

  useGesture({
    onDragStart: (e) => {
      if(step === SWIPE) {
        setStartedDragging(now());
      }
    },
    onDrag: (e) => {
      if(step === SWIPE && now() - startedDragging > 2000) {
        onSet(PINCH);
      }
    },
    onDragEnd: (e) => {
      if(step === SWIPE && now() - startedDragging > 2000) {
        onSet(PINCH);
      }
    },
    onPinchStart: (e) => {
      if(step === PINCH) {
        setStartedPinching(now());
      }
    },
    onPinch: (e) => {
      if(step === PINCH && now() - startedPinching > 500) {
        onSet(FEATURES);
      }
    },
    onPinchEnd: (e) => {
      if(step === PINCH && now() - startedPinching > 500) {
        onSet(FEATURES);
      }
    },
    onWheelStart: (e) => {
      if(step === PINCH) {
        setStartedPinching(now());
      }
    },
    onWheelEnd: (e) => {
      if(step === PINCH && now() - startedPinching > 500) {
        onSet(FEATURES);
      }
    }

  }, { 
    target: document,
    eventOptions: { passive: false },
    drag: {
      pointer: { touch: true }
    },
    wheel: {
    },
    pinch: {
      pointer: { touch: true }
    }
  })

  const onBegin = () => onSet(SWIPE);
  return (
    <>
    { /* Begin - Try Me overlay */ }
      <Modal open={open} onClose={onBegin} onClick={onBegin} sx={{ "& .MuiBackdrop-root": { backgroundColor: "rgba(1,35,27,.6)"} }}>
      <Box sx={ {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'background.paper',
        border: 0,
        outline: 0,
        fontSize: 30
      }}>
      <TouchIcon sx={ { fontSize: 80 } }/> <h1>Try Me!</h1>
      </Box>
      </Modal>
    { /* End - Try Me overlay */ }
    <div style={{
      maxWidth: "100vw",
      position: "absolute",
      display: step === SWIPE ? "block" : "none",
      zIndex: 100,
      borderRadius: 10,
      padding: "10px",
      backgroundColor: "rgba(3,177,136,.9)",
      fontSize: 30,
      color: "white",
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      pointerEvents: "none"
    }}>
      <SwipeIcon sx={ { fontSize: 40 }}/> Swipe anywhere to change your view.
    </div>

    <div style={{
      position: "absolute",
      maxWidth: "100vw",
      display: step === PINCH ? "block" : "none",
      zIndex: 100,
      borderRadius: 10,
      padding: "10px",
      backgroundColor: "rgba(3,177,136,.9)",
      fontSize: 30,
      color: "white",
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      pointerEvents: "none"
    }}>
      <PinchIcon sx={ { fontSize: 40 }}/> Pinch anywhere to zoom in or out.
    </div>

    <div style={{
      position: "absolute",
      maxWidth: "100vw",
      display: (step === FEATURES && featuresSubStep === 0) ? "block" : "none",
      zIndex: 100,
      borderRadius: 10,
      padding: "10px",
      backgroundColor: "rgba(3,177,136,.9)",
      fontSize: 30,
      color: "white",
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      pointerEvents: "none"
    }}>
      Perform the action described by the following prompts.
    </div>

    <div style={{
      position: "absolute",
      display: (enabled && showTimeline) ? "block" : "none",
      zIndex: 100,
      borderRadius: 10,
      padding: "4px",
      fontSize: 30,
      color: "white",
      width: "400px",
      top: 0,
      left: 0
    }}>
      <Timeline position="right">
        <TimelineItem sx={{ "&::before": { padding: 0, width: 0, content: 'none' } }}>
          <TimelineSeparator>
            <TimelineDot color={ step === TRY_ME ? "primary" : undefined } onClick={() => onSet(TRY_ME)}>
              <TouchIcon/>
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="h6" component="span">Try Me!</Typography>
            <Typography>Touch to start.</Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem sx={{ "&::before": { padding: 0, width: 0, content: 'none' } }}>
          <TimelineSeparator>
            <TimelineDot color={ step === SWIPE ? "primary" : undefined } onClick={() => onSet(SWIPE)}>
              <SwipeIcon/>
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="h6" component="span">Swipe</Typography>
            <Typography>Touch and drag to rotate your view.</Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem sx={{ "&::before": { padding: 0, width: 0, content: 'none' } }}>
          <TimelineSeparator>
            <TimelineDot color={ step === PINCH ? "primary" : undefined } onClick={() => onSet(PINCH)}>
              <PinchIcon/>
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="h6" component="span">Pinch</Typography>
            <Typography>Use two fingers to zoom.</Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem sx={{ "&::before": { padding: 0, width: 0, content: 'none' } }}>
          <TimelineSeparator>
            <TimelineDot color={ step === FEATURES ? "primary" : undefined } onClick={() => onSet(FEATURES)}>
              <FeaturesIcon/>
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="h6" component="span">Feature Overview</Typography>
            <Typography>Play through G code like a video, customize the back plot and adjust your camera settings.</Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem sx={{ "&::before": { padding: 0, width: 0, content: 'none' } }}>
          <TimelineSeparator>
            <TimelineDot color={ step === DONE ? "primary" : undefined } onClick={() => onSet(DONE)}>
              <DoneIcon/>
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="h6" component="span">Explore</Typography>
            <Typography>Play around with the simulator!</Typography>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </div>

    { qr ?
    <div style={{
      position: "absolute",
      display: enabled ? "block" : "none",
      zIndex: 100,
      borderRadius: 10,
      padding: "4px",
      fontSize: 20,
      width: "150px",
      color: "white",
      textAlign: "center",
      bottom: 0,
      left: "8px"
    }}>
      <div style={{ marginBottom: "8px" }}>Scan me to try on your phone!</div>
      <QRCode value={"https://links.pentamachine.com/" + qr} level="L" size={70}/>
    </div>
    : null }
    </>
  );
}

export default (DemoOverlay);
