import { createActions } from 'redux-actions';

import distanceMode from './distance-mode';
import feedRateMode from './feed-rate-mode';
import feedRate from './feed-rate';
import units from './units';
import kinematicsMode from './kinematics-mode';
import kinematicsData from './kinematics-data';
import cutterCompensation from './cutter-compensation';
import motion from './motion';
import planeSelect from './plane-select';
import spindleControl from './spindle-control';
import spindleSpeed from './spindle-speed';
import tool from './tool';
import machine from './machine';
import offsets from './offsets';

const actions = {
  machineState: {
    ...distanceMode.machineState,
    ...feedRateMode.machineState,
    ...feedRate.machineState,
    ...kinematicsMode.machineState,
    ...kinematicsData.machineState,
    ...planeSelect.machineState,
    ...units.machineState,
    ...cutterCompensation.machineState,
    ...motion.machineState,
    ...spindleControl.machineState,
    ...spindleSpeed.machineState,
    ...tool.machineState,
    ...machine.machineState,
    ...offsets.machineState,
    ...createActions({
      MACHINE_STATE: {
        SET: undefined
      }
    }).machineState
  }
}

export default actions;
