import React, { Component } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import DefaultInfoIcon from '@mui/icons-material/Info';
import DefaultWarningIcon from '@mui/icons-material/Warning';
import DefaultErrorIcon from '@mui/icons-material/Error';
import Radio from '@mui/material/Radio';

import { SHORT, LONG } from '../constants/machine-state/tool';
import { IN, MM } from '../constants/machine-state/units';
import { inchesToUnits, unitsToInches } from '../util/units';
import { USER_CHOSEN_G5X } from '../constants/viewer3d';

const PREFIX = 'Summary';

const classes = {
  axisLabel: `${PREFIX}-axisLabel`,
  axis: `${PREFIX}-axis`,
  container: `${PREFIX}-container`,
  nested: `${PREFIX}-nested`,
  li: `${PREFIX}-li`,
  liIcon: `${PREFIX}-liIcon`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.axisLabel}`]: {
    display: "inline-block"
  },

  [`&.${classes.axis}`]: {
    lineHeight: "32px",
    verticalAlign: "middle"
  },

  [`& .${classes.container}`]: {
    height: "100%",
    overflow: "scroll",
    marginLeft: 10
  },

  [`& .${classes.nested}`]: {
    paddingLeft: theme.spacing(1)
  },

  [`& .${classes.li}`]: {
    paddingLeft: "8px",
    paddingRight: "8px"
  },

  [`& .${classes.liIcon}`]: {
    marginRight: 0
  }
}));

const NestedDiv = styled('div')`
  && {
    padding-left: 8px
  }
`;

const InfoIcon = styled(DefaultInfoIcon)`
  && {
    color: #3f51b5;
    width: 15px;
  }`;
const WarningIcon = styled(DefaultWarningIcon)`
  && {
    color: #d4ba62;
    width: 15px;
  }`;
const ErrorIcon = styled(DefaultErrorIcon)`
  && {
    color: #ff0000;
    width: 15px;
  }`;


class Summary extends Component {
  handleSetToolLengthOffset = (toolNumber, toolLengthOffset, units) => (e) => {
    const {
      onSetTool
    } = this.props;
    if(e.key === 'Enter' || e.type === 'blur') {
      const Z = unitsToInches(parseFloat(e.target.value), units);

      if(isNaN(Z)) {
        e.target.value = toolLengthOffset;
      } else {
        e.target.value = inchesToUnits(Z, units).toFixed(4);
        const P = toolNumber;

        onSetTool({ P, Z });

      }
      e.target.blur();
      e.preventDefault();
    }
  };
  handleSetToolDiameter = (toolNumber, toolDiameter, units) => (e) => {
    const {
      onSetTool
    } = this.props;
    if(e.key === 'Enter' || e.type === 'blur') {
      const D = unitsToInches(parseFloat(e.target.value), units);

      if(isNaN(D)) {
        e.target.value = toolDiameter;
      } else {
        e.target.value = inchesToUnits(D, units).toFixed(4)
        const P = toolNumber;
        const R = D*.5;

        onSetTool({ P, R });

      }
      e.target.blur();
      e.preventDefault();
    }
  };
  handleSetToolHolder = (toolNumber) => (e) => {
    const {
      onSetTool
    } = this.props;
    const P = toolNumber;
    onSetTool({ P, holder: e.target.value });
  };
  handleSetG5xWorkOffset = (index, axis, currentValue, units) => (e) => {
    const {
      onSetG5xWorkOffset
    } = this.props;
    if(e.key === 'Enter' || e.type === 'blur') {
      const rawValue = parseFloat(e.target.value);
      const value = (axis === 'X' || axis === 'Y' || axis === 'Z') ? unitsToInches(rawValue, units) : rawValue;
      const displayValue = (axis === 'X' || axis === 'Y' || axis === 'Z') ? inchesToUnits(value, units).toFixed(4) : rawValue.toFixed(4);

      if(isNaN(value)) {
        e.target.value = currentValue;
      } else {
        e.target.value = displayValue;

        onSetG5xWorkOffset(index, axis, value);
      }
      e.target.blur();
      e.preventDefault();
    }
  };
  render() {
    const {
      lastSetUnitsMessage,
      initialUnits,
      numLines,
      approximateTime,
      maxLimitMessage,
      minLimitMessage,
      unimplementedMessages,
      nonSummarizedWarnings,
      nonSummarizedErrors,
      wcsChangedMessages,
      toolLengthOffsetMessages,
      running,
      toolTable,
      modelOffsetType,
      modelOffsetIndex,
      modelOffsets,
      modelOffsetLabels
    } = this.props;

    let units = initialUnits;
    const unitsElements = [];

    if(lastSetUnitsMessage) {
      const msgString = "Encountered " + lastSetUnitsMessage.code + " on line " + (lastSetUnitsMessage.line+1) + ". Setting units to " + (lastSetUnitsMessage.code === "G20" ? "inches" : "millimeters") + ".";
      units = (lastSetUnitsMessage.code === "G20" ? IN : MM);
      unitsElements.push(<ListItem key={"lastSetUnitsMessage"} className={classes.li}><ListItemIcon className={classes.liIcon}><InfoIcon/></ListItemIcon><ListItemText>{msgString}</ListItemText></ListItem>);
    } else {
      const msgString = "No units set. Assuming " + (initialUnits === IN ? "inches" : "millimeters") + ".";
      unitsElements.push(<ListItem key={"noUnitsSet"} className={classes.li}><ListItemIcon className={classes.liIcon}><InfoIcon/></ListItemIcon><ListItemText>{msgString}</ListItemText></ListItem>);
    }

    const maxLimitElements = [];
    const minLimitElements = [];

    for(let axis in maxLimitMessage) {
      const limit = maxLimitMessage[axis];
      let msgString = axis + " positive limit exceeded by maximum of " + inchesToUnits(limit.amount, units).toFixed(4) + " on line " + (limit.line+1) + ".";
      if(axis === "Z") {
        // TODO - show value in other units?
        msgString += " Try shortening your tool by at least " + inchesToUnits(limit.amount,units).toFixed(4) + units.toLowerCase() + ".";
      }
      maxLimitElements.push(<ListItem key={axis + "maxlimit"} className={classes.li}><ListItemIcon className={classes.liIcon}><ErrorIcon/></ListItemIcon><ListItemText>{msgString}</ListItemText></ListItem>);

    }

    for(let axis in minLimitMessage) {
      const limit = minLimitMessage[axis];
      let msgString = axis + " negative limit exceeded by maximum of " + inchesToUnits(limit.amount, units).toFixed(4) + " on line " + (limit.line+1) + ".";
      if(axis === "Z") {
        // TODO - show value in other units?
        msgString += " Try extending your tool by at least " + inchesToUnits(limit.amount, units).toFixed(4) + units.toLowerCase() + ".";
      }
      maxLimitElements.push(<ListItem key={axis + "minlimit"} className={classes.li}><ListItemIcon className={classes.liIcon}><ErrorIcon/></ListItemIcon><ListItemText>{msgString}</ListItemText></ListItem>);
    }

    const hours = Math.floor(approximateTime/60/60);
    const minutes = Math.floor(approximateTime/60)%60;
    const seconds = Math.floor(approximateTime)%60;
    const timeParts = [];

    if(hours > 0) {
      timeParts.push(hours + " hour" + (hours !== 1 ? "s" : ""));
    }
    if(minutes > 0) {
      timeParts.push(minutes + " minute" + (minutes !== 1 ? "s" : ""));
    }
    if(seconds > 0) {
      timeParts.push(seconds + " second" + (seconds !== 1 ? "s" : ""));
    }

    let unimplementedElements = null;

    if(unimplementedMessages.length > 0) {
      const codes = {};
      unimplementedMessages.forEach((msg) => {
        codes[msg.code] = msg.description;
      });

      const codeElements = [];
      for(let code in codes) {
        codeElements.push(<ListItem key={code} className={classes.li}><ListItemIcon className={classes.liIcon}><WarningIcon/></ListItemIcon><ListItemText>{code}'s intended usage: {codes[code]}</ListItemText></ListItem>);
      }

      unimplementedElements = [
        <ListItem key="unimplementedText" className={classes.li}> 
          <ListItemIcon className={classes.liIcon}><WarningIcon/></ListItemIcon>
          <ListItemText>
            Found {codeElements.length} code{ codeElements.length !== 1 ? 's' : ''} on {unimplementedMessages.length} line{ unimplementedMessages.length !== 1 ? 's' : ''} that {codeElements.length !== 1 ? "aren't" : "isn't"} implemented in the simulator. A real Penta machine's behavior may differ from what is shown. Check the intended usage of each code below.
          </ListItemText>
        </ListItem>,
        <NestedDiv key="unimplementedCodes">
          <List dense={true} disablePadding className={classes.nested}>{codeElements}</List>
        </NestedDiv>
      ];
    }

    const nonSummarizedWarningsElements = nonSummarizedWarnings.map((msg, i) => (
      <ListItem key={msg.message + i} className={classes.li}>
        <ListItemIcon className={classes.liIcon}>
          <WarningIcon/>
        </ListItemIcon>
        <ListItemText>{ typeof msg.line !== 'undefined' ? "Warning on line " + (msg.line+1) + ": " : ""}{msg.message}</ListItemText>
      </ListItem>
    ));

    const nonSummarizedErrorsElements = nonSummarizedErrors.map((msg, i) => (
      <ListItem key={msg.message + i} className={classes.li}>
        <ListItemIcon className={classes.liIcon}>
          <ErrorIcon/>
        </ListItemIcon>
        <ListItemText>{ typeof msg.line !== 'undefined' ? "Error on line " + (msg.line+1) + ": " : ""}{msg.message}</ListItemText>
      </ListItem>
    ));

    let toolElements = [];
    if(toolLengthOffsetMessages.length > 0) {
      // Here we consolidate the setToolLengthOffset messages to just the tool numbers used (we
      // can change tools several times in a program, but if we reuse the same tool multiple times
      // we just want to show it once). We'll show the last tool length offset in this message,
      // and provide an interface for adjusting that tool length offset. This assumes that the tool 
      // length offset doesn't change during a program, which isn't guaranteed to be the case, 
      // but is certainly our recommended practice. If this ever comes up as a problem, we'll need to 
      // adjust this behavior. The idea is to display the tool length offsets of each of the used
      // tools in the summary tab, with a text box that will be editable if the program is stopped 
      // so that the user can readily adjust the tool length offset of the tools that they're using. 
      // If we detect changes to the tool length offset during a program (using a G10, or our custom 
      // M654 that probes the tool), then maybe we remove the ability to set the tool length offset 
      // from the summary tab. I'm leaving that as an exercise for another time, as I think it will be
      // a very minor use case. This should still work if the tool length offset changes during the 
      // program, but the shown tool length offset may not correctly reflect the tool length offset
      // as it will show the tool length offset at the time the tool was loaded rather than the most
      // up to date version and setting it won't necessarily matter if the program is changing it for you. 
      // It doesn't seem to important to deal with at this time.

      // Same goes for tool diameter and tool holder.

      const tools = {};
      toolLengthOffsetMessages.forEach((msg) => {
        tools[msg.toolNumber] = {
          toolLengthOffset: msg.toolLengthOffset,
          toolDiameter: msg.toolDiameter,
          toolHolder: msg.toolHolder
        }
      });

      for(let tool in tools) {
        const toolNum = parseInt(tool);
        toolElements.push(
          <ListItem key={"usingTool" + tool} className={classes.li}>
            <ListItemIcon className={classes.liIcon}><InfoIcon/></ListItemIcon>
            <ListItemText>Using tool {tool}</ListItemText>
          </ListItem>);
        toolElements.push(
          <ListItem key={"toolLengthOffset" + tool} className={classes.li}>
            <ListItemIcon className={classes.liIcon}><InfoIcon/></ListItemIcon>
            <ListItemText>Tool {tool} Z offset <TextField variant="standard" disabled={running || toolNum === 0} onBlur={this.handleSetToolLengthOffset(tool, tools[tool].toolLengthOffset, units)} onKeyPress={this.handleSetToolLengthOffset(tool, tools[tool].toolLengthOffset, units)} defaultValue={inchesToUnits(tools[tool].toolLengthOffset, units).toFixed(4)}/>.</ListItemText>
          </ListItem>);
        toolElements.push(
          <ListItem key={"toolDiameter" + tool} className={classes.li}>
            <ListItemIcon className={classes.liIcon}><InfoIcon/></ListItemIcon>
            <ListItemText>Tool {tool} Diameter <TextField variant="standard" disabled={running || toolNum === 0} onBlur={this.handleSetToolDiameter(tool, tools[tool].toolDiameter, units)} onKeyPress={this.handleSetToolDiameter(tool, tools[tool].toolDiameter, units)} defaultValue={inchesToUnits(tools[tool].toolDiameter, units).toFixed(4)}/>.</ListItemText>
          </ListItem>);
        toolElements.push(
          <ListItem key={"toolHolder" + tool} className={classes.li}>
            <ListItemIcon className={classes.liIcon}><InfoIcon/></ListItemIcon>
            <ListItemText>Tool {tool} Holder 
              <Radio disabled={running || toolNum === 0} checked={toolTable[toolNum].holder === SHORT} value={SHORT} name={"toolHolder" + tool} aria-label="Short Tool Holder" onChange={this.handleSetToolHolder(tool)} color="primary"/> Short 
              <Radio disabled={running || toolNum === 0} checked={toolTable[toolNum].holder === LONG} value={LONG} name={"toolHolder" + tool} aria-label="Long Tool Holder" onChange={this.handleSetToolHolder(tool)} color="primary"/> Long
            </ListItemText>
          </ListItem>);
      }

    }

    const wcsElements = wcsChangedMessages.map((msg) => {
      const {
        wcs,
        code,
        workOffsets,
        labelOrder
      } = msg;
      const axesElements = labelOrder.map((axis) => {
         return (
           <Root key={axis} className={classes.axis}>
            <span className={classes.axisLabel}>{axis}</span> <TextField variant="standard" disabled={running} onBlur={this.handleSetG5xWorkOffset(wcs, axis, workOffsets[axis], units)} 
                                          onKeyPress={this.handleSetG5xWorkOffset(wcs, axis, workOffsets[axis], units)} 
                                          defaultValue={((axis === 'X' ||
                                                         axis === 'Y' ||
                                                         axis === 'Z') ? inchesToUnits(workOffsets[axis], units) : workOffsets[axis]).toFixed(4)}/>
            </Root>
         );
      });
      return (<ListItem key={"wcsChangd" + wcs} className={classes.li}>
        <ListItemIcon className={classes.liIcon}><InfoIcon/></ListItemIcon>
        <ListItemText>
          {code} Work Offsets
          {axesElements}
        </ListItemText>
      </ListItem>);
    });

    let manuallySelectedModelG5x = null;
    if(modelOffsetType === USER_CHOSEN_G5X) {
      const wcs = modelOffsetIndex;
      const code = [ "", "G54", "G55", "G56", "G57", "G58", "G59", "G59.1", "G59.2", "G59.3" ][wcs];
      const labelOrder = modelOffsetLabels;
      const workOffsets = modelOffsets;

      const axesElements = labelOrder.map((axis) => {
         return (<div key={axis} className={classes.axis}>
          <span className={classes.axisLabel}>{axis}</span> <TextField variant="standard" disabled={running} onBlur={this.handleSetG5xWorkOffset(wcs, axis, workOffsets[axis], units)} 
                                        onKeyPress={this.handleSetG5xWorkOffset(wcs, axis, workOffsets[axis], units)} 
                                        defaultValue={((axis === 'X' ||
                                                       axis === 'Y' ||
                                                       axis === 'Z') ? inchesToUnits(workOffsets[axis], units) : workOffsets[axis]).toFixed(4)}/>
          </div>);
      });
      manuallySelectedModelG5x = (<ListItem key={"manuallySelectedModelG5x" + wcs} className={classes.li}>
          <ListItemIcon className={classes.liIcon}><InfoIcon/></ListItemIcon>
          <ListItemText>
            {code} Work Offsets
            {axesElements}
          </ListItemText>
        </ListItem>);
    }

    return (
      <div className={classes.container}>
        <List dense={true}>
          {minLimitElements}
          {maxLimitElements}
          {nonSummarizedErrorsElements}
          {nonSummarizedWarningsElements}
          {unimplementedElements}
          <ListItem className={classes.li}><ListItemIcon className={classes.liIcon}><InfoIcon fontSize="inherit" color="primary"/></ListItemIcon><ListItemText>{numLines} lines</ListItemText></ListItem>
          <ListItem className={classes.li}><ListItemIcon className={classes.liIcon}><InfoIcon fontSize="inherit" color="primary"/></ListItemIcon><ListItemText>Approximately {timeParts.join(" ")} to run.</ListItemText></ListItem>
          {unitsElements}
          {toolElements}
          {wcsElements}
          {manuallySelectedModelG5x}
        </List>
      </div>
    );
  }
}

Summary.propTypes = {
  numLines: PropTypes.number.isRequired
};

export default (Summary);
