import actions from '../../actions';
import { handleActions } from 'redux-actions';

const reducer = handleActions(
  {
    [actions.machineState.kinematicsData.set]: (state, action) => action.payload
  },
  {
    xy: 0,
    xz: 0,
    yx: 0,
    yz: 0,
    zx: 0,
    zy: 0,
    dx: 0,
    dy: 0,
    dz: 0
  }
);

export default reducer;
