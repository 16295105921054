import { createActions } from 'redux-actions';

const actions = createActions({
  MACHINE_STATE: {
    OFFSETS: {
      SET: undefined,
    }
  }
});

export default actions;
