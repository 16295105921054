import { fork, call, put } from 'redux-saga/effects';
import actions from '../actions';
import queryString from 'query-string';
import { GLTFLoader, OBJLoader, STLLoader } from 'three-stdlib';
import { useInterpreterModel } from '../components/viewer3d/InterpreterModel';
import { interpretFileData } from './interpreter';
import { Color, Group, Mesh, MeshStandardMaterial } from 'three';
import { InterpreterCache } from '../gcode/interpreter-cache';
import { demoSaga } from './demo';
import { useCameraControls } from '../components/viewer3d/CameraControls';
import { FIRST_G5X_ENCOUNTERED, USER_CHOSEN_G5X, CENTER_OF_ROTATION } from '../constants/viewer3d';
import { processingQueue } from './simulation-zip-processing-queue';

const LINKS_URL = "https://links.pocketnc.com/";

// local dev URL - TODO - add an env variable
// to make development easier
//const LINKS_URL = "http://localhost:4500/";

const DEMOS_URL = "https://demos.pocketnc.com/";
//const DEMOS_URL = "http://localhost:4600/http://localhost:4500/";
export const DEFAULT_GCODE = `%
G20
G90 G94 G40 G17
G53 G0 Z0
G0 A0 B0
G0 X0
M5
M0
T4 M6
G43
S8500 M3
F20

G0 A90
G0 X1 Y1
G1 Z0
G1 X-1
G1 y-1
G1 X1
G1 Y1

G49
G53 G0 Z0

M5
M30
%
`;

export const fetchJSON = async (url) => {
  const content = await fetch(url).then((req) => req.json());

  return content;
};

export const fetchContent = async (url) => {
  const content = await fetch(url).then((req) => req.arrayBuffer());

  return content;
};

export const fetchPostUrls = async (post) => {
  const urls = await fetch(LINKS_URL + "?post=" + encodeURI(post)).then((res) => res.json());

  return urls;
};

export default function* loadQueryParams() {
  const query = queryString.parse(window.location.search);

  if(query.zip) {
    const zipData = yield call(fetchContent, DEMOS_URL + query.zip);
    processingQueue.addSimulationZip(zipData);
    return;
  }

  let data = null;
  if(query.demo && query.demo !== "1") {
    // demo is the key for a JSON object in AWS
    data = yield call(fetchJSON, DEMOS_URL + query.demo);

    if(data.titleBar === "hide") {
      yield put(actions.ui.titleBar.hide());
    }

    if(data.gcodePane === "hide") {
      yield put(actions.ui.gcodePane.hide());
    }

    if(data.cameraDistance) {
      const dist = parseFloat(data.cameraDistance); 
      if(!isNaN(dist)) {
        const cameraState = useCameraControls.getState();
        cameraState.setRadius(dist);
      }
    }
    if(data.theta && data.phi) {
      const theta = parseFloat(data.theta)*Math.PI/180; 
      const phi = parseFloat(data.phi)*Math.PI/180; 
      if(!isNaN(theta) && !isNaN(phi)) {
        const cameraState = useCameraControls.getState();
        cameraState.setCameraOrientation(theta, phi);
      }
    }

    if(data.showBetweenLines && (data.showBetweenLines === "true" || data.showBetweenLines === "false")) {
      const showBetweenLines = data.showBetweenLines === "true";
      yield put(actions.viewer3d.setShowBetweenLines(showBetweenLines));
    }

    if(data.minLine) {
      const minLine = parseInt(data.minLine);
      if(!isNaN(minLine)) {
        yield put(actions.viewer3d.setMinLine(minLine));
      }
    }

    if(data.maxLine) {
      const maxLine = parseInt(data.maxLine);
      if(!isNaN(maxLine)) {
        yield put(actions.viewer3d.setMaxLine(maxLine));
      }
    }

    if(data.automaticallyUpdateLines && (data.automaticallyUpdateLines === "true" || data.automaticallyUpdateLines === "false")) {
      const automaticallyUpdateLines = data.automaticallyUpdateLines === "true";
      yield put(actions.viewer3d.setAutomaticallyUpdateLines(automaticallyUpdateLines));
    }

    if(data.tools) {
      for(let tool of data.tools) {
        yield put(actions.machineState.tool.table.setTool(tool));
      }
    }

    if(data.wcs) {
      for(let wcsObj of data.wcs) {
        const { wcs, X, Y, Z, A, B, C } = wcsObj;

        const xParsed = parseFloat(X);
        const yParsed = parseFloat(Y);
        const zParsed = parseFloat(Z);
        const aParsed = parseFloat(A);
        const bParsed = parseFloat(B);
        const cParsed = parseFloat(C);

        const xValue = isNaN(xParsed) ? 0 : xParsed;
        const yValue = isNaN(yParsed) ? 0 : yParsed;
        const zValue = isNaN(zParsed) ? 0 : zParsed;
        const aValue = isNaN(aParsed) ? 0 : aParsed;
        const bValue = isNaN(bParsed) ? 0 : bParsed;
        const cValue = isNaN(cParsed) ? 0 : cParsed;

        yield put(actions.machineState.motion.g5x.setG5xWorkOffset({ index: wcs, axis: "X", value: xValue }));
        yield put(actions.machineState.motion.g5x.setG5xWorkOffset({ index: wcs, axis: "Y", value: yValue }));
        yield put(actions.machineState.motion.g5x.setG5xWorkOffset({ index: wcs, axis: "Z", value: zValue }));
        yield put(actions.machineState.motion.g5x.setG5xWorkOffset({ index: wcs, axis: "A", value: aValue }));
        yield put(actions.machineState.motion.g5x.setG5xWorkOffset({ index: wcs, axis: "B", value: bValue }));
        yield put(actions.machineState.motion.g5x.setG5xWorkOffset({ index: wcs, axis: "C", value: cValue }));
      }
    }

    if(data.machine) {
      yield put(actions.machineState.machine.set(data.machine));
    }

    if(data.feedColor) {
      const color = new Color(data.feedColor);
      yield put(actions.viewer3d.setFeedColor(color.getHex()));
    }

    if(data.rapidColor) {
      const color = new Color(data.rapidColor);
      yield put(actions.viewer3d.setRapidColor(color.getHex()));
    }

    if(data.modelParent) {
      if(data.modelParent === FIRST_G5X_ENCOUNTERED ||
         data.modelParent === CENTER_OF_ROTATION ||
         data.modelParent === USER_CHOSEN_G5X) {
        yield put(actions.viewer3d.setModelOffsetType(data.modelParent));
      }
    }

    if(data.plotOffset) {
      const offset = parseFloat(data.plotOffset);
      if(!isNaN(offset)) {
        yield put(actions.viewer3d.setOffset(offset));
      }
    }

    if(data.model) {
      const model = yield call(fetchContent, DEMOS_URL + data.model);
      if(data.model.toLowerCase().endsWith(".obj")) {
        const decoder = new TextDecoder("utf-8");
        const objLoader = new OBJLoader();

        const obj = objLoader.parse(decoder.decode(model));
        useInterpreterModel.getState().setModel(obj);
      } else if(data.model.toLowerCase().endsWith(".stl")) {
        const stlLoader = new STLLoader();

        const geometry = stlLoader.parse(model);
        const mesh = new Mesh(geometry, new MeshStandardMaterial());
        const obj = new Group();
        obj.add(mesh);
        useInterpreterModel.getState().setModel(obj);
      } else if(data.model.toLowerCase().endsWith(".glb")) {
        yield new Promise((resolve) => {
          const gltfLoader = new GLTFLoader();

          gltfLoader.parse(model, "", (obj) => {
            const nestedObj = new Group();
            nestedObj.add(obj.scene);
            useInterpreterModel.getState().setModel(nestedObj);
            resolve();
          });
        });
      }
      yield put(actions.viewer3d.loadModel());
      yield put(actions.viewer3d.showModel());
    }
  } else if(query.demo === "1") {
    yield fork(demoSaga, query.qr);
  }

  if(query.titleBar === "hide") {
    yield put(actions.ui.titleBar.hide());
  }

  if(query.gcodePane === "hide") {
    yield put(actions.ui.gcodePane.hide());
  }

  if(query.cameraDistance) {
    const dist = parseFloat(query.cameraDistance); 
    if(!isNaN(dist)) {
      const cameraState = useCameraControls.getState();
      cameraState.setRadius(dist);
    }
  }
  if(query.theta && query.phi) {
    const theta = parseFloat(query.theta)*Math.PI/180; 
    const phi = parseFloat(query.phi)*Math.PI/180; 
    if(!isNaN(theta) && !isNaN(phi)) {
      const cameraState = useCameraControls.getState();
      cameraState.setCameraOrientation(theta, phi);
    }
  }

  if(query.showBetweenLines && (query.showBetweenLines === "true" || query.showBetweenLines === "false")) {
    const showBetweenLines = query.showBetweenLines === "true";
    yield put(actions.viewer3d.setShowBetweenLines(showBetweenLines));
  }

  if(query.minLine) {
    const minLine = parseInt(query.minLine);
    if(!isNaN(minLine)) {
      yield put(actions.viewer3d.setMinLine(minLine));
    }
  }

  if(query.maxLine) {
    const maxLine = parseInt(query.maxLine);
    if(!isNaN(maxLine)) {
      yield put(actions.viewer3d.setMaxLine(maxLine));
    }
  }

  if(query.automaticallyUpdateLines && (query.automaticallyUpdateLines === "true" || query.automaticallyUpdateLines === "false")) {
    const automaticallyUpdateLines = query.automaticallyUpdateLines === "true";
    yield put(actions.viewer3d.setAutomaticallyUpdateLines(automaticallyUpdateLines));
  }

  if(query.tool) {
    try {
      let tools = [];
      if(Array.isArray(query.tool)) {
        tools = query.tool.map((json) => JSON.parse(json));
      } else {
        tools.push(JSON.parse(query.tool));
      }
      for(let tool of tools) {
        yield put(actions.machineState.tool.table.setTool(tool));
      }
    } catch(err) {
      console.error("Error parsing tools specified in query string.", err);
    }
  }

  if(query.wcs) {
    try {
      let wcsObjs = [];
      if(Array.isArray(query.wcs)) {
        wcsObjs = query.wcs.map((json) => JSON.parse(json));
      } else {
        wcsObjs.push(JSON.parse(query.wcs));
      }
      for(let wcsObj of wcsObjs) {
        const { wcs, X, Y, Z, A, B, C } = wcsObj;

        const xParsed = parseFloat(X);
        const yParsed = parseFloat(Y);
        const zParsed = parseFloat(Z);
        const aParsed = parseFloat(A);
        const bParsed = parseFloat(B);
        const cParsed = parseFloat(C);

        const xValue = isNaN(xParsed) ? 0 : xParsed;
        const yValue = isNaN(yParsed) ? 0 : yParsed;
        const zValue = isNaN(zParsed) ? 0 : zParsed;
        const aValue = isNaN(aParsed) ? 0 : aParsed;
        const bValue = isNaN(bParsed) ? 0 : bParsed;
        const cValue = isNaN(cParsed) ? 0 : cParsed;

        yield put(actions.machineState.motion.g5x.setG5xWorkOffset({ index: wcs, axis: "X", value: xValue }));
        yield put(actions.machineState.motion.g5x.setG5xWorkOffset({ index: wcs, axis: "Y", value: yValue }));
        yield put(actions.machineState.motion.g5x.setG5xWorkOffset({ index: wcs, axis: "Z", value: zValue }));
        yield put(actions.machineState.motion.g5x.setG5xWorkOffset({ index: wcs, axis: "A", value: aValue }));
        yield put(actions.machineState.motion.g5x.setG5xWorkOffset({ index: wcs, axis: "B", value: bValue }));
        yield put(actions.machineState.motion.g5x.setG5xWorkOffset({ index: wcs, axis: "C", value: cValue }));
      }
    } catch(err) {
      console.error("Error parsing work coordinate systems specified in query string.", err);
    }
  }

  if(query.machine) {
    yield put(actions.machineState.machine.set(query.machine));
  }

  if(query.feedColor) {
    const color = new Color(query.feedColor);
    yield put(actions.viewer3d.setFeedColor(color.getHex()));
  }

  if(query.rapidColor) {
    const color = new Color(query.rapidColor);
    yield put(actions.viewer3d.setRapidColor(color.getHex()));
  }

  if(query.modelParent) {
    if(query.modelParent === FIRST_G5X_ENCOUNTERED ||
       query.modelParent === CENTER_OF_ROTATION ||
       query.modelParent === USER_CHOSEN_G5X) {
      yield put(actions.viewer3d.setModelOffsetType(query.modelParent));
    }
  }

  if(query.plotOffset) {
    const offset = parseFloat(query.plotOffset);
    if(!isNaN(offset)) {
      yield put(actions.viewer3d.setOffset(offset));
    }
  }

  let postUrls = {};
  if(query.post) {
    postUrls = yield call(fetchPostUrls, query.post);
  }

  if(query.model) {
    const model = yield call(fetchContent, DEMOS_URL + query.model);
    if(query.model.toLowerCase().endsWith(".obj")) {
      const decoder = new TextDecoder("utf-8");
      const objLoader = new OBJLoader();

      const obj = objLoader.parse(decoder.decode(model));
      useInterpreterModel.getState().setModel(obj);
    } else if(query.model.toLowerCase().endsWith(".stl")) {
      const stlLoader = new STLLoader();

      const geometry = stlLoader.parse(model);
      const mesh = new Mesh(geometry, new MeshStandardMaterial());
      const obj = new Group();
      obj.add(mesh);
      useInterpreterModel.getState().setModel(obj);
    } else if(query.model.toLowerCase().endsWith(".glb")) {
      yield new Promise((resolve) => {
        const gltfLoader = new GLTFLoader();

        gltfLoader.parse(model, "", (obj) => {
          const nestedObj = new Group();
          nestedObj.add(obj.scene);
          useInterpreterModel.getState().setModel(nestedObj);
          resolve();
        });
      });
    }
    yield put(actions.viewer3d.loadModel());
    yield put(actions.viewer3d.showModel());
  } else if(postUrls.model) {
    const model = yield call(fetchContent, postUrls.model);
    if(postUrls.model.toLowerCase().endsWith(".obj")) {
      const decoder = new TextDecoder("utf-8");
      const objLoader = new OBJLoader();

      const obj = objLoader.parse(decoder.decode(model));
      useInterpreterModel.getState().setModel(obj);
    } else if(postUrls.model.toLowerCase().endsWith(".stl")) {
      const stlLoader = new STLLoader();

      const geometry = stlLoader.parse(model);
      const mesh = new Mesh(geometry, new MeshStandardMaterial());
      const obj = new Group();
      obj.add(mesh);
      useInterpreterModel.getState().setModel(obj);
    } else if(postUrls.model.toLowerCase().endsWith(".glb")) {
      yield new Promise((resolve) => {
        const gltfLoader = new GLTFLoader();

        gltfLoader.parse(model, "", (obj) => {
          useInterpreterModel.getState().setModel(obj.scene);
          resolve();
        });
      });
    }
    yield put(actions.viewer3d.loadModel());
    yield put(actions.viewer3d.showModel());
  }

  if(query.program) {
    yield put(actions.interpreter.setFileName(query.program));
    yield call(interpretFileData, { url: DEMOS_URL + query.program });
  } else if(postUrls.program) {
    yield put(actions.interpreter.setFileName(postUrls.programName || `post.ngc`));
    yield call(interpretFileData, { url: postUrls.program });
  } else if(data && data.program) {
    yield put(actions.interpreter.setFileName(data.program));
    yield call(interpretFileData, { url: DEMOS_URL + data.program });
  } else {
    const gcode = DEFAULT_GCODE;
    yield call(interpretFileData, gcode);
  }

  yield new Promise((resolve) => {
    InterpreterCache.getInstance().addEventListener("end", resolve)
  });

  // Actions placed here will happen after the interpreter completes
  // interpretting the entire program

  if(data && data.currentLine) {
    const line = parseInt(data.currentLine);
    if(!isNaN(line)) {
      yield put(actions.interpreter.setCurrentLine(line));
    }
  }

  if(data && data.t) {
    const t = parseFloat(data.t);
    if(!isNaN(t)) {
      yield put(actions.interpreter.setCurrentLineWithTime(t));
    }
  }

  // Actions placed here will happen after the interpreter completes
  // interpretting the entire program

  if(query.currentLine) {
    console.log("currentLine", query.currentLine);
    const line = parseInt(query.currentLine);
    if(!isNaN(line)) {
      yield put(actions.interpreter.setCurrentLine(line));
    }
  }

  if(query.t) {
    const t = parseFloat(query.t);
    if(!isNaN(t)) {
      yield put(actions.interpreter.setCurrentLineWithTime(t));
    }
  }
}
