import { createSelector } from 'reselect';
import * as types from '../constants/messages';

export const messages = (state) => state.interpreter.messages
export const annotations = createSelector( [ messages ], (messages) => {
  return messages.filter((msg) => typeof msg.line !== 'undefined').map((msg) => ({
    row: msg.line,
    column: 0,
    text: msg.message,
    type: msg.severity
  }));
});

export const maxLimitMessage = createSelector( [ messages ], (messages) => {
  const maxLimitMessages = messages.filter((msg) => msg.type === types.MAXIMUM_LIMIT);
  const axes = {};
  maxLimitMessages.forEach((msg) => {
    if(!axes[msg.axis]) {
      axes[msg.axis] = [];
    }

    axes[msg.axis].push(msg)
  });

  const maxLimit = {};

  for(let axis in axes) {
    maxLimit[axis] = axes[axis].reduce((a,b) => b.amount > a.amount ? b : a, axes[axis][0])
  }

  return maxLimit;
});

export const minLimitMessage = createSelector( [ messages ], (messages) => {
  const minLimitMessages = messages.filter((msg) => msg.type === types.MINIMUM_LIMIT);
  const axes = {};
  minLimitMessages.forEach((msg) => {
    if(!axes[msg.axis]) {
      axes[msg.axis] = [];
    }

    axes[msg.axis].push(msg)
  });

  const minLimit = {};

  for(let axis in axes) {
    minLimit[axis] = axes[axis].reduce((a,b) => b.amount > a.amount ? b : a, axes[axis][0])
  }

  return minLimit;
});

export const unimplementedMessages = createSelector( [ messages ], (messages) => {
  return messages.filter((msg) => msg.type === types.UNIMPLEMENTED);
});

export const nonSummarizedErrors = createSelector( [ messages ], (messages) => {
  return messages.filter((msg) => msg.severity === types.ERROR && [ types.MAXIMUM_LIMIT, types.MINIMUM_LIMIT ].indexOf(msg.type) === -1);
});
export const nonSummarizedWarnings = createSelector( [ messages ], (messages) => {
  return messages.filter((msg) => msg.severity === types.WARNING && [ types.UNIMPLEMENTED ].indexOf(msg.type) === -1);
});

export const setUnitsMessages = createSelector([ messages ], (messages) => {
  return messages.filter((msg) => msg.type === types.SET_UNITS);
});

export const lastSetUnitsMessage = createSelector([ messages ], (messages) => {
  const setUnitsMessages = messages.filter((msg) => msg.type === types.SET_UNITS);

  if(setUnitsMessages.length > 0) {
    return setUnitsMessages[setUnitsMessages.length-1];
  }
});

export const setToolLengthOffsetMessages = createSelector([ messages ], (messages) => {
  return messages.filter((msg) => msg.type === types.SET_TOOL_LENGTH_OFFSET);
});

export const uniqueToolLengthOffsetMessages = createSelector([ setToolLengthOffsetMessages ], (messages) => {
  const tools = {};
  messages.forEach((msg) => {
    tools[msg.toolNumber] = msg;
  });
  return Object.values(tools);
});

export const setWorkCoordinateSystemMessages = createSelector([ messages ], (messages) => {
  return messages.filter((msg) => msg.type === types.SET_WORK_COORDINATE_SYSTEM);
});

export const firstSetWorkCoordinateSystemMessage = createSelector([ messages ], (messages) => {
  return messages.find((msg) => msg.type === types.SET_WORK_COORDINATE_SYSTEM);
});

export const wcsChangedMessages = createSelector([ messages ], (messages) => {
  const dynamicWCSChanges = messages.filter((msg) => msg.type === types.COMPUTED_DYNAMIC_WORK_OFFSETS)
                                    .map((msg) => msg.wcs);
  const seen = {};
  const wcsMsgs = [];
  messages.filter((msg) => msg.type === types.SET_WORK_COORDINATE_SYSTEM && !dynamicWCSChanges.includes(msg.wcs))
          .forEach((msg) => {
            if(!seen[msg.wcs]) {
              wcsMsgs.push(msg);
              seen[msg.wcs] = true;
            }
          });
  return wcsMsgs;
});
