import { select, takeLatest, take, race, fork, delay, call, put } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import actions from '../actions';
import { selectDemo } from '../selectors';
import { TRY_ME, SWIPE, FEATURES } from '../constants/demo';
import { useCameraControls } from '../components/viewer3d/CameraControls';
//import { Group } from 'three';

function userInteractionChannel() {
  return eventChannel((emit) => {
    const callback = () => {
      emit("interaction");
    };
    document.addEventListener("pointerdown", callback);
    document.addEventListener("pointerup", callback);
    document.addEventListener("pointermove", callback);

    return () => {
      document.removeEventListener("pointerdown", callback);
      document.removeEventListener("pointerup", callback);
      document.removeEventListener("pointermove", callback);
    };
  });
}

function *resolveOnUserInteraction() {
  const chan = yield call(userInteractionChannel);
  yield take(chan);
  chan.close();

  return true;
}

function *resolveOnTimeout() {
  yield delay(60*5*1000);
  return true;
}

function *resetDemoOnIdle() {
  while(true) {
    const result = yield race({ userInteraction: call(resolveOnUserInteraction),
                                timeout: call(resolveOnTimeout) });
    if(result.timeout) {
      const demo = yield select(selectDemo);
      const currentTime = yield select((state) => state.interpreter.currentTime);
      if(demo.step !== TRY_ME || currentTime > 15*60) {
        yield put(actions.ui.demo.set(TRY_ME));
      }
    }
    yield delay(5000);
  }
}

function *handleSetStep(action) {
  if(action.payload === TRY_ME) {
    // reset certain settings
    yield put(actions.ui.options.collapse());
    yield put(actions.viewer3d.reset());
    yield put(actions.viewer3d.setOffset(0.006));
    yield put(actions.viewer3d.setAutomaticallyUpdateLines(true));
    yield put(actions.viewer3d.setIsolateSlider(false));
    yield put(actions.viewer3d.setShowBetweenLines(true));
    yield put(actions.viewer3d.loadModel());
    yield put(actions.viewer3d.showModel());
    yield put(actions.ui.controlsOverlay.setAlwaysVisible(false));
    yield put(actions.ui.gcodePane.hide());
    yield put(actions.interpreter.setTimeMultiplier(1));
    const cameraState = useCameraControls.getState();
    cameraState.setFilter(.005);
    cameraState.alignWithDirection(.2, .5, 1);
    cameraState.setRadius(30);
    const tool = cameraState.parents.find((parent) => parent.name === "Tool");
    if(tool) {
      cameraState.setActiveParent(tool);
    }
    /*
    const demoParentExists = cameraState.parents.find((parent) => parent.name === "Demo");
    if(demoParentExists) {
      cameraState.setActiveParent(demoParentExists);
    } else {
      const base = cameraState.parents.find((parent) => parent.name === "Base");
      const demoParent = { name: "Demo", object: new Group() }; 
      demoParent.object.position.x = 3.5;
      demoParent.object.position.y = -.885;
      demoParent.object.position.z = -1;
      base.object.add(demoParent.object);

      cameraState.addParent(demoParent);
      cameraState.setActiveParent(demoParent);
    }
    */
    yield put(actions.interpreter.setCurrentLineWithPercentage(0));
    const isRunning = yield select((state) => state.interpreter.running);
    if(!isRunning) {
      yield put(actions.interpreter.run());
      yield delay(2000);
    }
    yield put(actions.interpreter.unpause());

    while(true) {
      cameraState.alignWithDirection(.2, .5, 1);
      cameraState.setRadius(30);
      yield delay(30000);

      cameraState.setRadius(10);
      cameraState.alignWithDirection(1, .5, 0);

      yield delay(30000);

      cameraState.setRadius(51);
      cameraState.alignWithDirection(1, .5, 0);

      yield delay(30000);
      cameraState.setRadius(51);
      cameraState.alignWithDirection(0, .5, 1);

      yield delay(30000);
      cameraState.setRadius(10);

      yield delay(30000);
      cameraState.alignWithDirection(1, 0, 0);

      yield delay(20000);
    }

  } else if(action.payload === SWIPE) {
    const cameraState = useCameraControls.getState();
    cameraState.setFilter(.1);
    cameraState.alignWithDirection(.2, .5, 1);
    cameraState.setRadius(60);
  } else if(action.payload === FEATURES) {
    yield put(actions.interpreter.setTimeMultiplier(1));

    const isRunning = yield select((state) => state.interpreter.running);
    if(!isRunning) {
      yield put(actions.interpreter.run());
      yield delay(2000);
    }
    yield put(actions.ui.options.collapse());
    yield put(actions.ui.gcodePane.hide());
    yield put(actions.ui.gcodePane.setTab(0));
    yield put(actions.interpreter.pause());
    yield put(actions.ui.demo.setFeaturesSubStep(0));
  }
}

function *resetOnTryMe() {
  yield takeLatest(actions.ui.demo.set, handleSetStep);
}

export function *demoSaga(qr) {
  yield put(actions.ui.demo.enable());
  yield fork(resetDemoOnIdle);
  yield fork(resetOnTryMe);

  if(qr) {
    yield put(actions.ui.demo.setQr(qr));
  }

  yield delay(2000);
  yield put(actions.ui.demo.set(TRY_ME));

}
