import { combineReducers } from 'redux';
import machineState from './machine-state';
import interpreter from './interpreter';
import viewer3d from './viewer3d';
import ui from './ui';

const reducer = combineReducers({
  machineState,
  interpreter,
  ui,
  viewer3d
});

export default reducer;
