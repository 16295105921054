import React, { Component } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

const PREFIX = 'FileSelectButton';

const classes = {
  fileSelect: `${PREFIX}-fileSelect`
};

const Root = styled('span')((
  {
    theme
  }
) => ({
  [`& .${classes.fileSelect}`]: {
    display: "none"
  }
}));

class FileSelectButton extends Component {
  handleFileSelect = (e) => {
    const {
      onFileSelect,
      onRead
    } = this.props;

    if(e.target.files.length === 1) {
      if(onFileSelect) {
        onFileSelect(e.target.files[0]);
      }

      if(onRead) {
        this.readFile(e.target.files[0]);
      }

      this.fileInput.value = "";
    }
  };

  handleFileOpen = (e) => {
    this.fileInput.click();
  };

// adapted from
// https://stackoverflow.com/questions/14438187/javascript-filereader-parsing-long-file-in-chunks
  readFile = (file) => {
    const {
      onRead,
      onFinished,
      onError,
      chunkSize,
      stopReading,
      binary
    } = this.props;

    const fileSize = file.size;
    const cSize = chunkSize || 64*1024;

    let offset = 0;
    let chunkReader = null;

    const handleOnLoad = (e) => {
      if(e.target.error === null) {
        if(binary) {
          // when binary we're readying an ArrayBuffer
          onRead(e.target.result, Math.min(cSize, e.target.result.byteLength), fileSize); 
          offset += e.target.result.byteLength;
        } else {
          // otherwise we're reading a string
          onRead(e.target.result, Math.min(cSize, e.target.result.length), fileSize);
          offset += e.target.result.length;
        }
      } else {
        if(onError) {
          onError(e.target.error);
        }
        return;
      }

      if(offset >= fileSize) {
        if(onFinished) {
          onFinished();
        }
        return;
      }

      if(stopReading && stopReading()) {
        if(onFinished) {
          onFinished();
        }
      } else {
        chunkReader(offset, cSize);
      }
    };

    chunkReader = (_offset, length) => {
      const r = new FileReader();
      const blob = file.slice(_offset, length + _offset);
      r.onload = handleOnLoad;
      if(binary) {
        r.readAsArrayBuffer(blob);
      } else {
        r.readAsText(blob);
      }
    };

    chunkReader(offset, cSize);
  };

  render() {
    const { 
      children, 
      color,
      component,
      disabled,
      disableFocusRipple,
      disableRipple,
      fullWidth,
      href,
      mini,
      size,
      variant,
      buttonClasses,
      accept
    } = this.props;

    return (
      <Root>
        <Button 
          onClick={this.handleFileOpen} 
          classes={buttonClasses} 
          component={component}
          color={color} 
          disabled={disabled}
          disableFocusRipple={disableFocusRipple}
          disableRipple={disableRipple}
          fullWidth={fullWidth}
          href={href}
          mini={mini}
          size={size}
          variant={variant}>{children}</Button>
        <input 
          onChange={this.handleFileSelect} 
          ref={(fileInput) => this.fileInput = fileInput} 
          type="file" 
          accept={accept}
          className={classes.fileSelect}/>
      </Root>
    );
  }
}

FileSelectButton.propTypes = {
  onFileSelect: PropTypes.func,
  onRead: PropTypes.func,
  onFinished: PropTypes.func,
  onError: PropTypes.func,
  stopReading: PropTypes.func
};

export default (FileSelectButton);
