import { lookUpModalGroup } from './modal-groups';
import { usesPositionalArguments } from './positional-arguments';
import { lookUpG5xCommand, lookUpUnitsCommand, lookUpNotImplemented, setPosition, lookUpNonModalCommand, lookUpCommandDescription, lookUpCommand, lookUpParameterCommand } from './commands';
import * as motionConstants from '../constants/machine-state/motion';
import machines from '../machines';

import motionActions from '../actions/machine-state/motion';
import feedRateActions from '../actions/machine-state/feed-rate';
import feedRateModeActions from '../actions/machine-state/feed-rate-mode';
//import toolActions from '../actions/machine-state/tool';

import { INVERSE_TIME, UNITS_PER_MINUTE } from '../constants/machine-state/feed-rate-mode';
import { RAPID, ARC_CW, ARC_CCW } from '../constants/machine-state/motion';
import { parseLine } from './parseLine';
import { parseOWord } from './parseOWord';
import { createStore } from 'redux';
import machineReducer from '../reducers/machine-state';
import * as interpreterMessages from './interpreter-messages';

import { FIVE_AXIS } from '../constants/machine-state/kinematics-mode';
import * as feedRateConstants from '../constants/machine-state/feed-rate-mode';
import { MM } from '../constants/machine-state/units';
import { EPS } from '../constants';
import { loadedTool as loadedToolSelector } from '../selectors';
import { RAPID_COLOR, FEED_COLOR } from '../viewer3d/backplot';
import { calculatePosition, arcLength, arcRadii } from '../util/motion-math';

const SECONDS_PER_MINUTE = 60;
const NUM_LINEAR_AXES = 3;

export class GCodeInterpreter {
  constructor(options) {
    const {
      machineState
    } = options;

    this.store = createStore( machineReducer, machineState );
  }

  getState = () => {
    return this.store.getState();
  };

  interpret = (gcode, messages=[], currentLine=0) => {
    const oword = parseOWord(gcode);
    if(oword) {
      const state = this.store.getState();
      messages.push(interpreterMessages.AddLineNumber(interpreterMessages.OWordsNotSupported(), currentLine));
      return state;
    }

    const groups = {};
    const positionalCommands = [];

    // gcode-parser gets us an easily consumable representation of each line
    // but doesn't provide syntax checking or more advanced GCode features such as 
    // expressions. We'll likely need to implement our own parser at some point.
    const line = parseLine(gcode);


    line.words.forEach(([word, number], lineIndex) => {
      const code = word+number;
      const modalGroup = lookUpModalGroup(code);

      if(modalGroup !== undefined) {
        if(groups[modalGroup]) {
          groups[modalGroup].push(code)
        } else {
          groups[modalGroup] = [ code ];
        }
      }

      if(usesPositionalArguments(code)) {
        positionalCommands.push(code);
      }
    });

    if(positionalCommands.length > 1) {
      messages.push(interpreterMessages.AddLineNumber(interpreterMessages.MultipleCommandsWithPositionalArgs(positionalCommands), currentLine));
    }
    for(let group in groups) {
      if(groups[group].length > 1) {
        messages.push(interpreterMessages.AddLineNumber(interpreterMessages.MultipleCommandsInModalGroup(groups[group], group), currentLine));
      }
    }

    const args = {}; // WARNING - args is mutable, so we are creating actions ahead of time with this object as their payload and then dispatching them all at the end 
    const parameterActions = [];
    const unitsActions = [];
    const actions = [];

    let hasSetToolLengthOffset = false;
    let hasRotatedWorkOffsets = false;
    let hasWCSChange = false;
    let hasG10 = false;

    line.words.forEach((word, i) => {
      const parameterCommand = lookUpParameterCommand(word[0]); 
      if(parameterCommand) {
        parameterActions.push([ parameterCommand, word[1] ]);

        if(word[0] === "T") {
          if(Math.floor(word[1]) !== word[1]) {
            messages.push(interpreterMessages.AddLineNumber(interpreterMessages.NonIntegerToolNumber(word[1]), currentLine));
          }
          if(word[1] < 0) {
            messages.push(interpreterMessages.AddLineNumber(interpreterMessages.ToolNumberLessThanZero(), currentLine));
          }
          if(word[1] === 0) {
            messages.push(interpreterMessages.AddLineNumber(interpreterMessages.ToolNumberEqualToZero(), currentLine));
          }
          if(word[1] > 55) {
            messages.push(interpreterMessages.AddLineNumber(interpreterMessages.LargeToolNumber(), currentLine));
          }
        }
      }

      const code = word[0]+word[1];

      if(code === "G10") {
        hasG10 = true;
      }

      if(code === "G43") {
        // We want to report tool length offset changes
        // to the summary tab, so it can generate
        // a message about used tools and an interface
        // for adjusting the tool table.
        hasSetToolLengthOffset = true;
      }

      if(code === "M254") {
        hasRotatedWorkOffsets = true;
      }

      const wcsCommand = lookUpG5xCommand(code);
      if(wcsCommand) {
        hasWCSChange = true;
      }

      const notImplementedCommand = lookUpNotImplemented(code);

      const unitsCommand = lookUpUnitsCommand(code);
      if(unitsCommand) {
        messages.push(interpreterMessages.AddLineNumber(interpreterMessages.SetUnits(code), currentLine));
        unitsActions.push(unitsCommand());
      }

      const command = lookUpCommand(code);
      if(command) {
        actions.push(command(args))
      }

      const nonModalCommand = lookUpNonModalCommand(code);
      if(nonModalCommand) {
        args[code] = true;
      }

      if(notImplementedCommand) {
        messages.push(interpreterMessages.AddLineNumber(interpreterMessages.UnimplementedCode(code), currentLine));
      }

      if(!unitsCommand && !notImplementedCommand && !nonModalCommand && !command && !parameterCommand) {
        args[word[0]] = word[1];
        
        if(word[0] === "G" || word[0] === "M") {
          messages.push(interpreterMessages.AddLineNumber(interpreterMessages.UnrecognizedCode(code), currentLine))
        }
      }
    });

    let hasMotionCommand = groups[1];

    // if no positional commands are provided, but we have positional arguments, assume the previous motion command by
    // issuing a SET_POSITION action
    if(positionalCommands.length === 0) {

      // this checks if any position arguments exist in the args map
      if(["X", "Y", "Z", "A", "B", "C", "I", "J", "K"].reduce((a, b) => a || args[b] !== undefined, false)) {
        actions.push(setPosition(args));
        hasMotionCommand = true;
      }
    }

    if(!hasMotionCommand) {
      actions.push(motionActions.machineState.motion.nonMotionCommand());
    }

    if(hasSetToolLengthOffset) {
      if(typeof args.H !== 'undefined') {
        if(args.H !== Math.floor(args.H)) {
          args.H = Math.floor(args.H);
          messages.push(interpreterMessages.AddLineNumber(interpreterMessages.NonIntegerToolNumber(args.H), currentLine));
        }

        if(args.H > 55) {
          messages.push(interpreterMessages.AddLineNumber(interpreterMessages.LargeToolNumber(), currentLine));
        }
      }
    }

    unitsActions.forEach((action) => {
      this.store.dispatch(action);
    });

    const state = this.store.getState();
    const stateAfterUnits = this.store.getState();
    const units = stateAfterUnits.units;

    // TODO - This is a temporary way of automatically detecting tool properties
    // for the IMTS demo. This is looking for specific comment lines that the 
    // Mastercam post outputs about tools that it is using.
//    if(gcode.startsWith("(")) {
//      const regex = /T(\d+)/;
//      const found = gcode.match(regex);
//      if(found) {
//        const P = parseInt(found[1]);
//        const gcodeLower = gcode.toLowerCase();
//        const facemill = gcodeLower.includes("facemill");
//        const ball = gcodeLower.includes("ball");
//        const engrave = gcodeLower.includes("engraver");
//        const holder = facemill ? "NONE" : (state.machine === "SOLO" ? "HOLDER1" : "LONG");
//        actions.push(toolActions.machineState.tool.table.setTool({ 
//          P, 
//          holder,
//          type: ball ? "BALL" : (engrave ? "ENGRAVE" : "FLAT")
//        }));
//      }
//    }


    args.units = units;
    args.machineState = state; // use this sparingly, but it is necessary in rare cases
                               // such as M254, which needs the current machine
                               // to calculate dynamic work offsets
    const hasFeedRateModeAction = actions.find((action) => action.type === feedRateModeActions.machineState.feedRateMode.setInverseTime.toString() ||
                                                           action.type === feedRateModeActions.machineState.feedRateMode.setUnitsPerMinute.toString() ||
                                                           action.type === feedRateModeActions.machineState.feedRateMode.setUnitsPerRevolution.toString());

    const hasFeedRate = parameterActions.find(([ actionCreator, p ]) => actionCreator === feedRateActions.machineState.feedRate.set && parseFloat(p) > EPS);
    const hasFeedRateMove = actions.find((action) => action.type === motionActions.machineState.motion.linear.toString() ||
                                                     action.type === motionActions.machineState.motion.arcCW.toString() ||
                                                     action.type === motionActions.machineState.motion.arcCCW.toString());
    if(hasFeedRateModeAction && !hasFeedRate && hasFeedRateMove) {
      messages.push(interpreterMessages.AddLineNumber(interpreterMessages.FeedRateModeResetsFeedRate(), currentLine));
    }

    if(state.feedRateMode === INVERSE_TIME || hasFeedRateModeAction) {
      // if we're starting the line in inverse time mode, we need to set the feed rate to 0 as
      // a feed rate is required on every motion line when in inverse time mode.

      // OR - reset the feed rate to 0 if a feed rate mode action will be run on this line
      // See SOFT-109 for a better way to do this.
      this.store.dispatch(feedRateActions.machineState.feedRate.set(0));
    }

    parameterActions.forEach(([ actionCreator, arg ]) => {
      this.store.dispatch(actionCreator(arg));
    });

    actions.forEach((action) => {
      this.store.dispatch(action);
    });

    let nextState = this.store.getState();

    if(hasMotionCommand) {
      const machine = machines[nextState.machine];

      messages.push(...(machine.getLimitErrors(nextState.joints, machine.limits).map((msg) => interpreterMessages.AddLineNumber(msg, currentLine))));
    }

    if(nextState.motion.mode === ARC_CW || nextState.motion.mode === ARC_CCW) {
      if(args.R) {
        messages.push(interpreterMessages.AddLineNumber(interpreterMessages.RadiusFormatArc(), currentLine));
      }

      // Error conditions are documented here: http://linuxcnc.org/docs/2.8/html/gcode/g-code.html#gcode:g2-g3
      const { r0, r1 } = arcRadii(state.motion.position, nextState.motion.position, nextState.planeSelect, nextState.motion.mode);
      const diff = Math.abs(r0-r1);
      if(diff > .05) {
        messages.push(interpreterMessages.AddLineNumber(interpreterMessages.RadiusDiffers(r0,r1), currentLine));
      }
      if(diff > .005 && (diff > .001*r0 || diff > .001*r1)) {
        messages.push(interpreterMessages.AddLineNumber(interpreterMessages.RadiusDiffers(r0,r1), currentLine));
      }
    }

    if(hasG10) {
      if(typeof args.L !== 'undefined' && typeof args.P !== 'undefined') {
        messages.push(interpreterMessages.AddLineNumber(interpreterMessages.SetWorkOffsets(args.P, nextState.motion.g5x.offsets[args.P], machines[nextState.machine].jointLabels), currentLine));
      }
      if(typeof args.L !== 'undefined' && args.L !== 2) {
        messages.push(interpreterMessages.AddLineNumber(interpreterMessages.UnimplementedCode("G10 L" + args.L, lookUpCommandDescription("G10")), currentLine));
      }
    }

    if(hasRotatedWorkOffsets) {
      messages.push(interpreterMessages.AddLineNumber(interpreterMessages.ComputedRotatedWorkOffsets(args.P, 
                                                             nextState.motion.g5x.index, 
                                                             nextState.motion.g5x.offsets[args.P],
                                                             machines[nextState.machine].jointLabels), currentLine));
    }
    if(hasWCSChange) {
      messages.push(interpreterMessages.AddLineNumber(interpreterMessages.SetWorkCoordinateSystem(nextState.motion.g5x.index, 
                                                          nextState.motion.g5x.offsets[nextState.motion.g5x.index],
                                                          machines[nextState.machine].jointLabels), currentLine));
    }

    if(hasSetToolLengthOffset) {
      const tool = nextState.tool;
      const loadedToolNumber = tool.loaded;

      let toolNumber = loadedToolNumber;
      if(typeof args.H !== 'undefined' && args.H > 0) {
        toolNumber = args.H;
      }

      toolNumber = Math.min(toolNumber, 55);

      messages.push(interpreterMessages.AddLineNumber(interpreterMessages.SetToolLengthOffset(toolNumber, tool.toolLengthOffset.Z, tool.toolLengthOffset.R*2, tool.toolLengthOffset.holder), currentLine))

      if(loadedToolNumber !== toolNumber) {
        messages.push(interpreterMessages.AddLineNumber(interpreterMessages.ToolLengthOffsetCommandDiffersFromLoadedTool(toolNumber, loadedToolNumber), currentLine));
      }
    }

    if(nextState.motion.issuedMotionCommand && 
       nextState.motion.mode !== RAPID && 
       nextState.feedRate < EPS) {
      messages.push(interpreterMessages.AddLineNumber(interpreterMessages.ZeroFeedRate(), currentLine));

      if(nextState.feedRateMode === INVERSE_TIME) {
        messages.push(interpreterMessages.AddLineNumber(interpreterMessages.InverseTimeModeRequiresFeedRate(), currentLine));
      }

      if(state.feedRateMode === INVERSE_TIME &&
        nextState.feedRateMode === UNITS_PER_MINUTE) {
        messages.push(interpreterMessages.AddLineNumber(interpreterMessages.InverseTimeResetsFeedRate(), currentLine));
      }

    }

    return nextState;
  };
}

export function generateBackPlotPoints(points, currentState, nextState, time, t, curIndex) {
  const machine = machines[nextState.machine];
  const currentJoints = currentState.joints;
  const nextJoints = nextState.joints;
  const currentPosition = currentState.motion.position;
  const nextPosition = nextState.motion.position;

  const turns = nextState.motion.turns;
  const motionMode = nextState.motion.mode;
  const planeSelect = nextState.planeSelect;

  // Back plot
  const joints0 = currentJoints;
  const joints1 = nextJoints;
  const state1 = nextState;

  const pos0 = machine.kinematics.forwardKinematics({ ...state1, joints: joints0 });
  const pos1 = state1.motion.position;
  const kinematicsMode = state1.kinematicsMode;
  const workOffsets = currentState.motion.g5x.offsets[currentState.motion.g5x.index];

  // TODO - make more generic, right now these assume an AB
  // configuration. They are used to determine how many samples
  // to approximate arc movement, so they're fairly forgiving when
  // the configuration isn't AB. The arcLengthA and arcLengthB
  // calculations below, though, should be updated to reflect the
  // specific machines configuration. See SOFT-1050.
  const da = Math.abs(joints1[3]-joints0[3]);
  const db = Math.abs(joints1[4]-joints0[4]);
  if(((da > EPS ||
      db > EPS) && (kinematicsMode !== FIVE_AXIS || Math.abs(workOffsets.A) < EPS || Math.abs(workOffsets.B) < EPS || Math.abs(workOffsets.C) < EPS)) ||
      (motionMode !== motionConstants.RAPID &&
       motionMode !== motionConstants.LINEAR)) {
    // rotary or other non-linear moves

    const maxArcAngle = 5;
    const maxDesiredSegmentLength = .1; // We'd prefer all our segments to be shorter than this when approximating curved paths with linear segments
                                         // TODO - smaller numbers give better visual results (spiral.ngc is a good demonstration), but can dramatically slow down processing
                                         // We need to add a way to let the user change this (SOFT-82). 
    const minSamples = 5; // TODO - The minimum number of samples should probably also be configurable, perhaps between 2-20 (SOFT-82)

    let samples = 40;
    let calculateSegments = true;
    let pathPoints = [];
    if((motionMode === motionConstants.RAPID ||
        motionMode === motionConstants.LINEAR) && (db > -EPS || da > -EPS)) {
      const x = Math.max(joints1[0],joints0[0]);
      const y = Math.max(joints1[1],joints0[1]);
      const z = Math.max(machine.toolLength(-joints1[2]), machine.toolLength(-joints0[2]));
      const XZmag = Math.sqrt(x*x+z*z);
      const YZmag = Math.sqrt(y*y+z*z);
      const arcLengthA = da*Math.PI/180*YZmag;
      const arcLengthB = db*Math.PI/180*XZmag;

      samples = Math.max(Math.ceil(arcLengthB/maxDesiredSegmentLength), Math.ceil((arcLengthA/YZmag*180/Math.PI)/maxArcAngle), Math.ceil((arcLengthB/XZmag*180/Math.PI)/maxArcAngle), Math.ceil(arcLengthA/maxDesiredSegmentLength), minSamples);
    } else if(motionMode === motionConstants.ARC_CW ||
              motionMode === motionConstants.ARC_CCW) {
      const arcL = arcLength(currentPosition, nextPosition, planeSelect, motionMode, turns);
      const { r1: arcR } = arcRadii(currentPosition, nextPosition, planeSelect, motionMode);

      samples = Math.max(Math.ceil(arcL/maxDesiredSegmentLength), Math.ceil((arcL/arcR*180/Math.PI)/maxArcAngle), minSamples);
    }

    while(calculateSegments) {
//            let pathLength = 0;
      let maxSegmentLength = 0;

      let lastPt;
      for(let i = 0; i < samples; i++) {
        const param = i/(samples-1);
        const ptTime = time-t+param*t;
        let dt;
        if(param === 0) {
          dt = .01/t;
        } else {
          dt = -.01/t;
        }
        const turns = nextState.motion.turns;
        const pos = calculatePosition(motionMode, pos0, pos1, param, planeSelect, turns, true);
        const pos2 = calculatePosition(motionMode, pos0, pos1, param+dt, planeSelect, turns, true);

        const joints = machine.kinematics.inverseKinematics({ ...state1, motion: { ...state1.motion, position: pos }});
        const joints2 = machine.kinematics.inverseKinematics({ ...state1, motion: { ...state1.motion, position: pos2 }});

        const currentToolNumberLoaded = currentState.tool.loaded;
        const nextToolNumberLoaded = nextState.tool.loaded;
        const currentLoadedToolLengthOffset = currentToolNumberLoaded === 0 ? -machine.toolLength(0) : loadedToolSelector(currentState).Z;
        const nextLoadedToolLengthOffset = nextToolNumberLoaded === 0 ? -machine.toolLength(0) : loadedToolSelector(nextState).Z;

        const nextStateWithCurrentWorkOffsets = { ...state1, joints, motion: { ...state1.motion, g5x: currentState.motion.g5x }, tool: { ...currentState.tool, toolLengthOffset: { ...currentState.tool.toolLengthOffset, Z: currentLoadedToolLengthOffset } } };
        const workPiecePos = machine.workPieceSpace(nextStateWithCurrentWorkOffsets);
        const normal = machine.toolDirection(nextStateWithCurrentWorkOffsets);

        const nextStateWithNextWorkOffsets = { ...state1, joints: joints2, motion: { ...state1.motion, g5x: nextState.motion.g5x }, tool: { ...nextState.tool, toolLengthOffset: { ...nextState.tool.toolLengthOffset, Z: nextLoadedToolLengthOffset } } };
        const workPiecePos2 = machine.workPieceSpace(nextStateWithNextWorkOffsets);

        const pt = [ workPiecePos.X, workPiecePos.Y, workPiecePos.Z ];
        const pt2 = [ workPiecePos2.X, workPiecePos2.Y, workPiecePos2.Z ];
        const n = [ normal.X, normal.Y, normal.Z ];

        let speed;
        if(dt > 0) {
          const dx = pt2[0]-pt[0];
          const dy = pt2[1]-pt[1];
          const dz = pt2[2]-pt[2];
          speed = Math.sqrt(dx*dx+dy*dy+dz*dz)/.01;
        } else {
          const dx = pt[0]-pt2[0];
          const dy = pt[1]-pt2[1];
          const dz = pt[2]-pt2[2];
          speed = Math.sqrt(dx*dx+dy*dy+dz*dz)/.01;
        }

        if(motionMode === motionConstants.RAPID) {
          pathPoints.push({ position: pt, color: RAPID_COLOR, speed, time: ptTime, line: curIndex, normal: n, colorIndex: 0 });
        } else {
          pathPoints.push({ position: pt, color: FEED_COLOR, speed, time: ptTime, line: curIndex, normal: n, colorIndex: 1 });
        }

        if(i > 0) {
          const dx = pt[0]-lastPt[0];
          const dy = pt[1]-lastPt[1];
          const dz = pt[2]-lastPt[2];
          const segmentLength = Math.sqrt(dx*dx+dy*dy+dz*dz);
          maxSegmentLength = Math.max(maxSegmentLength, segmentLength);
//                pathLength += segmentLength;
        }
        lastPt = pt;
      }

      if(maxSegmentLength > 1.5*maxDesiredSegmentLength) {
        samples = Math.ceil(samples*maxSegmentLength/maxDesiredSegmentLength);
        pathPoints = [];
      } else {
        calculateSegments = false;
      }
    }

    for(let i = 0; i < pathPoints.length; i++) {
      points.push(pathPoints[i]);
    }
  } else {
    const currentToolNumberLoaded = currentState.tool.loaded;
    const nextToolNumberLoaded = nextState.tool.loaded;
    const currentLoadedToolLengthOffset = currentToolNumberLoaded === 0 ? -machine.toolLength(0) : loadedToolSelector(currentState).Z;
    const nextLoadedToolLengthOffset = nextToolNumberLoaded === 0 ? -machine.toolLength(0) : loadedToolSelector(nextState).Z;

    // current machine state with the toolLengthOffset set to be the face of the spindle if no tool is loaded (i.e. loaded tool === 0)
    const currentStateWithNoLoadedTool = { ...currentState, tool: { ...currentState.tool, toolLengthOffset: { ...currentState.tool.toolLengthOffset, Z: currentLoadedToolLengthOffset }}};
    const currentWorkPieceSpace = machine.workPieceSpace(currentStateWithNoLoadedTool);

    // next machine state with the toolLengthOffset set to be the face of the spindle if no tool is loaded (i.e. loaded tool === 0)
    const nextStateWithNoLoadedTool = { ...nextState, tool: { ...nextState.tool, toolLengthOffset: { ...nextState.tool.toolLengthOffset, Z: nextLoadedToolLengthOffset }}};
    const nextWorkPieceSpace = machine.workPieceSpace(nextStateWithNoLoadedTool);
    const normal0 = machine.toolDirection(currentState);
    const normal1 = machine.toolDirection(nextState);
    const nx0 = normal0.X;
    const ny0 = normal0.Y;
    const nz0 = normal0.Z;
    const nx1 = normal1.X;
    const ny1 = normal1.Y;
    const nz1 = normal1.Z;
    const x0 = currentWorkPieceSpace.X;
    const y0 = currentWorkPieceSpace.Y;
    const z0 = currentWorkPieceSpace.Z;
    const x1 = nextWorkPieceSpace.X;
    const y1 = nextWorkPieceSpace.Y;
    const z1 = nextWorkPieceSpace.Z;
    const dx = x1-x0;
    const dy = y1-y0;
    const dz = z1-z0;
    const speed = Math.sqrt(dx*dx+dy*dy+dz*dz)/t;
    switch(motionMode) {
      case motionConstants.RAPID:
        points.push({ position: [ x0, y0, z0 ], color: RAPID_COLOR, speed: speed, time: time-t, line: curIndex, normal: [ nx0, ny0, nz0 ], colorIndex: 0 });
        points.push({ position: [ x1, y1, z1 ], color: RAPID_COLOR, speed: speed, time, line: curIndex, normal: [ nx1, ny1, nz1 ], colorIndex: 0 });
        break;
      case motionConstants.LINEAR:
        points.push({ position: [ x0, y0, z0 ], color: FEED_COLOR, speed: speed, time: time-t, line: curIndex, normal: [ nx0, ny0, nz0 ], colorIndex: 1 });
        points.push({ position: [ x1, y1, z1 ], color: FEED_COLOR, speed: speed, time, line: curIndex, normal: [ nx1, ny1, nz1 ], colorIndex: 1 });
        break;
      default:
        console.error("shouldn't get here");
        break;
    }
  }
}

export function timeBetweenLines(currentState, nextState, line, currentLine) {
  const machine = machines[nextState.machine];
  const currentJoints = currentState.joints;
  const nextJoints = nextState.joints;
  const currentPosition = currentState.motion.position;
  const nextPosition = nextState.motion.position;

  if(nextState.motion.issuedMotionCommand) {
    const turns = nextState.motion.turns;
    const motionMode = nextState.motion.mode;
    const planeSelect = nextState.planeSelect;
    const units = nextState.units;

    let t = 0.001; // move takes a minimum of 1 servo period (1ms unless we use a controller that can handle a smaller period)

    const commandedFeedRate = nextState.feedRate;
    let linearFeedRate = nextState.feedRate;
    const feedRateMode = nextState.feedRateMode;
    if(units === MM) {
      // convert feedRate to inches
      linearFeedRate /= 25.4;
    }

    // All coordinates will be in inches by this point

    if(motionMode !== motionConstants.RAPID) {
      if(feedRateMode === feedRateConstants.INVERSE_TIME) {
        if(commandedFeedRate >= EPS) {
          t = 1./commandedFeedRate*SECONDS_PER_MINUTE;
        }
      } else if(feedRateMode === feedRateConstants.UNITS_PER_MINUTE) {
        if(motionMode === motionConstants.LINEAR) {
          if(linearFeedRate >= EPS) {
            let maxLinearMove = 0;
            for(let j = 0; j < NUM_LINEAR_AXES; j++) {
              const dj = Math.abs(nextJoints[j]-currentJoints[j]);
              maxLinearMove = Math.max(maxLinearMove, dj);

              t = Math.max(t, dj/linearFeedRate*SECONDS_PER_MINUTE);
            }
            if(maxLinearMove > -EPS && maxLinearMove < EPS) {
              // no linear movement, so units are now in degrees/minute
              for(let j = 3; j < currentJoints.length; j++) {
                const dj = Math.abs(nextJoints[j]-currentJoints[j]);
                t = Math.max(t, dj/commandedFeedRate*SECONDS_PER_MINUTE);
              }
            }
          }
        } else if(motionMode === motionConstants.ARC_CW ||
                  motionMode === motionConstants.ARC_CCW) {
          if(linearFeedRate >= EPS) {
            const arcL = arcLength(currentPosition, nextPosition, planeSelect, motionMode, turns);
            t = arcL/linearFeedRate*SECONDS_PER_MINUTE;
          }
        } else if(motionMode === motionConstants.OFF) {
          t = 0;
        } else {
          t = 0;
          // TODO other canned cycles
        }
      } else {
        // TODO Units per revolution
          t = 3;
      }
    }

    // make sure we don't go faster than any of our joints can go
    // doesn't take into account arcs and canned cycles, so make sure
    // to calculate time for those before here
    const maxFeedRates = machine.limits.velocities; // in units/second
    const maxAccelerations = machine.limits.accelerations; // in units/second
    let maxRotaryMove = 0;
    for(let j = 3; j < currentJoints.length; j++) {
      const dj = Math.abs(nextJoints[j]-currentJoints[j]);
      maxRotaryMove = Math.max(dj, maxRotaryMove);
    }
    for(let j = 0; j < currentJoints.length; j++) {
      const accelDist = maxFeedRates[j]*maxFeedRates[j]/(.5*maxAccelerations[j]);
      const dj = Math.abs(nextJoints[j]-currentJoints[j]);
      if(dj > EPS) {
        let jointT = 0;
        if(maxRotaryMove < EPS && maxRotaryMove > -EPS) {
          jointT = dj/maxFeedRates[j]; // MachineKit combines linear moves when possible to limit how much acceleration/deceleration 
                                       // is required so ignoring acceleration seems to better approximate the actual time it takes
                                       // on pure linear moves.
        } else {
          // When coordinating rotary+linear moves, acceleration is often the limiting factor 
          // See http://www.machinekit.io/docs/common/User_Concepts/#sec:trajectory-control
          if(dj >= accelDist) {
            // Move is long enough to reach max velocity, taking max acceleration into account, but it takes
            // a bit of time to accelerate to max velocity, so take that time into account.
            jointT = Math.max(dj-accelDist)/maxFeedRates[j]+2*maxFeedRates[j]/(.5*maxAccelerations[j]);
          } else {
            // Move isn't long enough to reach max velocity, so calculate time to it takes to go half the necessary
            // distance at max acceleration + time it takes to decelerate back to 0 (deceleration time is the same
            // as the acceleration time, so twice the time it takes to accelerate).

            jointT = Math.sqrt(dj/maxAccelerations[j]);
          }
        }
        t = Math.max(t, jointT);
      }
    }

    return t;
  } else {
    if(line.indexOf("M999") > -1) {
      return 2;
    }
  }
  return 0;
}

export default GCodeInterpreter;
