import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FileSelectButton from './FileSelectButton';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';

class ModelLoader extends Component {
  handleFileSelect = (f) => {
    const {
      binary,
      onLoading,
      onError
    } = this.props;

    if(f.name.toLowerCase().endsWith(".obj") || f.name.toLowerCase().endsWith(".stl") || f.name.toLowerCase().endsWith(".glb")) {
      this.fileSize = f.size;
      this.fileName = f.name;
      if(binary) {
        this.data = undefined;
        this.read = 0;
        this.buffer = "";
      } else {
        this.data = undefined;
        this.buffer = undefined;
        this.offset = 0;
      }

      if(onLoading) {
        onLoading();
      }
    } else {
      onError("File name must end with .obj, .stl, or .glb");
    }
  };

  handleFinished = () => {
    const {
      onLoadModel
    } = this.props;

    this.stopReadingFile = false;

    onLoadModel(this.buffer, this.fileName);
  };

  handleRead = (chunk, size, totalSize) => {
    const {
      onProgress,
      onError
    } = this.props;

    if(!this.data) {
      this.buffer = new ArrayBuffer(totalSize);
      this.data = new Uint8Array(this.buffer);
      this.offset = 0;
    }

    try {
      this.data.set(new Uint8Array(chunk), this.offset);
    } catch(err) {
      if(onError) {
        onError(new Error("Error reading data chunk: " + err.message + ", " + this.offset));
      }
    }

    this.offset += size;
    const progress = Math.min(this.offset/this.fileSize*100, 100);

    if(onProgress) {
      onProgress(progress);
    }
  };

  stopReading = () => {
    return this.stopReadingFile;
  };

  render() {
    const {
      iconOnly,
      labelOnly,
      variant,
      size,
      color,
      label = "Model"
    } = this.props;
    return (
      <FileSelectButton onFileSelect={this.handleFileSelect}
                        onFinished={this.handleFinished}
                        onRead={this.handleRead}
                        stopReading={this.stopReading}
                        chunkSize={64*1024}
                        binary={true}
                        size={size}
                        color={color}
                        accept=".obj,.stl,.glb"
                        variant={variant}>
        { labelOnly ? null : <OpenInBrowserIcon/>} {iconOnly ? null : label}
      </FileSelectButton>
    );
  }
};

ModelLoader.propTypes = {
  onProgress: PropTypes.func,
  onError: PropTypes.func,
  onLoadModel: PropTypes.func,
  stopReading: PropTypes.func
};

export default ModelLoader;
