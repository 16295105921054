import { useMemo, useEffect, useRef } from 'react';
import { useCameraControls, selectAddParent, selectRemoveParent, selectSetActiveParent } from './CameraControls';
import { useThree } from '@react-three/fiber';
import { Vector2, DoubleSide } from 'three';

export const FLAT = "FLAT";
export const BALL = "BALL";
export const ENGRAVE = "ENGRAVE";
export const PROBE = "PROBE";
export const CORNER_ROUND = "CORNER_ROUND";

export default function SimpleTool(props) {
  const { scene } = useThree();
  const {
    diameter = .125,
    length = 10,
    loaded = false,
    tool = {},
    type = FLAT
  } = props;

  const toolRef = useRef();
  const addParent = useCameraControls(selectAddParent);
  const removeParent = useCameraControls(selectRemoveParent);
  const setActiveParent = useCameraControls(selectSetActiveParent);

  const {
    cornerRadius = 0,
    outerRadius = 0
  } = tool;

  const points = useMemo(() => {
    const p = [[diameter*.5, 0] ]
    const NUM_PTS = 10;
    for(let i = 0; i < NUM_PTS; i++) {
      const t = i/(NUM_PTS-1);
      const angle = Math.PI*(1-t)+Math.PI/2*t;
      const r = cornerRadius;
      const cx = r*Math.cos(angle) + diameter*.5 + cornerRadius;
      const cy = r*Math.sin(angle);
      p.push([ cx, cy ]);
    }
    p.push([ outerRadius, cornerRadius ]);
    p.push([ outerRadius, length ]);

    return p.map((pt) => new Vector2( pt[0], pt[1] ));
  }, [ diameter, cornerRadius, outerRadius, length ]);

  useEffect(() => {
    const toolParent = { name: "Tool", object: toolRef.current };

    addParent(toolParent);
    setActiveParent(toolParent);

    return () => {
      removeParent(toolParent);
    };
  }, [ addParent, removeParent, toolRef, setActiveParent ]);

  return <group>
    <group ref={toolRef} position-z={loaded ? -length : 0}/>
    { type === FLAT ? 
    <mesh rotation-x={Math.PI/2} position-z={-length*.5} visible={loaded}>
      <cylinderGeometry args={[ diameter*.5, diameter*.5, length, 20, 1, false, 0, 2*Math.PI ]}/>
      <meshStandardMaterial metalness={.9} roughness={.232} color={0xaaaaaa} envMap={scene.envMap} envMapIntensity={2} side={DoubleSide}/>
    </mesh> : null }
    { type === BALL ? 
    <mesh rotation-x={Math.PI/2} position-z={-length*.5+diameter*.5} visible={loaded}>
      <capsuleGeometry args={[ diameter*.5, length, 20, 20 ]}/>
      <meshStandardMaterial metalness={.9} roughness={.232} color={0xaaaaaa} envMap={scene.envMap} envMapIntensity={2} side={DoubleSide}/>
    </mesh> : null }
    { type === ENGRAVE ? 
    <group>
      <mesh rotation-x={Math.PI/2} position-z={-(length-diameter)*.5} visible={loaded}>
        <cylinderGeometry args={[ diameter*.5, diameter*.5, length-diameter, 20, 1, true, 0, 2*Math.PI ]}/>
        <meshStandardMaterial metalness={.9} roughness={.232} color={0xaaaaaa} envMap={scene.envMap} envMapIntensity={2} side={DoubleSide}/>
      </mesh>
      <mesh rotation-x={-Math.PI/2} position-z={-length+diameter*.5} visible={loaded}>
        <coneGeometry args={[ diameter*.5, diameter, 20, 1, true, 0, 2*Math.PI ]}/>
        <meshStandardMaterial metalness={.9} roughness={.232} color={0xaaaaaa} envMap={scene.envMap} envMapIntensity={2} side={DoubleSide}/>
      </mesh>
    </group>: null }
    { type === PROBE ? 
    <group>
    <mesh rotation-x={Math.PI/2} position-z={-length*.5} visible={loaded}>
      <cylinderGeometry args={[ 1.5/25.4*.5, 1.5/25.4*.5, length, 20, 1, false, 0, 2*Math.PI ]}/>
      <meshStandardMaterial metalness={.9} roughness={.232} color={0xaaaaaa} envMap={scene.envMap} envMapIntensity={2} side={DoubleSide}/>
    </mesh> 
    <mesh visible={loaded} position-z={-length}>
      <sphereGeometry args={[ diameter*.5, 40, 20 ]}/>
      <meshPhysicalMaterial clearcoat={1} sheen={1} metalness={0} roughness={.1} color={0x882863} envMap={scene.envMap} envMapIntensity={1.5} transparent={true} opacity={.9}/>
    </mesh>
    </group>: null }
    { type === CORNER_ROUND ?
    <group>
      <mesh rotation-x={Math.PI/2} position-z={-length} visible={loaded}>
        <latheGeometry args={[ points, 32 ]}/>
        <meshStandardMaterial metalness={.9} roughness={.232} color={0xaaaaaa} envMap={scene.envMap} envMapIntensity={2} side={DoubleSide}/>
      </mesh>
      <mesh position-z={-length} visible={loaded}>
        <circleGeometry args={[diameter*.5]}/>
        <meshStandardMaterial metalness={.9} roughness={.232} color={0xaaaaaa} envMap={scene.envMap} envMapIntensity={2} side={DoubleSide}/>
      </mesh>
    </group> : null }
  </group>
};
