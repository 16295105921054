import { connect } from 'react-redux';

import OtherOptionsPane from '../components/OtherOptionsPane';
import actions from '../actions';
import { selectDemo } from '../selectors';

export default connect(
  (state, ownProps) => {
    return {
      demo: selectDemo(state)
    };
  },
  (dispatch, ownProps) => ({
    onNextDemoStep: () => dispatch(actions.ui.demo.next()),
    onNextFeaturesSubStep: () => dispatch(actions.ui.demo.nextFeaturesSubStep()),
  })
)(OtherOptionsPane);
