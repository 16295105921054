export const parseOWord = (rawLine) => {
  const line = rawLine.replace(/^n\d+\s*/i, "").toLowerCase()

  const namedMatch = line.match(/^o<([^>]+)>\s*(.*)$/i)
  const numberedMatch = line.match(/^o(\d+)\s*(.*)$/i)

  if(namedMatch) {
    namedMatch[1] = namedMatch[1].replace(/\s*/g, "")
  }

  return namedMatch || numberedMatch;
};
