import actions from '../../actions';
import { handleActions } from 'redux-actions';
import produce from 'immer';

const reducer = handleActions(
  {
    [actions.ui.options.expand]: produce((state, action) => { state.expanded = true }),
    [actions.ui.options.collapse]: produce((state, action) => { state.expanded = false }),
    [actions.ui.options.toggle]: produce((state, action) => { state.expanded = !state.expanded }),
  },
  {
    expanded: false,
  }
);

export default reducer;
