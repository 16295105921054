import { ShaderMaterial, Color } from 'three';
const createBackPlotShader = () => {
  return new ShaderMaterial({
    uniforms: {
      colors: { value: [ new Color(0x000080), new Color(0xffffff) ] },
      offset: { value: .004 },
      minTime: { value: 0 },
      maxTime: { value: 9999999999 },
      minLine: { value: 0 },
      maxLine: { value: 9999999999 },
      maxY: { value: 99999999 },
      maxXZ: { value: 99999999 }
    },
    vertexColors: true,
    vertexShader: `
uniform vec3 colors[2];
uniform float minTime;
uniform float maxTime;
uniform float minLine;
uniform float maxLine;
uniform float offset;
uniform float maxXZ;
uniform float maxY;

attribute float line;
attribute float time;
attribute float speed;
attribute float colorIndex;

varying vec3 vPos;
varying vec3 vColor;
varying float vLine;
varying float vTime;
void main() {
  vPos = position + offset*normal;
  gl_Position = projectionMatrix * modelViewMatrix * vec4( vPos, 1.0 );
  vColor = colors[int(colorIndex)];
  vLine = line;
  vTime = time;
}
`,

    fragmentShader: `
uniform float minTime;
uniform float maxTime;
uniform float minLine;
uniform float maxLine;
uniform float maxXZ;
uniform float maxY;

varying vec3 vColor;
varying vec3 vPos;
varying float vLine;
varying float vTime;
void main()	{
  if(length(vPos.xz) > maxXZ || 
     abs(vPos.y) > maxY ||
     vTime < minTime || vTime > maxTime ||
     vLine < minLine || vLine > maxLine) {

    discard;
  }
  gl_FragColor = vec4(vColor, 1.);
}
`
  });
}
export default createBackPlotShader;
