export const ERROR = "error";
export const WARNING = "warning";
export const INFO = "info";
export const UNIMPLEMENTED = "UNIMPLEMENTED";
export const UNRECOGNIZED = "UNRECOGNIZED";
export const MINIMUM_LIMIT = "MINIMUM_LIMIT";
export const MAXIMUM_LIMIT = "MAXIMUM_LIMIT";
export const MULTIPLE_COMMANDS_IN_MODAL_GROUP = "MULTIPLE_COMMANDS_IN_MODAL_GROUP";
export const MULTIPLE_COMMANDS_WITH_POSITIONAL_ARGS = "MULTIPLE_COMMANDS_WITH_POSITIONAL_ARGS";
export const ZERO_FEED_RATE = "ZERO_FEED_RATE";
export const INVERSE_TIME_REQUIRES_FEED_RATE = "INVERSE_TIME_REQUIRES_FEED_RATE";
export const INVERSE_TIME_RESETS_FEED_RATE = "INVERSE_TIME_RESETS_FEED_RATE";
export const FEED_RATE_MODE_RESETS_FEED_RATE = "FEED_RATE_MODE_RESETS_FEED_RATE";
export const SET_TOOL_LENGTH_OFFSET = "SET_TOOL_LENGTH_OFFSET";
export const TOOL_LENGTH_OFFSET_COMMAND_DIFFERS_FROM_LOADED_TOOL = "TOOL_LENGTH_OFFSET_COMMAND_DIFFERS_FROM_LOADED_TOOL";
export const SET_WORK_COORDINATE_SYSTEM = "SET_WORK_COORDINATE_SYSTEM";
export const SET_WORK_OFFSETS = "SET_WORK_OFFSETS";
export const COMPUTED_DYNAMIC_WORK_OFFSETS = "COMPUTED_DYNAMIC_WORK_OFFSETS";
export const NON_INTEGER_TOOL_NUMBER = "NON_INTEGER_TOOL_NUMBER";
export const TOOL_NUMBER_LESS_THAN_ZERO = "TOOL_NUMBER_LESS_THAN_ZERO";
export const TOOL_NUMBER_EQUAL_TO_ZERO = "TOOL_NUMBER_EQUAL_TO_ZERO";
export const LARGE_TOOL_NUMBER = "LARGE_TOOL_NUMBER";
export const SET_UNITS = "SET_UNITS";
export const RADIUS_DIFFERS = "RADIUS_DIFFERS";
export const RADIUS_FORMAT_ARC = "RADIUS_FORMAT_ARC";
export const OWORD_NOT_SUPPORTED = "OWORD_NOT_SUPPORTED";
