// AUTOMATIC BUILD from pentakins 327cecbf1a479ffe960989494d18c20e3b94af7e
export function joints2table(jointX,jointY,jointZ,jointA,jointB,dy,dz,toolZ) {
  let x0,x1,x2,x3,x4,x5,x6,x7,x,y,z,a,b;
  x0 = Math.sin(jointB);
  x1 = Math.cos(jointB);
  x2 = Math.sin(jointA);
  x3 = Math.cos(jointA);
  x4 = jointZ - toolZ;
  x5 = x3*x4;
  x6 = dz*x1;
  x7 = x1*x2;
  x = -dy*x0*x2 + dz*x0*x3 - dz*x0 + jointX*x1 + jointY*x0*x2 - x0*x5;
  y = -dy*x3 + dy - dz*x2 + jointY*x3 + x2*x4;
  z = dy*x7 + jointX*x0 - jointY*x7 + x1*x5 - x3*x6 + x6;
  a = jointA;
  b = jointB;
  return { x,y,z,a,b };
}
export function table2joints(tableX,tableY,tableZ,tableA,tableB,dy,dz,toolZ) {
  let x0,x1,x2,x3,x4,x5,x,y,z,a,b;
  x0 = Math.cos(tableB);
  x1 = Math.sin(tableB);
  x2 = Math.sin(tableA);
  x3 = Math.cos(tableA);
  x4 = tableX*x1;
  x5 = tableZ*x0;
  x = tableX*x0 + tableZ*x1;
  y = -dy*x3 + dy + dz*x2 + tableY*x3 + x2*x4 - x2*x5;
  z = -dy*x2 - dz*x3 + dz + tableY*x2 + toolZ - x3*x4 + x3*x5;
  a = tableA;
  b = tableB;
  return { x,y,z,a,b };
}
export function joints2tcpc(jointsX,jointsY,jointsZ,jointsA,jointsB,workX,workY,workZ,workA,workB,dy,dz,toolZ) {
  let x0,x1,x2,x3,x4,x5,x6,x7,x8,x9,x10,x11,x12,x13,x14,x15,x16,x17,x18,x19,x20,x,y,z,a,b;
  x0 = Math.sin(jointsB);
  x1 = Math.sin(workB);
  x2 = x0*x1;
  x3 = Math.cos(jointsB);
  x4 = Math.cos(workB);
  x5 = x3*x4;
  x6 = x0*x4;
  x7 = x1*x3 - x6;
  x8 = dz*x7;
  x9 = Math.sin(jointsA);
  x10 = x7*x9;
  x11 = Math.cos(jointsA);
  x12 = jointsZ - toolZ;
  x13 = Math.cos(workA);
  x14 = Math.sin(workA);
  x15 = -x14*x2 - x14*x5;
  x16 = x11*x15 + x13*x9;
  x17 = x11*x13 - x15*x9;
  x18 = x13*x2 + x13*x5;
  x19 = x11*x18 + x14*x9;
  x20 = x11*x14 - x18*x9;
  x = dy*x10 + jointsX*(x2 + x5) - jointsY*x10 - workX + x11*x12*x7 - x11*x8 + x8;
  y = dy*x13 - dy*x17 + dz*x15 - dz*x16 + jointsX*(x1*x14*x3 - x14*x6) + jointsY*x17 - workY + x12*x16;
  z = dy*x14 - dy*x20 + dz*x18 - dz*x19 + jointsX*(-x1*x13*x3 + x13*x6) + jointsY*x20 - workZ + x12*x19;
  a = jointsA - workA;
  b = jointsB - workB;
  return { x,y,z,a,b };
}
export function tcpc2joints(tcpcX,tcpcY,tcpcZ,tcpcA,tcpcB,workX,workY,workZ,workA,workB,dy,dz,toolZ) {
  let x0,x1,x2,x3,x4,x5,x6,x7,x8,x9,x10,x11,x12,x13,x14,x15,x16,x17,x18,x19,x20,x21,x22,x,y,z,a,b;
  x0 = Math.sin(workB);
  x1 = tcpcB + workB;
  x2 = Math.sin(x1);
  x3 = Math.cos(workB);
  x4 = Math.cos(x1);
  x5 = x0*x2 + x3*x4;
  x6 = Math.cos(workA);
  x7 = x0*x4;
  x8 = x2*x3 - x7;
  x9 = x6*x8;
  x10 = Math.sin(workA);
  x11 = x10*x8;
  x12 = tcpcA + workA;
  x13 = Math.sin(x12);
  x14 = Math.cos(x12);
  x15 = -x0*x13*x4 + x13*x2*x3;
  x16 = -x0*x13*x2 - x13*x3*x4;
  x17 = x10*x14 + x16*x6;
  x18 = -x10*x16 + x14*x6;
  x19 = -x14*x2*x3 + x14*x7;
  x20 = x0*x14*x2 + x14*x3*x4;
  x21 = x10*x13 + x20*x6;
  x22 = -x10*x20 + x13*x6;
  x = tcpcX*x5 - tcpcY*x11 + tcpcZ*x9 + workX*x5 - workY*x11 + workZ*x9;
  y = -dy*x14 + dy + dz*x13 + tcpcX*x15 + tcpcY*x18 + tcpcZ*x17 + workX*x15 + workY*x18 + workZ*x17;
  z = -dy*x13 - dz*x14 + dz + tcpcX*x19 + tcpcY*x22 + tcpcZ*x21 + toolZ + workX*x19 + workY*x22 + workZ*x21;
  a = x12;
  b = x1;
  return { x,y,z,a,b };
}
export function joints2rwo(jointsX,jointsY,jointsZ,jointsA,jointsB,workX,workY,workZ,workA,workB,dy,dz,toolZ) {
  let x0,x1,x2,x3,x4,x5,x6,x7,x8,x9,x10,x11,x12,x13,x14,x,y,z,a,b;
  x0 = Math.sin(jointsB);
  x1 = Math.sin(workB);
  x2 = x0*x1;
  x3 = Math.cos(jointsB);
  x4 = Math.cos(workB);
  x5 = x3*x4;
  x6 = Math.sin(workA);
  x7 = x0*x4;
  x8 = x1*x3;
  x9 = x7 - x8;
  x10 = Math.cos(workA);
  x11 = Math.sin(jointsA);
  x12 = Math.cos(jointsA);
  x13 = -x11*x2 - x11*x5;
  x14 = x12*x2 + x12*x5;
  x = jointsX - workX*(x2 + x5) + workY*x6*x9 - workZ*x10*x9;
  y = dy*x12 - dy - dz*x11 + jointsY - workX*(x11*x7 - x11*x8) - workY*(x10*x12 - x13*x6) - workZ*(x10*x13 + x12*x6);
  z = dy*x11 + dz*x12 - dz + jointsZ - toolZ - workX*(x1*x12*x3 - x12*x7) - workY*(x10*x11 - x14*x6) - workZ*(x10*x14 + x11*x6);
  a = jointsA - workA;
  b = jointsB - workB;
  return { x,y,z,a,b };
}
export function rwo2joints(rwoX,rwoY,rwoZ,rwoA,rwoB,workX,workY,workZ,workA,workB,dy,dz,toolZ) {
  let x0,x1,x2,x3,x4,x5,x6,x7,x8,x9,x10,x11,x12,x13,x,y,z,a,b;
  x0 = Math.sin(workB);
  x1 = rwoB + workB;
  x2 = Math.sin(x1);
  x3 = Math.cos(workB);
  x4 = Math.cos(x1);
  x5 = Math.cos(workA);
  x6 = x0*x4;
  x7 = x2*x3 - x6;
  x8 = Math.sin(workA);
  x9 = rwoA + workA;
  x10 = Math.sin(x9);
  x11 = Math.cos(x9);
  x12 = -x0*x10*x2 - x10*x3*x4;
  x13 = x0*x11*x2 + x11*x3*x4;
  x = rwoX + workX*(x0*x2 + x3*x4) - workY*x7*x8 + workZ*x5*x7;
  y = -dy*x11 + dy + dz*x10 + rwoY + workX*(-x0*x10*x4 + x10*x2*x3) + workY*(x11*x5 - x12*x8) + workZ*(x11*x8 + x12*x5);
  z = -dy*x10 - dz*x11 + dz + rwoZ + toolZ + workX*(-x11*x2*x3 + x11*x6) + workY*(x10*x5 - x13*x8) + workZ*(x10*x8 + x13*x5);
  a = x9;
  b = x1;
  return { x,y,z,a,b };
}
export function rwo2work(rwoX,rwoY,rwoZ,rwoA,rwoB,workX,workY,workZ,workA,workB,dy,dz,toolZ) {
  let x0,x1,x2,x3,x4,x5,x6,x7,x8,x9,x10,x11,x12,x13,x14,x15,x16,x17,x18,x19,x20,x21,x22,x23,x24,x25,x26,x27,x28,x,y,z,a,b;
  x0 = Math.sin(workB);
  x1 = rwoB + workB;
  x2 = Math.cos(x1);
  x3 = x0*x2;
  x4 = Math.cos(workB);
  x5 = Math.sin(x1);
  x6 = x3 - x4*x5;
  x7 = dz*x6;
  x8 = rwoA + workA;
  x9 = Math.sin(x8);
  x10 = dy*x9;
  x11 = Math.cos(x8);
  x12 = x0*x5 + x2*x4;
  x13 = Math.cos(workA);
  x14 = -x6;
  x15 = Math.sin(workA);
  x16 = rwoX + workX*x12 - workY*x14*x15 + workZ*x13*x14;
  x17 = x15*x9;
  x18 = x0*x11*x5 + x11*x2*x4;
  x19 = -dz*x11 + dz + rwoZ + workX*(x11*x3 - x11*x4*x5) + workY*(x13*x9 - x15*x18) + workZ*(x13*x18 + x17) - x10;
  x20 = -x0*x5*x9 - x2*x4*x9;
  x21 = -x11*x13;
  x22 = -dy*x11 + dy + dz*x9 + rwoY + workX*(-x0*x2*x9 + x4*x5*x9) + workY*(-x15*x20 - x21) + workZ*(x11*x15 + x13*x20);
  x23 = -x0*x15*x5 - x15*x2*x4;
  x24 = x11*x23 + x13*x9;
  x25 = -x21 - x23*x9;
  x26 = x0*x13*x5 + x13*x2*x4;
  x27 = x11*x26 + x17;
  x28 = x11*x15 - x26*x9;
  x = x10*x6 + x11*x19*x6 - x11*x7 + x12*x16 - x22*x6*x9 + x7;
  y = dy*x13 - dy*x25 + dz*x23 - dz*x24 + x16*(x15*x3 - x15*x4*x5) + x19*x24 + x22*x25;
  z = dy*x15 - dy*x28 + dz*x26 - dz*x27 + x16*(-x0*x13*x2 + x13*x4*x5) + x19*x27 + x22*x28;
  a = workA;
  b = workB;
  return { x,y,z,a,b };
}
export function jointsDir2workDir(jointsDirX,jointsDirY,jointsDirZ,jointsA,jointsB,workA,workB) {
  let x0,x1,x2,x3,x4,x5,x6,x7,x8,x9,x10,x,y,z;
  x0 = jointsB - workB;
  x1 = Math.cos(x0);
  x2 = Math.sin(jointsA);
  x3 = Math.sin(x0);
  x4 = Math.cos(jointsA);
  x5 = Math.sin(workA);
  x6 = jointsDirX*x3;
  x7 = Math.cos(workA);
  x8 = x4*x7;
  x9 = x2*x5;
  x10 = x2*x7;
  x = jointsDirX*x1 + jointsDirY*x2*x3 - jointsDirZ*x3*x4;
  y = jointsDirY*(x1*x9 + x8) + jointsDirZ*(-x1*x4*x5 + x10) - x5*x6;
  z = jointsDirY*(-x1*x10 + x4*x5) + jointsDirZ*(x1*x8 + x9) + x6*x7;
  return { x,y,z };
}
export function workDir2jointsDir(workDirX,workDirY,workDirZ,workA,workB,jointsA,jointsB) {
  let x0,x1,x2,x3,x4,x5,x6,x7,x8,x9,x10,x,y,z;
  x0 = jointsB - workB;
  x1 = Math.cos(x0);
  x2 = Math.cos(workA);
  x3 = Math.sin(x0);
  x4 = Math.sin(workA);
  x5 = Math.sin(jointsA);
  x6 = workDirX*x3;
  x7 = Math.cos(jointsA);
  x8 = x2*x7;
  x9 = x4*x5;
  x10 = x2*x5;
  x = workDirX*x1 - workDirY*x3*x4 + workDirZ*x2*x3;
  y = workDirY*(x1*x9 + x8) + workDirZ*(-x1*x10 + x4*x7) + x5*x6;
  z = workDirY*(-x1*x4*x7 + x10) + workDirZ*(x1*x8 + x9) - x6*x7;
  return { x,y,z };
}
export function joints2work(jointsX,jointsY,jointsZ,jointsA,jointsB,workA,workB,dy,dz) {
  let x0,x1,x2,x3,x4,x5,x6,x7,x8,x9,x10,x11,x12,x13,x14,x15,x,y,z;
  x0 = jointsB - workB;
  x1 = Math.sin(x0);
  x2 = Math.cos(x0);
  x3 = Math.sin(jointsA);
  x4 = Math.cos(jointsA);
  x5 = Math.cos(workA);
  x6 = Math.sin(workA);
  x7 = dz*x2;
  x8 = jointsX*x1;
  x9 = x4*x5;
  x10 = x3*x6;
  x11 = x10*x2 + x9;
  x12 = x3*x5;
  x13 = x12 - x2*x4*x6;
  x14 = x10 + x2*x9;
  x15 = -x12*x2 + x4*x6;
  x = -dy*x1*x3 + dz*x1*x4 - dz*x1 + jointsX*x2 + jointsY*x1*x3 - jointsZ*x1*x4;
  y = -dy*x11 + dy*x5 - dz*x13 + jointsY*x11 + jointsZ*x13 - x6*x7 - x6*x8;
  z = -dy*x15 + dy*x6 - dz*x14 + jointsY*x15 + jointsZ*x14 + x5*x7 + x5*x8;
  return { x,y,z };
}
export function work2joints(workX,workY,workZ,workA,workB,jointsA,jointsB,dy,dz) {
  let x0,x1,x2,x3,x4,x5,x6,x7,x8,x9,x10,x,y,z;
  x0 = jointsB - workB;
  x1 = Math.cos(x0);
  x2 = Math.cos(workA);
  x3 = Math.sin(x0);
  x4 = Math.sin(workA);
  x5 = Math.sin(jointsA);
  x6 = Math.cos(jointsA);
  x7 = workX*x3;
  x8 = x2*x6;
  x9 = x4*x5;
  x10 = x2*x5;
  x = workX*x1 - workY*x3*x4 + workZ*x2*x3;
  y = -dy*x6 + dy + dz*x5 + workY*(x1*x9 + x8) + workZ*(-x1*x10 + x4*x6) + x5*x7;
  z = -dy*x5 - dz*x6 + dz + workY*(-x1*x4*x6 + x10) + workZ*(x1*x8 + x9) - x6*x7;
  return { x,y,z };
}
