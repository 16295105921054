import { connect } from 'react-redux';
import actions from '../actions';
import { GLTFLoader, OBJLoader, STLLoader } from 'three-stdlib';
import { useInterpreterModel } from '../components/viewer3d/InterpreterModel';

import { Group, Mesh, MeshStandardMaterial } from 'three';

import ModelLoader from '../components/ModelLoader';

export default connect(
  (state, ownProps) => ({ 
  }),
  (dispatch, ownProps) => ({
    onLoadModel: (model, filename) => {
      if(filename.toLowerCase().endsWith(".obj")) {
        const decoder = new TextDecoder("utf-8");
        const objLoader = new OBJLoader();

        const obj = objLoader.parse(decoder.decode(model));
        useInterpreterModel.getState().setModel(obj);
      } else if(filename.toLowerCase().endsWith(".stl")) {
        const stlLoader = new STLLoader();

        const geometry = stlLoader.parse(model);
        const mesh = new Mesh(geometry, new MeshStandardMaterial());
        const obj = new Group();
        obj.add(mesh);
        useInterpreterModel.getState().setModel(obj);
      } else if(filename.toLowerCase().endsWith(".glb")) {
        const gltfLoader = new GLTFLoader();

        gltfLoader.parse(model, "", (obj) => {
          const nestedObj = new Group();
          nestedObj.add(obj.scene);
          useInterpreterModel.getState().setModel(nestedObj);
        });
      }
      dispatch(actions.viewer3d.loadModel())
      dispatch(actions.viewer3d.showModel())
    }
  })
)(ModelLoader);
