import { connect } from 'react-redux';

import MachineMenu from '../components/MachineMenu';
import actions from '../actions';

export default connect(
  (state, ownProps) => ({
    machine: state.machineState.machine,
    running: state.interpreter.running,
  }),
  (dispatch, ownProps) => ({
    onChangeMachine: (machine) => {
      dispatch(actions.machineState.machine.set(machine));
    },
  })
)(MachineMenu);
