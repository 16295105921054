import React, { useMemo, useRef, forwardRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { mergeRefs } from 'react-merge-refs';
import * as THREE from 'three';

export const vertexShader = `
    varying vec3 vNormal;
    varying vec2 vUV;
    void main() {
      vNormal = normal;
      vUV = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }
  `;
export const fragmentShader = `
uniform sampler2D myTexture;
uniform vec3 hitNormal;
uniform float opacity;

varying vec3 vNormal;
varying vec2 vUV;
void main() {
  vec3 multColor = vec3(1,1,1);
  if(dot(vNormal, hitNormal) > .99) {
    multColor = vec3(.02,1, .8);
  }
  gl_FragColor = vec4(texture2D(myTexture, vUV).xyz*multColor, opacity);
}
`;


export default forwardRef( function Model({ 
    hitNormal,
    opacity,
    ...props 
  }, ref) {

  const group = useRef()
  const { nodes, materials } = useGLTF('/gltf/viewCube.glb')

  const uniforms = useMemo(() => {
    return {
      hitNormal: { value: new THREE.Vector3() },
      myTexture: { type: "t" },
      opacity: { value: .25 }

    };
  }, []);

  return (
    <mesh ref={mergeRefs([group, ref])}
          geometry={nodes.viewCube.geometry} 
          dispose={null}
          {...props}>
      <shaderMaterial attach="material"
        uniforms={uniforms}
        uniforms-myTexture-value={materials.viewCubeMaterial.emissiveMap}
        uniforms-hitNormal-value={hitNormal}
        uniforms-opacity-value={opacity}
        vertexShader={vertexShader}
        fragmentShader={fragmentShader}
        transparent={opacity < 1}/>
    </mesh>
  );
})

useGLTF.preload('/gltf/viewCube.glb')
