export const FIRST_G5X_ENCOUNTERED = "firstG5x";
export const USER_CHOSEN_G5X = "userG5x";
export const CENTER_OF_ROTATION = "cor";

const constants = {
  FIRST_G5X_ENCOUNTERED,
  USER_CHOSEN_G5X,
  CENTER_OF_ROTATION
};

export default constants;
