import { take, call, put } from 'redux-saga/effects';
import { buffers, eventChannel } from 'redux-saga';
import jszip from 'jszip';
import { interpretFileData } from './interpreter';
import actions from '../actions';
import { useInterpreterModel } from '../components/viewer3d/InterpreterModel';
import { GLTFLoader, OBJLoader, STLLoader } from 'three-stdlib';
import { Group, Mesh, MeshStandardMaterial } from 'three';

export class SimulationZipProcessingQueue {
  constructor() {
    this.channel = eventChannel( (emit) => {
      // public method for adding a file to the queue
      this.addSimulationZip = (arraybuffer) => {
        emit(arraybuffer);
      };

      return () => {};
    }, buffers.sliding(1));
  }
}

export const processingQueue = new SimulationZipProcessingQueue();

function *processSimulationZip(buffer) {
  const zip = yield call(jszip.loadAsync, buffer);

  const dataZipObj = yield call([zip, zip.file], "data.json");
  if(dataZipObj) {
    const dataStr = yield call([dataZipObj, dataZipObj.async ], "string");
    const data = JSON.parse(dataStr);

    if(data.program) {
      if(data.machine) {
        yield put(actions.machineState.machine.set(data.machine));
      }

      if(data.tools) {
        for(let tool of data.tools) {
          yield put(actions.machineState.tool.table.setTool(tool));
        }
      }

      if(data.wcs) {
        for(let wcsObj of data.wcs) {
          const { wcs, X, Y, Z, A, B, C } = wcsObj;

          const xParsed = parseFloat(X);
          const yParsed = parseFloat(Y);
          const zParsed = parseFloat(Z);
          const aParsed = parseFloat(A);
          const bParsed = parseFloat(B);
          const cParsed = parseFloat(C);

          const xValue = isNaN(xParsed) ? 0 : xParsed;
          const yValue = isNaN(yParsed) ? 0 : yParsed;
          const zValue = isNaN(zParsed) ? 0 : zParsed;
          const aValue = isNaN(aParsed) ? 0 : aParsed;
          const bValue = isNaN(bParsed) ? 0 : bParsed;
          const cValue = isNaN(cParsed) ? 0 : cParsed;

          yield put(actions.machineState.motion.g5x.setG5xWorkOffset({ index: wcs, axis: "X", value: xValue }));
          yield put(actions.machineState.motion.g5x.setG5xWorkOffset({ index: wcs, axis: "Y", value: yValue }));
          yield put(actions.machineState.motion.g5x.setG5xWorkOffset({ index: wcs, axis: "Z", value: zValue }));
          yield put(actions.machineState.motion.g5x.setG5xWorkOffset({ index: wcs, axis: "A", value: aValue }));
          yield put(actions.machineState.motion.g5x.setG5xWorkOffset({ index: wcs, axis: "B", value: bValue }));
          yield put(actions.machineState.motion.g5x.setG5xWorkOffset({ index: wcs, axis: "C", value: cValue }));
        }
      }

      // load G code file
      const gcodeZipObj = yield call([zip, zip.file], data.program);
      if(gcodeZipObj) {
        const gcodeData = yield call([gcodeZipObj, gcodeZipObj.async], "string");
        yield put(actions.interpreter.setFileName(data.program));
        yield call(interpretFileData, gcodeData);
      } else {
        console.error("Could not find specified G code file in zip:", data.gcode);
        return;
      }
    }

    if(data.models) {
      // load models
      // TODO, support more than 1 model
      const filename = data.models[0];
      const modelZipObj = yield call([zip, zip.file], filename);
      const model = yield call([modelZipObj, modelZipObj.async], "arraybuffer");

      if(filename.toLowerCase().endsWith(".obj")) {
        const decoder = new TextDecoder("utf-8");
        const objLoader = new OBJLoader();

        const obj = objLoader.parse(decoder.decode(model));
        useInterpreterModel.getState().setModel(obj);
      } else if(filename.toLowerCase().endsWith(".stl")) {
        const stlLoader = new STLLoader();

        const geometry = stlLoader.parse(model);
        const mesh = new Mesh(geometry, new MeshStandardMaterial());
        const obj = new Group();
        obj.add(mesh);
        useInterpreterModel.getState().setModel(obj);
      } else if(filename.toLowerCase().endsWith(".glb")) {
        const gltfLoader = new GLTFLoader();

        gltfLoader.parse(model, "", (obj) => {
          const nestedObj = new Group();
          nestedObj.add(obj.scene);
          useInterpreterModel.getState().setModel(nestedObj);
        });
      }
      yield put(actions.viewer3d.loadModel())
      yield put(actions.viewer3d.showModel())
    }
  } else {
    console.error("No data.json file in zip.");
  }
}

function *saga() {
  const channel = processingQueue.channel;

  while(true) {
    const arraybuffer = yield take(channel);

    yield call(processSimulationZip, arraybuffer);
  }
}

export default saga;
