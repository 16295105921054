import { connect } from 'react-redux';
import actions from '../actions/ui';

import Divider from '../components/Divider';

export default connect(
  (state, ownProps) => ({
    left: state.ui.gcodePane.width
  }),
  (dispatch, ownProps) => ({
    onDrag: (w) => dispatch(actions.ui.gcodePane.setWidth(w))
  })
)(Divider);
