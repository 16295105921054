import gcodePane from './gcode-pane';
import controlsOverlay from './controls-overlay';
import titleBar from './title-bar';
import demo from './demo';
import options from './options';

const actions = {
  ui: {
    ...gcodePane.ui,
    ...controlsOverlay.ui,
    ...titleBar.ui,
    ...demo.ui,
    ...options.ui
  }
};

export default actions;
