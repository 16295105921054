import GCodeInterpreter from './interpreter';
import machines from '../machines';
import { calculatePosition } from '../util/motion-math';
import BackPlot from '../viewer3d/backplot';
import { FIVE_AXIS } from '../constants/machine-state/kinematics-mode';
import { INVERSE_TIME, UNITS_PER_MINUTE, UNITS_PER_REVOLUTION } from '../constants/machine-state/feed-rate-mode';

function isPercentLine(line) {
  return line === "%";
}

function isCommentLine(line) {
  const withoutN = line.replace(/^N\d+\s*/, "");
  return (withoutN.startsWith("(") && withoutN.endsWith(")")) || withoutN.startsWith(";");
}

// Object that maintains a cache of machineStates and
// times suitable to be used to animate through or jump
// to any specific time in a program.

// Leverages web workers to perform work behind the scenes
// and generates events, so event listeners can be attached
// to monitor progress.
export class InterpreterCache {
  static instance;

  static getInstance() {
    if(!this.instance) {
      this.instance = new InterpreterCache();
    }

    return this.instance;
  }

  constructor() {
    this.lines = [];
    this.times = [];
    this.states = [];
    this.toolPaths = []; // list of line numbers that represent the beginning and end of tool paths
    this.messages = [];
    this.editors = null;
    this.events = {
    };
    this.worker = null;
  }

  registerEditor(editor) {
    if(this.editor) {
      throw new Error("Editor already registered! Unregister with InterpreterCache.unregister(editor).");
    } else {
      this.editor = editor;
    }
  }

  unregisterEditor() {
    this.editor = null;
  }

  getPreviousToolPathRange(currentLine) {
    const current = this.getCurrentToolPathRange(currentLine);

    if(currentLine <= current.firstLine) {
      return this.getCurrentToolPathRange(Math.max(current.min-1, 0));
    }
    return current;
  }

  getCurrentToolPathRange(currentLine) {
    const range = {
      min: 0,
      max: this.lines.length-1
    };
    if(this.toolPaths.length >= 2) {
      let index0 = 0;
      let index1 = this.toolPaths.length;
      while(index0 < index1) {
        const mid = Math.floor((index0+index1)/2);
        if(this.toolPaths[mid] <= currentLine) {
          index0 = mid+1;
        } else {
          index1 = mid;
        }
      }

      const minIndex = Math.min(this.toolPaths.length-1, Math.max(index0-1, 0));

      range.min = this.toolPaths[minIndex]; 
      range.max = (minIndex === this.toolPaths.length-1) ? this.lines.length-1 : this.toolPaths[minIndex+1]-1;
    }

    let i = range.min;
    while(i <= range.max && (isPercentLine(this.lines[i]) || isCommentLine(this.lines[i]))) {
      i++;
    }

    range.firstLine = i;

    return range;
  }

  getNextToolPathRange(currentLine) {
    const current = this.getCurrentToolPathRange(currentLine);

    return this.getCurrentToolPathRange(current.max+1);
  }

  timeAtLine(line) {
    return this.times[Math.max(0, Math.min(line, this.times.length-1))];
  }

  lineAtTime(currentTime, min=null, max=null) {
    const times = this.times;

    let currentLine = 0;
    if(currentTime >= times[times.length-1]) {
      currentLine = times.length-1;
      currentTime = times[currentLine];
    } else if(currentTime === 0) {
      currentLine = 0;
    } else {
      if(min === null) {
        min = 0;
      }
      if(max === null) {
        max = times.length-1;
      }
      let mid = Math.floor((min+max)*.5);
      while( min+1 < max ) {
        if(times[mid] >= currentTime) {
          max = mid;
        } else {
          min = mid;
        }
        mid = Math.floor((min+max)*.5);
      }
      if(currentTime < times[min]) {
        currentLine = min;
      } else {
        currentLine = max;
      }
    }

    return currentLine;
  }

  machineStateAtLineAndTime(currentLine, currentTime) {
    const prevLine = currentLine-1;
    const linesPerIndex = this.linesPerIndex;
    const totalStates = this.states.length;

    if(totalStates > 0) {
      const index = Math.min(Math.floor(prevLine/linesPerIndex), totalStates-1);
      const closestPreviousState = index < 0 ? 
        this.initialState :
        this.states[index];
      const line = Math.max(index*linesPerIndex, -1);

      let prevState = closestPreviousState;

      const lines = this.lines;
      let interpreter = null;
      let currentState = null;
      const currentIndex = Math.min(Math.floor(currentLine/linesPerIndex), totalStates-1);
      if(prevLine > line || currentIndex*linesPerIndex !== currentLine) {
        interpreter = new GCodeInterpreter({ machineState: prevState });

        let l = line;
        while(l <= prevLine) {
          prevState = interpreter.interpret(lines[l]);
          l++;
        }
      }

      if(currentIndex*linesPerIndex === currentLine) {

        // if the current line is in our list of indexed states, use it
        currentState = this.states[currentIndex];
      } else {
        // otherwise, interpret from the previous state
        currentState = interpreter.interpret(lines[currentLine]);
      }

      const currentMachine = machines[currentState.machine];

// instead of getting the previous position like so:
//      const prevPos = prevState.motion.position;
// we must calculate forward kinematics using the current state in case there
// were any state changes in the current line that would result in a change to the work piece
// space such as a G43, G49, G60, etc.
      const prevJoints = prevState.joints;

      const prevPos = currentMachine.kinematics.forwardKinematics({ 
        ...currentState,
        joints: prevJoints
      });
      const currentPos = currentState.motion.position;

      const times = this.times;
      const prevTime = prevLine < 0 ? 0 : times[prevLine];
      const nextTime = times[currentLine];

      let t = 1;
      if(nextTime-prevTime > 0) {
        t = (currentTime-prevTime)/(nextTime-prevTime);
      }

      const motionMode = currentState.motion.mode;
      const turns = currentState.motion.turns;
      const planeSelect = currentState.planeSelect;
      const pos = calculatePosition(motionMode, prevPos, currentPos, t, planeSelect, turns, currentState.motion.issuedMotionCommand);

      let nextState = { ...currentState, motion: { ...currentState.motion, position: pos }};
      const joints = currentMachine.kinematics.inverseKinematics(nextState);

      nextState.joints = joints;

      return nextState;
    }

    return this.initialState;
  }

  addEventListener(name, callback) {
    if(!this.events[name]) {
      this.events[name] = [];
    }
    this.events[name].push(callback);
  }

  removeEventListener(name, callback) {
    if(this.events[name]) {
      const index = this.events[name].indexOf(callback);
      this.events[name].splice(index, 1);
    }
  }

  emit(name, ...args) {
    if(this.events[name]) {
      this.events[name].forEach((callback) => {
        callback(...args);
      });
    }
  }

  clear() {
    this.lines = [];
    this.times = [];
    this.states = [];
    this.messages = [];
    this.toolPaths = [];
  }

  // fileData - program to interpret, can be:
  //       1) a File object
  //       2) an object with a .url parameter from a URL we have permission to fetch
  //       3) a string
  // machineState - the starting machineState when we start interpretting
  interpret(file, machineState) {
    this.terminate();
    this.clear();
    this.emit("begin", machineState);

    // automatically disable TCPC initially
    const initialStateInterpreter = new GCodeInterpreter({ machineState });
    this.initialState = initialStateInterpreter.interpret("M429");

    this.worker = new Worker(new URL('../workers/interpreter.js', import.meta.url));
    this.worker.onmessage = (msg) => {
      this.emit("update", msg.data);

      const {
        progress,
        lines,
        states,
        times,
        toolPaths,
        messages,
        linesPerIndex
      } = msg.data;

      this.linesPerIndex = linesPerIndex;

      for(let i = 0; i < lines.length; i++) {
        this.lines.push(lines[i]);
      }

      for(let i = 0; i < states.length; i++) {
        this.states.push(states[i]);
      }

      for(let i = 0; i < times.length; i++) {
        this.times.push(times[i]);
      }

      for(let i = 0; i < messages.length; i++) {
        this.messages.push(messages[i]);
      }

      for(let i = 0; i < toolPaths.length; i++) {
        this.toolPaths.push(toolPaths[i]);
      }

      if(progress === 100) {
        this.emit("end", this.lines.length);
      }
    };

    this.worker.postMessage({
      file,
      machineState
    });
  }

  interpretEditorContents(machineState) {
    if(!this.editor) {
      throw new Error("No editor registered.");
    }

    const session = this.editor.getSession();
    let blankLines = 1;
    while(!session.getLine(session.getLength()-blankLines)) {
      blankLines++;
    }

    const lines = session.getLines(0, session.getLength()-blankLines);
    this.interpret(lines, machineState);
  }

  interpretEditorContentsReplacingSelectionWithTCPC(machineState) {
    if(!this.editor) {
      throw new Error("No editor registered.");
    }

    const session = this.editor.getSession();
    let blankLines = 1;
    while(!session.getLine(session.getLength()-blankLines)) {
      blankLines++;
    }

    const range = session.getSelection().getRange();
    const start = range.start.row;
    const end = range.end.row;

    if(start !== end) {
      const backplot = BackPlot.getInstance();

      let startIndex = -1;
      let endIndex = -1;
      for(let i = 0; i < backplot.lineObject.lines.length && backplot.lineObject.lines[i] !== 0 && backplot.lineObject.lines[i] <= end; i++) {
        if(startIndex === -1 && backplot.lineObject.lines[i] >= start) {
          startIndex = i;
        }
        endIndex = i;
      }

      const lines = session.getLines(0, session.getLength()-blankLines);
      const endLines = lines.splice(end+1);
      lines.splice(start);

      const prevLine = Math.max(start-1,0);
      let prevTime = this.timeAtLine(prevLine);
      const endLine = end;
      const endTime = this.timeAtLine(endLine);
      const endMachineState = this.machineStateAtLineAndTime(endLine, endTime);
      const prevMachineState = this.machineStateAtLineAndTime(prevLine, prevTime);
      const prevMult = prevMachineState.units === "MM" ? 25.4 : 1;

      let prevX = backplot.lineObject.vertices[3*(startIndex-1)]*prevMult;
      let prevY = backplot.lineObject.vertices[3*(startIndex-1)+1]*prevMult;
      let prevZ = backplot.lineObject.vertices[3*(startIndex-1)+2]*prevMult;
      let prevA = prevMachineState.joints[3];
      let prevB = prevMachineState.joints[4];

      if(prevMachineState.kinematicsMode !== FIVE_AXIS) {
        lines.push("M428");
      }

      if(prevMachineState.feedRateMode !== INVERSE_TIME) {
        lines.push("G93");
      }

      for(let i = startIndex; i <= endIndex; i++) {
        const mode = backplot.lineObject.colorIndices[i];
        const time = backplot.lineObject.times[i];
        const dt = time-prevTime;
        const line = backplot.lineObject.lines[i];
        const machineState = this.machineStateAtLineAndTime(line, time);
        const units = machineState.units;
        const mult = units === "MM" ? 25.4 : 1;

        const x = backplot.lineObject.vertices[3*i]*mult;
        const y = backplot.lineObject.vertices[3*i+1]*mult;
        const z = backplot.lineObject.vertices[3*i+2]*mult;
        const a = machineState.joints[3];
        const b = machineState.joints[4];

        if(dt > 0) {
          let line = "";
          if(Math.abs(prevX-x) > .00001) {
            line += ` X${parseFloat(x.toFixed(5))}`;
          }
          if(Math.abs(prevY-y) > .00001) {
            line += ` Y${parseFloat(y.toFixed(5))}`;
          }
          if(Math.abs(prevZ-z) > .00001) {
            line += ` Z${parseFloat(z.toFixed(5))}`;
          }
          if(Math.abs(prevA-a) > .00001) {
            line += ` A${parseFloat(a.toFixed(5))}`;
          }
          if(Math.abs(prevB-b) > .00001) {
            line += ` B${parseFloat(b.toFixed(5))}`;
          }
          if(mode === 1) {
            line += ` F${parseFloat((60/dt).toFixed(5))}`;
          }
          if(line.length > 0) {
            line = `G${mode}` + line;
            lines.push(line);
          }
        }
        prevTime = time;

        prevX = x;
        prevY = y;
        prevZ = z;
        prevA = a;
        prevB = b;
      }

      if(endMachineState.kinematicsMode !== FIVE_AXIS) {
        lines.push("M429");
      }
      if(endMachineState.feedRateMode === UNITS_PER_MINUTE) {
        lines.push("G94");
      } else if(endMachineState.feedRateMode === UNITS_PER_REVOLUTION) {
        lines.push("G95");
      }

      for(let i = 0; i < endLines.length; i++) {
        lines.push(endLines[i]);
      }

      this.interpret(lines, machineState);
    } else {
      const lines = session.getLines(0, session.getLength()-blankLines);
      this.interpret(lines, machineState);
    }
  }

  terminate() {
    if(this.worker) {
      this.emit("terminated");
      this.worker.terminate();
      this.worker = null;
    }
  }

  machineStateFromTime(time) {
  }
};
