import machineState from './machine-state';
import interpreter from './interpreter';
import ui from './ui';
import iframe from './iframe';
import viewer3d from './viewer3d';
import rfid from './rfid';

const actions = {
  ...machineState,
  ...interpreter,
  ...ui,
  ...iframe,
  ...viewer3d,
  ...rfid
}

export default actions;
