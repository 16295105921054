import actions from '../../actions/ui';
import { handleActions } from 'redux-actions';
import produce from 'immer';

const reducer = handleActions(
  {
    [actions.ui.gcodePane.setWidth]: produce((state, action) => { state.width = action.payload }),
    [actions.ui.gcodePane.setSelected]: produce((state, action) => { state.selectedLine = action.payload }),
    [actions.ui.gcodePane.setLineOffset]: produce((state, action) => { state.lineOffset = action.payload }),
    [actions.ui.gcodePane.setShowLines]: produce((state, action) => { state.showLines = action.payload }),
    [actions.ui.gcodePane.scroll]: produce((state, action) => { state.lineOffset += action.payload }),
    [actions.ui.gcodePane.setTab]: produce((state, action) => { state.currentTab = action.payload }),

    [actions.ui.gcodePane.togglePane]: produce((state, action) => { state.visible = !state.visible }),
    [actions.ui.gcodePane.show]: produce((state, action) => { state.visible = true }),
    [actions.ui.gcodePane.hide]: produce((state, action) => { state.visible = false })
  },
  {
    width: 320,
    visible: true,
    lineOffset: 0,
    showLines: 50,
    selectedLine: 0,
    currentTab: 0
  }
);

export default reducer;
