import React, { useMemo, useEffect, useRef } from 'react'
import { useThree } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei'
import { useCameraControls, X, Y, selectSetUpAndForward, selectAddParent, selectRemoveParent } from './CameraControls';
import { checkLimits } from '../../machines';
import { V2 } from '../../constants/machine-state/machine';

export default function Model({ 
  joints = [ 0, 0, 0, 0, 0 ],
  tool = null,
  toolHolder = "NONE",
  spindleAngle = 0,
  model = null,
  modelOffsets = { X: 0, Y: 0, Z:0, A: 0, B:0, C: 0 },
  children,
  ...props 
}) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/gltf/v2-10.glb')
  const { scene } = useThree();

  const baseRef = useRef();
  const spindleRef = useRef();
  const tableRef = useRef();
  const trunnionRef = useRef();
  const partRef = useRef();

  const addParent = useCameraControls(selectAddParent);
  const removeParent = useCameraControls(selectRemoveParent);
  const setUpAndForward = useCameraControls(selectSetUpAndForward);

  const jointColors = useMemo(() => joints.map((joint, jointIndex) => checkLimits(V2, jointIndex, joint) ? 0x000000 : 0xaa0000 ), [ joints ]);

  const holder = nodes[toolHolder] ? <primitive object={nodes[toolHolder]} rotation-z={spindleAngle}/> : null;

  useEffect(() => {
    const intensity = 3;
    const intensity2 = 1.25;
    materials.BaseMaterial.envMap = scene.environment;
    materials.BaseMaterial.envMapIntensity = intensity;

    materials.ColletMaterial.envMap = scene.environment;
    materials.ColletMaterial.envMapIntensity = intensity;

    materials['ColletMaterial.001'].envMap = scene.environment;
    materials['ColletMaterial.001'].envMapIntensity = intensity;

    materials.BackingPlateMaterial.envMap = scene.environment;
    materials.BackingPlateMaterial.envMapIntensity = intensity;

    materials.TrunnionMaterial.envMap = scene.environment;
    materials.TrunnionMaterial.envMapIntensity = intensity;

    materials.TableMaterial.envMap = scene.environment;
    materials.TableMaterial.envMapIntensity = intensity;

    materials.SpindleMaterial.envMap = scene.environment;
    materials.SpindleMaterial.envMapIntensity = intensity;

    materials.XMaterial.envMap = scene.environment;
    materials.XMaterial.envMapIntensity = intensity;

    materials.ButtonsMaterial.envMap = scene.environment;
    materials.ButtonsMaterial.envMapIntensity = intensity2;

    materials.RailsMaterial.envMap = scene.environment;
    materials.RailsMaterial.envMapIntensity = intensity2;

    const base = { name: "Base", object: baseRef.current };
    const spindle = { name: "Spindle", object: spindleRef.current };
    const table = { name: "Table", object: tableRef.current };
    const part = { name: "Part", object: partRef.current };
    const trunnion = { name: "Trunnion", object: trunnionRef.current };

    addParent(base);
    addParent(spindle);
    addParent(table);
    addParent(trunnion);
    addParent(part);

    setUpAndForward(Y, X);

    return () => {
      removeParent(base);
      removeParent(spindle);
      removeParent(table);
      removeParent(trunnion);
      removeParent(part);
    };
  }, [ addParent, materials, removeParent, setUpAndForward, scene]);

  return (
    <group ref={group} {...props} dispose={null}>
      <group ref={baseRef}>
        <spotLight intensity={.15} angle={Math.PI / 8} penumbra={0.15} decay={2} position={[-30, 30, 30]} rotation={[-0.81, -0.58, -0.25]} />
        <spotLight intensity={.15} angle={Math.PI / 8} penumbra={0.15} decay={2} position={[-30, 30, -30]} rotation={[-0.81, -0.58, -0.25]} />
        <spotLight intensity={0.05} angle={Math.PI / 8} penumbra={0.15} decay={2} position={[30, 30, 30]} rotation={[-0.81, -0.58, -0.25]} />
        <spotLight intensity={0.05} angle={Math.PI / 8} penumbra={0.15} decay={2} position={[30, 30, -30]} rotation={[-0.81, -0.58, -0.25]} />
        <spotLight intensity={0.02} angle={Math.PI / 8} penumbra={0.15} decay={2} position={[30, -30, -30]} rotation={[-0.81, -0.58, -0.25]} />
        <mesh geometry={nodes.Base_1.geometry} material={materials.BaseMaterial} />
        <mesh geometry={nodes.Base_2.geometry} material={materials.RailsMaterial} />
        <mesh geometry={nodes.buttons.geometry} material={materials.ButtonsMaterial} />
        <mesh geometry={nodes.cycle_start_led.geometry} material={materials['None.002']} />
        <mesh geometry={nodes.estop_led.geometry} material={materials['None.001']} />
        <group position-x={joints[0]}>
          <mesh geometry={nodes.x_1.geometry} material={materials.XMaterial} material-emissive={jointColors[0]}/>
          <mesh geometry={nodes.x_2.geometry} material={materials.ZRailMaterial}/>
          <mesh geometry={nodes.z.geometry} material={materials.SpindleMaterial} ref={spindleRef} position-z={6.26+joints[2]} material-emissive={jointColors[2]}>
            { tool }
            { holder }
          </mesh>
        </group>
        <mesh geometry={nodes.y.geometry} material={materials.BackingPlateMaterial} position-y={-joints[1]} material-emissive={jointColors[1]}>
          <mesh geometry={nodes.a.geometry} material={materials.TrunnionMaterial} ref={trunnionRef} rotation-x={joints[3]*Math.PI/180} material-emissive={jointColors[3]}>
            <mesh geometry={nodes.b.geometry} material={materials.TableMaterial} ref={tableRef} rotation-y={joints[4]*Math.PI/180} material-emissive={jointColors[4]}>
              <group rotation-y={ -modelOffsets.B*Math.PI/180 }>
                <group rotation-x={ -modelOffsets.A*Math.PI/180 }>
                  <group position={[ modelOffsets.X, modelOffsets.Y, modelOffsets.Z ]}>
                    <group ref={partRef}></group>
                    {model}
                  </group>
                </group>
              </group>
              {children}
            </mesh>
          </mesh>
        </mesh>
      </group>
    </group>
  )
}
