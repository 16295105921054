import { useEffect } from 'react';
import { useThree } from '@react-three/fiber';
import { useCubeTexture } from '@react-three/drei';

export default function Environment(props) {
  const { scene } = useThree();

  const envMap = useCubeTexture(['px.jpg', 'nx.jpg', 'py.jpg', 'ny.jpg', 'pz.jpg', 'nz.jpg'], { path: '/textures/cube/' })

  useEffect(() => {
    scene.environment = envMap;
//    scene.background = envMap;
  }, [ envMap, scene ]);

  return null;
}
