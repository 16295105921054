import { createActions } from 'redux-actions';

export default createActions({
  INTERPRETER: {
    SET_FILE_NAME: undefined,
    START_PROCESSING: undefined,
    END_PROCESSING: undefined,
    SET_PROCESSING_PROGRESS: undefined,

    // don't trigger machine updates, only used to update interpreter state
    SET_CURRENT_LINE_AND_TIME_NO_UPDATES: undefined,
    //

    SET_APPROXIMATE_TIME: undefined,
    SET_NUM_LINES: undefined,
    SET_INITIAL_STATE: undefined,
    APPEND_MESSAGES: undefined,

// actions that trigger machineState updates 
// new ones must be added in reducers/machine-state/index.js
    INCREMENT_TIME: undefined,
    SET_CURRENT_LINE_WITH_PERCENTAGE: undefined,
    SET_CURRENT_LINE_WITH_PERCENTAGE_LINE_BOUNDED: undefined,
    SET_CURRENT_LINE_WITH_TIME: undefined,
    SET_CURRENT_LINE: undefined,
    NEXT_LINE: undefined,
    PREVIOUS_LINE: undefined,

    PAUSE: undefined,
    UNPAUSE: undefined,
    RUN: undefined,
    RUN_REPLACE_SELECTION_WITH_TCPC: undefined,
    STOP: undefined,
    SET_TIME_MULTIPLIER: undefined
  }
});
