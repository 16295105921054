import React from 'react';

import { styled } from '@mui/material/styles';
const PREFIX = 'DRO';

const classes = {
  dro: `${PREFIX}-dro`,
  pre: `${PREFIX}-pre`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.dro}`]: {
    borderRadius: 10,
    padding: "10px",
    backgroundColor: "rgba(100,100,100,.75)",
    color: "white",
    marginTop: 10,
    textAlign: "right"
  },

  [`& .${classes.pre}`]: {
    padding: 0,
    margin: 0
  }
}));
const DRO = ((props) => {
  const {
    X,Y,Z,A,B,C,T
  } = props;

  return (
    <Root>
      <div className={classes.dro }>
        <pre className={classes.pre}>
        X: { X }<br/>
        Y: { Y }<br/>
        Z: { Z }<br/>
        { typeof(A) !== "undefined" ? <>A: { A }<br/></> : null }
        B: { B }<br/>
        { typeof(C) !== "undefined" ? <>C: { C }<br/></> : null }
        T: { T }<br/>
        </pre>
      </div>
    </Root>
  );
});

export default DRO;
