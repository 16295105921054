import { useState, useCallback } from 'react';

export const useCallbackRef = () => {
  const [ refState, setRefState ] = useState(null);
  const callbackRef = useCallback((node) => {
    setRefState(node);
  }, []);

  return [ callbackRef, refState ];
};

export default useCallbackRef;
