import { connect } from 'react-redux';
import actions from '../actions';

import Summary from '../components/Summary';

import * as selectors from '../selectors';
import machines from '../machines';

export default connect(
  (state, ownProps) => ({
    lastSetUnitsMessage: selectors.messages.lastSetUnitsMessage(state),
    initialUnits: selectors.initialUnits(state),
    numLines: state.interpreter.numLines,
    approximateTime: state.interpreter.approximateTime,
    maxLimitMessage: selectors.messages.maxLimitMessage(state),
    minLimitMessage: selectors.messages.minLimitMessage(state),
    unimplementedMessages: selectors.messages.unimplementedMessages(state),
    nonSummarizedWarnings: selectors.messages.nonSummarizedWarnings(state),
    nonSummarizedErrors: selectors.messages.nonSummarizedErrors(state),
    wcsChangedMessages: selectors.messages.wcsChangedMessages(state),
    toolLengthOffsetMessages: selectors.messages.setToolLengthOffsetMessages(state),
    running: state.interpreter.running,
    toolTable: state.machineState.tool.table,
    modelOffsetType: state.viewer3d.modelOffsetType,
    modelOffsetIndex: state.viewer3d.modelOffsetIndex,
    modelOffsets: state.machineState.motion.g5x.offsets[state.viewer3d.modelOffsetIndex],
    modelOffsetLabels: machines[state.machineState.machine].jointLabels
  }),
  (dispatch, ownProps) => ({
    onSetTool: (tool) => dispatch(actions.machineState.tool.table.setTool(tool)),
    onSetG5xWorkOffset: (index, axis, value) => dispatch(actions.machineState.motion.g5x.setG5xWorkOffset({ index, axis, value }))
  })
)(Summary);
