import { OFF, RAPID, LINEAR, ARC_CW, ARC_CCW } from '../../constants/machine-state/motion';
import actions from '../../actions/machine-state';
import { combineActions, handleActions } from 'redux-actions';
import machines from '../../machines';
import { ABSOLUTE, INCREMENTAL, ABSOLUTE_ARC, INCREMENTAL_ARC } from '../../constants/machine-state/distance-mode';
import { MM, IN } from '../../constants/machine-state/units';
import { closestRotary, isRadiusFormatArc } from '../../util/motion-math';
import produce from 'immer';

export const doLocalMove = (action, motionTest) => {
  const {
    X, Y, Z, I, J, K, P, units, machineState
  } = action.payload;

  const {
    position, g5x
  } = machineState.motion;

  const {
    machine,
    kinematicsData
  } = machineState;

  const workOffsets = g5x.offsets[g5x.index];

  const {
    A, B, C
  } = position;

  const newJoints = machines[machine].inverseFiveAxis({
    motion: { 
      position: {
        X: ( units === MM ? X/25.4 : X ) + workOffsets.X,
        Y: ( units === MM ? Y/25.4 : Y ) + workOffsets.Y,
        Z: ( units === MM ? Z/25.4 : Z ) + workOffsets.Z,
        A: A,
        B: B,
        C: C
      },
      g5x
    },
    kinematicsData
  }, true);

  const newPos = { X: (newJoints[0] - workOffsets.X)*I + (position.X - workOffsets.X) * (1 - I),
                   Y: (newJoints[1] - workOffsets.Y)*J + (position.Y - workOffsets.Y) * (1 - J),
                   Z: (newJoints[2] - workOffsets.Z)*K + (position.Z - workOffsets.Z) * (1 - K),
                   A: A - workOffsets.A, 
                   B: B - workOffsets.B, 
                   C: C - workOffsets.C, units: IN };

  const nextPos = nextPosition({ ...machineState.motion, distanceMode: ABSOLUTE },{ payload: newPos });

  return { position: nextPos, mode: P ? LINEAR : RAPID };
};

const initialState = {
    mode: OFF,
    distanceMode: ABSOLUTE,
    arcDistanceMode: INCREMENTAL_ARC,
    turns: 0,
    issuedMotionCommand: false,
    position: {
      X: 0,
      Y: 1.5,
      Z: 0,
      A: 0,
      B: 0,
      C: 0,
      I: 0,
      J: 0,
      K: 0
    },
    g5x: {
      index: 1,
      offsets: [
        { X: 0, Y: 0, Z: 0, A: 0, B: 0, C: 0 },
        { X: 0, Y: 0, Z: 0, A: 0, B: 0, C: 0 }, // G54
        { X: 0, Y: 0, Z: 0, A: 0, B: 0, C: 0 }, // G55
        { X: 0, Y: 0, Z: 0, A: 0, B: 0, C: 0 }, // G56
        { X: 0, Y: 0, Z: 0, A: 0, B: 0, C: 0 }, // G57
        { X: 0, Y: 0, Z: 0, A: 0, B: 0, C: 0 }, // G58
        { X: 0, Y: 0, Z: 0, A: 0, B: 0, C: 0 }, // G59
        { X: 0, Y: 0, Z: 0, A: 0, B: 0, C: 0 }, // G59.1
        { X: 0, Y: 0, Z: 0, A: 0, B: 0, C: 0 }, // G59.2
        { X: 0, Y: 0, Z: 0, A: 0, B: 0, C: 0 }, // G59.3
      ]
    }
  };

// TODO - we've duplicated a portion of this in doInverseKinematics
//      - perhaps we should put all of this over there
const nextPosition = (state, action) => {
  const prevPos = state.position;
  const { X, Y, Z, A, B, C, I, J, K, R, units, G53 } = action.payload;
  const distanceMode = state.distanceMode;
  const arcDistanceMode = state.arcDistanceMode;
  const workOffsets = state.g5x.offsets[state.g5x.index];

  const radiusFormatArc = isRadiusFormatArc(action.payload);

  let params = { X, Y, Z, A, B, C };
  for(const key in params) {
    if(params[key] === undefined) {
      delete params[key];
    } else if(units === MM && (key === 'X' || key === 'Y' || key === 'Z')) {
      params[key] = params[key]/25.4; // convert parameters to inches
    }
  }

  let arcParams = { I, J, K };
  for(const key in arcParams) {
    if(arcParams[key] === undefined) {
      arcParams[key] = 0;
    } else if(units === MM) {
      arcParams[key] = arcParams[key]/25.4; // convert parameters to inches
    }
  }

  let pos = state.position;
  if(!G53) { // handle G53 in inverseKinematics
    if(distanceMode === ABSOLUTE) {
      const offsets = {};
      for(const key in params) {
        offsets[key] = params[key]+workOffsets[key];
      }
      pos = {
        ...state.position,
        ...offsets
      };
    } else {
      // if(distanceMode === INCREMENTAL) 
      pos = {
        ...pos
      };
      for(const key in params) {
        pos[key] = prevPos[key]+params[key];
      }
    }
  }

  if(arcDistanceMode === ABSOLUTE_ARC) {
    // TODO check that both I and J are present for XY plane (G17), or both I and K are present for XZ plane (G18) or both J and K for YZ plane (G19)
    // this page only mentions XY and XZ planes for some reason: http://linuxcnc.org/docs/html/gcode/g-code.html#gcode:g90.1-g91.1
    // will need to test with a real machine to see what happens with each plane
    pos = {
      ...state.position,
      ...arcParams
    };
  } else {
    // if(arcDistanceMode === INCREMENTAL_ARC) 
    const mapping = {
      I: "X",
      J: "Y",
      K: "Z"
    };
    pos = {
      ...pos
    };
    for(const key in arcParams) {
      pos[key] = prevPos[mapping[key]]+arcParams[key];
    }
  }

  if(radiusFormatArc) {
    if(units === MM) {
      pos = { ...pos, R: R/25.4 };
    } else {
      pos = { ...pos, R };
    }
  } else {
    pos = { ...pos, R: 0 };
  }

  return pos;
};

const reducer = handleActions(
  {
    [actions.machineState.motion.resetRotary]: (state, action) => {
      const { P, machineState } = action.payload;
      const { position } = state;
      const { machine } = machineState;

      const continuousRotationJoint = 4;
      const minRot = machines[machine].limits.extents.min[continuousRotationJoint];
      const maxRot = machines[machine].limits.extents.max[continuousRotationJoint];
      const axis = machines[machine].jointLabels[continuousRotationJoint];

      const closestRot = closestRotary(position[axis], P, minRot, maxRot); 

      const nextPos = { ...state.position, [axis]: closestRot };
      return { ...state, position: nextPos };
    },
    [actions.machineState.offsets.set]: produce((state, action) => {
      const {
        L, P
      } = action.payload;
      if(L === 2 && P >= 0 && P <= 9) {
        let index = P;
        if(index === 0) {
          index = state.g5x.index;
        }
        const axes = [ "X", "Y", "Z", "A", "B", "C" ]; 
        for(let axis in action.payload) {
          if(axes.indexOf(axis) >= 0) {
            state.g5x.offsets[index][axis] = action.payload[axis];
          }
        }
      }
    }),
    [actions.machineState.distanceMode.setAbsolute]: (state, action) => ({ ...state, distanceMode: ABSOLUTE }),
    [actions.machineState.distanceMode.setIncremental]: (state, action) => ({ ...state, distanceMode: INCREMENTAL }),
    [actions.machineState.motion.nonMotionCommand]: (state, action) => ({ ...state, issuedMotionCommand: false }),
    [actions.machineState.motion.off]: (state, action) => ({ ...state, mode: OFF }),
    [actions.machineState.motion.rapid]: (state, action) => {
      const nextPos = nextPosition(state,action);

      return { ...state, 
               position: nextPos,
               issuedMotionCommand: true,
               mode: RAPID };
    },
    [actions.machineState.motion.linear]: (state, action) => {
      const nextPos = nextPosition(state,action);

      return { ...state, 
               position: nextPos,
               issuedMotionCommand: true,
               mode: LINEAR };
    },
    [actions.machineState.motion.localMove]: (state, action) => {
      const { position, mode } = doLocalMove(action, state);

      const newState = {
        ...state,
        position,
        issuedMotionCommand: true,
        mode 
      };

      return newState;
    },
    [actions.machineState.motion.arcCW]: (state, action) => {
      const { P } = action.payload;

      const nextPos = nextPosition(state,action);

      return { ...state, 
               position: nextPos,
               turns: P,
               issuedMotionCommand: true,
               mode: ARC_CW };
    },
    [actions.machineState.motion.arcCCW]: (state, action) => {
      const { P } = action.payload;

      const nextPos = nextPosition(state,action);

      return { ...state, 
               position: nextPos,
               turns: P,
               issuedMotionCommand: true,
               mode: ARC_CCW };
    },
    [actions.machineState.motion.setPosition]: (state, action) => {
      const nextPos = nextPosition(state,action);

      return { ...state, 
               position: nextPos,
               issuedMotionCommand: true };
    },
    [actions.machineState.motion.g5x.enableG54]: produce((state, action) => { state.g5x.index = 1 }),
    [actions.machineState.motion.g5x.enableG55]: produce((state, action) => { state.g5x.index = 2 }),
    [actions.machineState.motion.g5x.enableG56]: produce((state, action) => { state.g5x.index = 3 }),
    [actions.machineState.motion.g5x.enableG57]: produce((state, action) => { state.g5x.index = 4 }),
    [actions.machineState.motion.g5x.enableG58]: produce((state, action) => { state.g5x.index = 5 }),
    [actions.machineState.motion.g5x.enableG59]: produce((state, action) => { state.g5x.index = 6 }),
    [actions.machineState.motion.g5x.enableG591]: produce((state, action) => { state.g5x.index = 7 }),
    [actions.machineState.motion.g5x.enableG592]: produce((state, action) => { state.g5x.index = 8 }),
    [actions.machineState.motion.g5x.enableG593]: produce((state, action) => { state.g5x.index = 9 }),
    [actions.machineState.motion.g5x.computeRotatedWorkOffsets]: produce((state, action) => {
      const { P, machineState } = action.payload;
      const { machine } = machineState;

      state.g5x.offsets[P] = machines[machine].computeRotatedWorkOffsets(machineState); 
    }),
    [actions.machineState.motion.g5x.setG5xWorkOffset]: produce((state,action) => {
      const {
        index,
        axis,
        value
      } = action.payload;

      state.g5x.offsets[index][axis] = value;
    })
  },
  initialState
);

export const doForwardKinematics = (machineState) => handleActions(
{
    // Unlike most other reduces, we have access to the full machineState tree in this reducer.
    [combineActions(
       actions.machineState.tool.setToolLengthOffset,
       actions.machineState.tool.clearToolLengthOffset,
       actions.machineState.motion.rapid,
       actions.machineState.motion.linear,
       actions.machineState.motion.localMove,
       actions.machineState.motion.arcCW,
       actions.machineState.motion.arcCCW,
       actions.machineState.motion.setPosition,
       actions.machineState.motion.resetRotary,
       actions.machineState.kinematicsMode.setFiveAxis,
       actions.machineState.kinematicsMode.setTrivial
    )]: (state, action) => {
      const machine = machines[machineState.machine];

      const pos = {
        ...state.position,
        ...machine.kinematics.forwardKinematics(machineState)
      };

      return { ...state, position: pos };
    }
},
initialState
);

export default reducer;
