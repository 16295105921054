import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

function CopyLinkWidget(props) {
  const linkData = document.location.toString();
  const handleCopy = () => {
    if(navigator && navigator.clipboard) {
      navigator.clipboard.writeText(linkData);
    }
  };

  return <>
  Link
  <div>
  <TextField
    inputProps={{ readOnly: "readonly" }}
    variant="outlined"
    defaultValue={linkData}
  />
  <Button onClick={handleCopy}>Copy Link</Button>
  </div>
  </>;
}

export default CopyLinkWidget;
