import React, { useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Viewer3D from '../components/viewer3d/Viewer3D';
import TitleBar from './TitleBar';
import GCodePane from '../containers/GCodePane';
import Divider from '../containers/Divider';
import ControlsOverlay from '../containers/ControlsOverlay';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CameraButtons from '../containers/CameraButtons';
import DROPane from '../containers/DROPane';
import OtherOptionsPane from '../containers/OtherOptionsPane';
import machines from '../machines';
import InterpreterBackPlot from '../containers/viewer3d/InterpreterBackPlot';
import InterpreterModel from './viewer3d/InterpreterModel';
import SimpleTool from './viewer3d/SimpleTool';
import MachineMenu from '../containers/MachineMenu';
import DemoOverlay from '../containers/DemoOverlay';

import { ReactReduxContext } from 'react-redux';
import { useContextBridge } from '@react-three/drei';

const PREFIX = 'App';

const classes = {
  container: `${PREFIX}-container`,
  machinePane: `${PREFIX}-machinePane`,
  topSpacing: `${PREFIX}-topSpacing`,
  showTitleBar: `${PREFIX}-showTitleBar`,
  hideTitleBar: `${PREFIX}-hideTitleBar`,
  portrait: `${PREFIX}-portrait`,
  landscape: `${PREFIX}-landscape`,
  shiftDown: `${PREFIX}-shiftDown`
};

const StyledContainer = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.portrait}`]: { 
    position: "absolute", 
    width: "100%", 
    height: "100%", 
    maxHeight: "100vw", 
    top: "calc((100vh - 100vw) * .3)" 
  },
  [`& .${classes.landscape}`]: { 
    position: "absolute", 
    width: "100%", 
    height: "100%"
  },
  [`& .${classes.shiftDown}`]: {
    position: "relative",
    height: "100%"
  },
  [`& .${classes.container}`]: {
    position: "relative",
//    width: "100vw",
//    height: "100vh",
    overflow: "hidden"
  },

  [`& .${classes.machinePane}`]: {
    position: "fixed",
    right: 10,
    bottom: 0
  },

  [`& .${classes.topSpacing}`]: {
    marginTop: "10px"
  },

  [`& .${classes.showTitleBar}`]: {
    paddingTop: 64, 
    height: "calc( 100% - 64px )",
  },

  [`& .${classes.hideTitleBar}`]: {
    paddingTop: 0, 
    height: "100%",
    position: "relative"
  }
}));

const theme = createTheme({
  palette: {
    primary: {
      verylight: "#edfcf7",
      light: "#59e4b8",
      main: "#03b188",
      dark: "#00805b",
      contrastText: "#ffffff"
    },
    secondary: {
      verylight: "#fffcee",
      main: "#ffb431",
      light: "#ffe665",
      dark: "#c78500",
      contrastText: "#2d2d2d"
    },
    text: {
      primary: "#333333",
      secondary: "#333333",
    }
  },

  typography: {
    useNextVariants: true
  }
});
const App = ((props) => {
  const {
    hideTitleBar,
    demo,
    onNextFeaturesSubStep,
    machine,
    joints,
    toolLoaded,
    toolHolder,
    toolLength,
    toolType,
    toolDiameter,
    tool,
    kinematicsData,
    modelScale,
    modelColor,
    modelOpacity,
    spindleAngle,
    currentTime,
    modelOffsets,
    showModel
  } = props;

  const ContextBridge = useContextBridge(ReactReduxContext);
  const Machine = machines[machine].Machine;

  const containerRef = useRef();

// This is to prevent accidental right clicks when using a touch screen.
// A long press commonly acts as a right click and a context menu appears,
// but generally isn't what the user wants. This can make it more difficult
// to inspect the HTML, so it can be commented out in development, or we
// could figure out a good way to disable the context menu on demand.
//  useEffect(() => {
//    document.addEventListener('contextmenu', (e) => e.preventDefault());
//  }, []);

  // This is so height is properly set on mobile.
  // Just using CSS didn't seem to cut it.
  // See https://medium.com/@susiekim9/how-to-compensate-for-the-ios-viewport-unit-bug-46e78d54af0d
  // https://stackoverflow.com/questions/43575363/css-100vh-is-too-tall-on-mobile-due-to-browser-ui
  useEffect(() => {
    if(containerRef && containerRef.current) {
      const el = containerRef.current;

      const handleResize = () => {
        el.style.height = window.innerHeight + "px";
      }

      window.addEventListener("resize", handleResize);

      handleResize();

      return () => {
        window.removeEventListener("resize", handleResize);
      }
    }
  }, [ containerRef ]);
  

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StyledContainer>
          <div className={classes.container} ref={containerRef}>
            { hideTitleBar ? null : <TitleBar/> }
            <div className={ hideTitleBar ? classes.hideTitleBar : classes.showTitleBar }>
              <div className={ classes.shiftDown }>
                <GCodePane/>
                <Divider width={5}/>
                <div className={(window.innerWidth > window.innerHeight || window.innerWidth < 600) ? classes.landscape : classes.portrait}>
                  <CameraButtons/>
                  <DROPane/>
                  <OtherOptionsPane/>
                  <ControlsOverlay/>
                </div>
                <DemoOverlay/>
                <Viewer3D demo={demo} onNextFeaturesSubStep={onNextFeaturesSubStep}>
                  <ContextBridge>
                  <Machine joints={joints} 
                           toolHolder={toolHolder}
                           kinematicsData={kinematicsData}
                           tool={<SimpleTool diameter={toolDiameter} 
                                 loaded={toolLoaded} 
                                 length={toolLength} 
                                 tool={tool}
                                 type={toolType}/>} 
                           model={<InterpreterModel showModel={showModel} modelScale={modelScale} modelColor={modelColor} modelOpacity={modelOpacity}/>}
                           spindleAngle={spindleAngle}
                           modelOffsets={modelOffsets}>
                    <InterpreterBackPlot currentTime={currentTime}/>
                  </Machine>
                  </ContextBridge>
                </Viewer3D>
              </div>
            </div>

            <MachineMenu/>
          </div>
        </StyledContainer>
      </ThemeProvider>
    </StyledEngineProvider>
  );
})

export default App;
