import SimpleFileSelectButton from './SimpleFileSelectButton';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';


function SimpleGCodeLoader(props) {
  const {
    onFileSelect
  } = props;
  return (<SimpleFileSelectButton onFileSelect={onFileSelect} size="small" color="inherit">
    <OpenInBrowserIcon/> GCode
  </SimpleFileSelectButton>);
}

export default SimpleGCodeLoader;
