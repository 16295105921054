import * as v2 from './v2';
import * as v250 from './v2-50';
import * as solo from './solo';
import * as v1 from './v1';
import * as v1kickstarter from './v1kickstarter';
import { V1KICKSTARTER, V1, V2, V2_50, SOLO } from '../constants/machine-state/machine';
import { EPS } from '../constants';

const machines = {
  [V1]: v1,
  [V1KICKSTARTER]: v1kickstarter,
  [V2]: v2,
  [V2_50]: v250,
  [SOLO]: solo
};

export default machines;

// returns true if within valid limits, false otherwise
export const checkLimits = (machine, jointIndex, joint) => {
  const min = machines[machine].limits.extents.min[jointIndex];
  const max = machines[machine].limits.extents.max[jointIndex];

  return joint >= min-EPS && joint <= max+EPS;
};
