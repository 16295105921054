export const MAX_STEPS = 5;
const steps = [ ];
for(let i = 0; i < MAX_STEPS; i++) {
  steps[i] = i;
}
export const [ TRY_ME, SWIPE, PINCH, FEATURES, DONE ] = steps;

export const MAX_SUB_STEPS = 9;
const subSteps = [ ];
for(let i = 0; i < MAX_SUB_STEPS; i++) {
  subSteps[i] = i;
}
export const [ GCODE_OPEN, PLAY, PAUSE, NEXT_LINE, NEXT_TOOL_PATH, GCODE_CLOSE, CAMERA, OTHER_OPTIONS_OPEN, OTHER_OPTIONS_CLOSE ] = subSteps;

