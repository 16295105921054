import * as v2 from './v2';
import V1kickstarter from '../components/viewer3d/V1kickstarter';

export const toolLength = (toolOffset) => {
  return 4+toolOffset;
};;
export const computeRotatedWorkOffsets = v2.computeRotatedWorkOffsets;
export const toolDirection = v2.toolDirection;
export const forwardFiveAxis = v2.forwardFiveAxis;
export const inverseFiveAxis = v2.inverseFiveAxis;
export const workPieceSpace = v2.workPieceSpace;
export const kinematics = v2.kinematics;
export const limits = {
  extents: {
    max: [ 2.55, 2.95, 0.1, 95, 99999 ],
    min: [ -1.75, -2.05, -3.45, -5, -9999]
  },
  // x,y,z in inches/second
  // a,b in degrees/second
  velocities: [ .6666, .6666, .6666, 10, 20 ],
  accelerations: [ 10, 10, 10, 1800, 1800 ]
};
export const jointLabels = v2.jointLabels;
export const getLimitErrors = v2.getLimitErrors;
export const name = "V1 Kickstarter";
export const Machine = V1kickstarter;
