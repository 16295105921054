import React, { forwardRef, useLayoutEffect, useRef } from 'react'
import { extend, useThree } from '@react-three/fiber'
import { BlendPerspectiveOrthographicCamera } from '../../viewer3d/blend-perspective-orthographic-camera';
import { mergeRefs } from 'react-merge-refs';

extend({ BlendPerspectiveOrthographicCamera });

const BlendCamera = forwardRef( function BlendCamera({ makeDefault, ...props}, ref) {
  const set = useThree(({ set }) => set);
  const camera = useThree(({ camera }) => camera);
  const size = useThree(({ size }) => size);
  const cameraRef = useRef();
  useLayoutEffect(() => {
    const { current: cam } = cameraRef
    if (cam && !props.manual) {
      cam.aspect = size.width / size.height
      cam.updateProjectionMatrix()
    }
  }, [size, props, cameraRef]);
  useLayoutEffect(() => {
    if(makeDefault && cameraRef.current) {
      const oldCam = camera;
      set(() => ({ camera: cameraRef.current }));
      return () => set(() => ({ camera: oldCam }));
    }
  }, [makeDefault, camera, cameraRef, set]);

  return <blendPerspectiveOrthographicCamera ref={mergeRefs([cameraRef,ref])} {...props}/>
});

export default BlendCamera;
