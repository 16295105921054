import { connect } from 'react-redux';

import DROPane from '../components/DROPane';

export default connect(
  (state, ownProps) => ({
  }),
  (dispatch, ownProps) => ({
  })
)(DROPane);
