import React, { Component } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

const PREFIX = 'SimpleFileSelectButton';

const classes = {
  fileSelect: `${PREFIX}-fileSelect`
};

const Root = styled('span')((
  {
    theme
  }
) => ({
  [`& .${classes.fileSelect}`]: {
    display: "none"
  }
}));

class FileSelectButton extends Component {
  handleFileSelect = (e) => {
    const {
      onFileSelect
    } = this.props;

    if(e.target.files.length === 1) {
      if(onFileSelect) {
        onFileSelect(e.target.files[0]);
      }

      this.fileInput.value = "";
    }
  };

  handleFileOpen = (e) => {
    this.fileInput.click();
  };

  render() {
    const { 
      children, 
      color,
      component,
      disabled,
      disableFocusRipple,
      disableRipple,
      fullWidth,
      href,
      mini,
      size,
      variant,
      buttonClasses,
      accept
    } = this.props;

    return (
      <Root>
        <Button 
          onClick={this.handleFileOpen} 
          classes={buttonClasses} 
          component={component}
          color={color} 
          disabled={disabled}
          disableFocusRipple={disableFocusRipple}
          disableRipple={disableRipple}
          fullWidth={fullWidth}
          href={href}
          mini={mini}
          size={size}
          variant={variant}>{children}</Button>
        <input 
          onChange={this.handleFileSelect} 
          ref={(fileInput) => this.fileInput = fileInput} 
          type="file" 
          accept={accept}
          className={classes.fileSelect}/>
      </Root>
    );
  }
}

FileSelectButton.propTypes = {
  onFileSelect: PropTypes.func,
  onError: PropTypes.func
};

export default (FileSelectButton);
