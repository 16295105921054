import { createActions } from 'redux-actions';

const actions = createActions({
  UI: {
    TITLE_BAR: {
      HIDE: undefined,
      SHOW: undefined
    }
  }
});

export default actions;
