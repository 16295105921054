import actions from '../actions/machine-state';
export const notImplemented = {
  // motion commands
  "G4": true,
  "G5": true,
  "G5.1": true,
  "G5.2": true,
  "G5.3": true,

  // Lathe related
  "G7": true,
  "G8": true,

  // plane select
  "G17.1": true,
  "G18.1": true,
  "G19.1": true,

  // go/set predefined position
  "G28": true,
  "G28.1": true,
  "G30": true,
  "G30.1": true,

  // spindle synchronized motion
  "G33": true,
  "G33.1": true,

  // straight probe
  "G38.2": true,
  "G38.3": true,
  "G38.4": true,
  "G38.5": true,

  // cutter compensation
  "G41": true,
  "G42": true,
  "G41.1": true,
  "G42.1": true,

  // tool length offset
  "G43.1": true,
  "G43.2": true,

  // exact path mode
  "G61": true,
  "G61.1": true,

  // path blending
  "G64": true,

  // drilling cycle with chip breaking
  "G73": true,

  // threading cycle
  "G76": true,


  // canned cycle
  "G80": true,
  "G81": true,
  "G82": true,
  "G83": true,
  "G84": true,
  "G85": true,
  "G86": true,
  "G87": true,
  "G88": true,
  "G89": true,

  // coordinate system offset
  "G92": true,
  "G92.1": true,
  "G92.2": true,
  "G92.3": true,

  // feed rate mode
  "G95": true,

  // spindle control mode
  "G96": true,
  "G97": true,

  // canned cycle return level
  "G98": true,
  "G99": true,

  // program 
  "M0": true,
  "M1": true,
  "M2": true,
  "M30": true,
  
  // coolant control
  "M7": true,
  "M8": true,
  "M9": true,

  // orient spindle
  "M19": true,

  // feed and spindle overrides enable/disable
  "M48": true,
  "M49": true,

  // feed override control
  "M50": true,

  // spindle override control
  "M51": true,

  // adaptive feed control
  "M52": true,

  // feed stop control
  "M53": true,

  // set current tool number
  "M61": true,

  // output control
  "M62": true,
  "M63": true,
  "M64": true,
  "M65": true,

  // input control
  "M66": true,

  // analog output control
  "M67": true,
  "M68": true,

  // save modal state
  "M70": true,

  // invalidate stored modal state
  "M71": true,

  // restore modal state
  "M72": true,

  // save autorestore modal state
  "M73": true,

  "M700": true
};

export const commandDescriptions = {
  // motion commands
  "G0": "Move at machine's maximum speed.",
  "G1": "Move at the current feed rate.",
  "G2": "Make a clockwise arc move.",
  "G3": "Make a counter-clockwise arc move.",

  "G4": "Dwell in place.",
  "G5": "Make a cubic spline move.",
  "G5.1": "Make a quadratic spline move.",
  "G5.2": "Make a NURBS move.",
  "G5.3": "Make a NURBS move.",
  "G6.2": "Move to local coordinates, but constrained to specific physical axes of movement.",

  // canned cycle
  "G80": "Cancel canned cycle.",

  // plane select
  "G17": "Select XY plane.",
  "G18": "Select XZ plane.",
  "G19": "Select YZ plane.",

  // cutter compensation
  "G40": "Turn off cutter compensation.",

  // tool length offset
  "G43": "Enable tool length offset.",
  "G49": "Disable tool length offset.",

  // distance mode
  "G90": "Set absolute distance mode.",
  "G91": "Set incremental distance mode.",
  "G90.1": "Set absolute arc distance mode.",
  "G91.1": "Set incremental arc distance mode.",

  // feed rate mode
  "G93": "Set inverse time feed rate mode.",
  "G94": "Set units per minute feed rate mode.",

  // Lathe related
  "G7": "Enable lathe diameter mode.",
  "G8": "Enable lathe radius mode.",

  // tool table and coordinate systems
  "G10": "Set tool or work offsets.",

  // plane select
  "G17.1": "Select UV plane.",
  "G18.1": "Select WU plane.",
  "G19.1": "Select VW plane.",

  // go/set predefined position
  "G28": "Go to predefined absolute position stored in parameters 5161-5166.",
  "G28.1": "Store current position in parameters 5161-5166",
  "G30": "Go to predefined absolute position stored in parameters 5181-5186.",
  "G30.1": "Store current position in parameters 5181-5186.",

  // spindle synchronized motion
  "G33": "Spindle synchronized motion.",
  "G33.1": "Rigid tapping.",

  // straight probe
  "G38.2": "Probe toward workpiece, stop on contact, signal error if failure. Implemented to behave like G1.",
  "G38.3": "Probe toward workpiece, stop on contact. Implemented to behave like G1.",
  "G38.4": "Probe away from workpiece, stop on loss of contact, signal error if failure. Implemented to behave like G1.",
  "G38.5": "Probe away from workpiece, stop on loss of contact. Implemented to behave like G1.",

  // cutter compensation
  "G41": "Enable cutter compensation left of programmed path.",
  "G42": "Enable cutter compensation right of programmed path.",
  "G41.1": "Enable cutter compensation left of programmed path with tool diameter.",
  "G42.1": "Enable cutter compensation right of programmed path with tool diameter.",

  // tool length offset
  "G43.1": "Dynamic tool length offset.",
  "G43.2": "Apply additional tool length offset.",

  // select coordinate system
  "G54": "Select coordinate system 1 (the default).",
  "G55": "Select coordinate system 2.",
  "G56": "Select coordinate system 3.",
  "G57": "Select coordinate system 4.",
  "G58": "Select coordinate system 5.",
  "G59": "Select coordinate system 6.",
  "G59.1": "Select coordinate system 7.",
  "G59.2": "Select coordinate system 8.",
  "G59.3": "Select coordinate system 9.",

  // exact path mode
  "G61": "Enable exact path mode.",
  "G61.1": "Enable exact path mode.",

  // path blending
  "G64": "Set path blending parameters.",

  // drilling cycle with chip breaking
  "G73": "Drilling cycle with chip breaking.",

  // threading cycle
  "G76": "Threading cycle.",

  // canned cycle
  "G81": "Drilling cycle.",
  "G82": "Drilling cycle, dwell.",
  "G83": "Peck drilling cycle.",
  "G84": "Right-hand tapping cycle.",
  "G85": "Boring cycle, feed out.",
  "G86": "Boring cycle, spindle stop, rapid move out.",
  "G87": "Back boring cycle.",
  "G88": "Boring cycle, spindle stop, manual out.",
  "G89": "Boring cycle, dwell, feed out.",

  // coordinate system offset
  "G92": "Offsets the coordinate system by setting the current point to the axis numbers you want and stores the offsets in parameters 5211-5219.",
  "G92.1": "Reset G92 offsets to zero and sets parameters 5211-5219 to zero.",
  "G92.2": "Reset G92 offsets to zero.",
  "G92.3": "Set the G92 offsets to the values saved in parameters 5211-5219.",

  // feed rate mode
  "G95": "Set units per revolution feed rate mode.",

  // spindle control mode
  "G96": "Spindle control mode, constant surface speed.",
  "G97": "Spindle control mode, RPM mode.",

  // canned cycle return level
  "G98": "Retract to the position that axis was in just before this series of one or more contiguous canned cycles was started.",
  "G99": "Retract to the position specified by the R word of the canned cycle.",


  // spindle control
  "M3": "Turn on spindle going clockwise.",
  "M4": "Turn on spindle going counter-clockwise.",
  "M5": "Turn off spindle.",
  
  // tool change
  "M6": "Change tool.",

  // program 
  "M0": "Pause program.",
  "M1": "Pause program if optional stop switch is on.",
  "M2": "End the program. Pressing cycle start will start the program at the beginning of the file.",
  "M30": "End the program. Pressing cycle start will start the program at the beginning of the file.",
  
  // coolant control
  "M7": "Turn mist coolant on.",
  "M8": "Turn flood coolant on.",
  "M9": "Turn both mist and flood coolant off.",

  // orient spindle
  "M19": "Orient spindle.",

  // feed and spindle overrides enable/disable
  "M48": "Enable speed and feed rate override controls.",
  "M49": "Disable spindle speed and feed rate override controls.",

  // feed override control
  "M50": "Feed override control.",

  // spindle override control
  "M51": "Spindle speed override control.",

  // adaptive feed control
  "M52": "Use adaptive feed.",

  // feed stop control
  "M53": "Enable feed stop switch.",

  // set current tool number
  "M61": "Change the current tool number while in MDI or Manual mode.",

  // output control
  "M62": "Turn on digital output synchronized with motion.",
  "M63": "Turn on digital output synchronized with motion.",
  "M64": "Turn on digital output immediately.",
  "M65": "Turn on digital output immediately.",

  // input control
  "M66": "Wait on digital input.",

  // analog output control
  "M67": "Set an analog output synchronized with motion.",
  "M68": "Set an analog output immediately.",

  // save modal state
  "M70": "Save modal state.",

  // invalidate stored modal state
  "M71": "Invalidate saved modal state.",

  // restore modal state
  "M72": "Restore modal state.",

  // save autorestore modal state
  "M73": "Save modal state with a subroutine and restore state on subroutine endsub or any return path.",

  // dynamic work offsets
  "M254": "Rotate the current work offset by the current tilt angle and store the results in the specified work offsets.",

  // kinematics
  "M428": "Enable TCPC.",
  "M429": "Disable TCPC.",

  "M700": "Display messages to user in Kinetic Control.",

  "M999": "Set rotary position to +/- a multiple of 360 degrees to set it closest to a desired angle provided in the P argument without physically moving the axis."
};

export const commands = {
  // motion commands
  "G0": actions.machineState.motion.rapid,
  "G1": actions.machineState.motion.linear,
  "G2": actions.machineState.motion.arcCW,
  "G3": actions.machineState.motion.arcCCW,
  "G6.2": actions.machineState.motion.localMove,

  "G38.2": actions.machineState.motion.linear,
  "G38.3": actions.machineState.motion.linear,
  "G38.4": actions.machineState.motion.linear,
  "G38.5": actions.machineState.motion.linear,
  
  "G10": actions.machineState.offsets.set,

  // plane select
  "G17": actions.machineState.planeSelect.setXY,
  "G18": actions.machineState.planeSelect.setZX,
  "G19": actions.machineState.planeSelect.setYZ,

  // cutter compensation
  "G40": actions.machineState.cutterCompensation.off,

  // tool length offset
  "G43": actions.machineState.tool.setToolLengthOffset,
  "G49": actions.machineState.tool.clearToolLengthOffset,

  // select coordinate system
  "G54": actions.machineState.motion.g5x.enableG54,
  "G55": actions.machineState.motion.g5x.enableG55,
  "G56": actions.machineState.motion.g5x.enableG56,
  "G57": actions.machineState.motion.g5x.enableG57,
  "G58": actions.machineState.motion.g5x.enableG58,
  "G59": actions.machineState.motion.g5x.enableG59,
  "G59.1": actions.machineState.motion.g5x.enableG591,
  "G59.2": actions.machineState.motion.g5x.enableG592,
  "G59.3": actions.machineState.motion.g5x.enableG593,

  // distance mode
  "G90": actions.machineState.distanceMode.setAbsolute,
  "G91": actions.machineState.distanceMode.setIncremental,
  "G90.1": actions.machineState.distanceMode.setAbsoluteArc,
  "G91.1": actions.machineState.distanceMode.setIncrementalArc,

  // feed rate mode
  "G93": actions.machineState.feedRateMode.setInverseTime,
  "G94": actions.machineState.feedRateMode.setUnitsPerMinute,

  // spindle control
  "M3": actions.machineState.spindleControl.cw,
  "M4": actions.machineState.spindleControl.ccw,
  "M5": actions.machineState.spindleControl.off,
  
  // tool change
  "M6": actions.machineState.tool.change,

  // Dynamic work offsets
  "M254": actions.machineState.motion.g5x.computeRotatedWorkOffsets,

  // kinematics
  "M428": actions.machineState.kinematicsMode.setFiveAxis,
  "M429": actions.machineState.kinematicsMode.setTrivial,
  "M999": actions.machineState.motion.resetRotary
};

export const unitsCommands = {
  // units
  "G20": actions.machineState.units.setIn,
  "G21": actions.machineState.units.setMm,
};

export const parameterCommands = {
  "T": actions.machineState.tool.set,
  "S": actions.machineState.spindleSpeed.set,
  "F": actions.machineState.feedRate.set
};

export const nonModalCommands = {
  "G53": true
};

export const g5xCommands = {
  "G54": true,
  "G55": true,
  "G56": true,
  "G57": true,
  "G58": true,
  "G59": true,
  "G59.1": true,
  "G59.2": true,
  "G59.3": true
};

export const lookUpNonModalCommand = (code) => {
  return nonModalCommands[code];
};

export const lookUpCommand = (code) => {
  return commands[code];
};
export const lookUpCommandDescription = (code) => {
  return commandDescriptions[code];
};

export const lookUpUnitsCommand = (code) => {
  return unitsCommands[code];
}

export const lookUpParameterCommand = (word) => {
  return parameterCommands[word];
};

export const setPosition = actions.machineState.motion.setPosition;

export const lookUpNotImplemented = (code) => {
  return notImplemented[code];
};

export const lookUpG5xCommand = (code) => {
  return g5xCommands[code];
}
