import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import './index.css';
import App from './containers/App';
//import AlignerApp from './containers/AlignerApp';
import actions from './actions';
import { HashRouter, Routes, Route } from "react-router-dom";

import IFrameMessenger from './iframe';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';
import rootSaga from './sagas';

import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import getAnalytics from './util/analytics';

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  rootReducer, {}, composeWithDevTools(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

getAnalytics().pageview("/", document.location.href, document.title).send();

console.log("Penta Simulator v" + process.env.REACT_APP_VERSION);
console.log("Commit", process.env.REACT_APP_COMMIT);
export const version = process.env.REACT_APP_VERSION;
export const commit = process.env.REACT_APP_COMMIT;

window.addEventListener("message", (e) => {
  if(e.origin !== document.location.origin) {
    //console.log("in simulator on message",  { data: e.data, origin: e.origin });
    const initializeType = actions.iframe.initialize().type;
    const rockhopperType = actions.iframe.rockhopper({}).type;
    const embedGcodeType = actions.iframe.embedGcode().type;
    const iframeMessenger = IFrameMessenger.getInstance();
    switch(e.data.type) {
      case initializeType:
        store.dispatch(actions.iframe.initialize(e.data.payload));
        iframeMessenger.addWindow(e.source, e.origin);
        break;
      case rockhopperType:
//        console.log(e.data.payload.id, e.data.payload.data);
        if(e.data.payload.id === "file_content") {
          const lines = e.data.payload.data.split("\n").map((line) => line.replace("\r", ""));
          store.dispatch(actions.interpreter.setLines(lines));
        } else if(e.data.payload.id.startsWith("tool_table")) {
          const P = e.data.payload.id.split(":")[1];
          const Z = e.data.payload.data[3];
          const R = e.data.payload.data[10];

          store.dispatch(actions.machineState.tool.table.setTool({ P, Z, R }));
        }
        break;
      case embedGcodeType:
        const gcode = e.data.payload.split("\n").map((line) => line.trim());
        store.dispatch(actions.interpreter.setLines(gcode));
        break;
      default:
        break;
    }
  }
});

// pocketnc-ui likes to take focus to ensure keyboard shortcuts for jogging are captured.
// If we don't grab focus here, the backplot looks really laggy until we interact with a
// ui element because we allow a less responsive window when we don't have focus to 
// increase processing speed of the interpreter
window.focus();

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);

root.render(
<Provider store={store}>
  <HashRouter hashType="noslash">
    <Routes>
      <Route path="/" exact element={<App/>}/>
    </Routes>
  </HashRouter>
</Provider>);

function unregisterServiceWorker() {
  if("serviceWorker" in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      return Promise.all(registrations.map((reg) => reg.unregister()));
    });
  }
}
unregisterServiceWorker();
