import React, { Suspense, useRef, useMemo, useCallback, useState, useEffect } from 'react'
import { Canvas, useFrame, useThree, createPortal } from '@react-three/fiber'
import { OrthographicCamera, useCamera } from '@react-three/drei'
import * as THREE from 'three';
import CameraControls, { useCameraControls, selectAlignWithDirection, selectAddLocalOrientationObject, selectRemoveLocalOrientationObject } from './CameraControls';
import ViewCube from './ViewCube';
import { useSpring, animated } from '@react-spring/web';
import Environment from './Environment';
import { FEATURES, CAMERA } from '../../constants/demo';
import LoaderProgress from '../LoaderProgress';

const AnimatedViewCube = animated(ViewCube);

function HUD(props) {
  const {
    demo,
    onNextFeaturesSubStep
  } = props;

  const { enabled, step, featuresSubStep: subStep } = demo || {};
  const demoOpen = enabled && (step === FEATURES && subStep === CAMERA);

  const { gl, scene, camera, size } = useThree()

  const hudScene = useMemo(() => new THREE.Scene(), [])
  const hudCamera = useRef()
  const viewCubeRef = useRef()

  const [ hitNormal, setHitNormal ] = useState([0,0,0]);
  const [ targetOpacity, setOpacity ] = useState(.25);

  const springOpacity = demoOpen ? 1 : targetOpacity;

  const { opacity } = useSpring({ to: { opacity: springOpacity } });

  const displayHitNormal = demoOpen ? [ 0, 0, 1 ] : hitNormal;

  const alignWithDirection = useCameraControls(selectAlignWithDirection);
  const addLocalOrientationObject = useCameraControls(selectAddLocalOrientationObject);
  const removeLocalOrientationObject = useCameraControls(selectRemoveLocalOrientationObject);

  useEffect(() => {
    const viewCube = viewCubeRef.current;

    if(viewCube) {
      addLocalOrientationObject(viewCube);
    }

    return () => {
      if(viewCube) {
        removeLocalOrientationObject(viewCube);
      }
    }
  }, [ viewCubeRef, addLocalOrientationObject, removeLocalOrientationObject ] );

  useFrame(() => {
    gl.autoClear = true
    gl.render(scene, camera)
    gl.autoClear = false
    gl.clearDepth()
    gl.render(hudScene, hudCamera.current)
  }, 1)

  const handlePointerMove = useCallback((e) => {
    setHitNormal([ e.face.normal.x, e.face.normal.y, e.face.normal.z ]);
    setOpacity(1);
  }, [ setHitNormal ]);

  const handlePointerOut = useCallback((e) => {
    setHitNormal([0,0,0]);
    setOpacity(.25);
  }, [ setHitNormal ]);

  const handleClick = useCallback((e) => {
    const x = e.face.normal.x;
    const y = e.face.normal.y;
    const z = e.face.normal.z;

    alignWithDirection(x,y,z);

    if(demoOpen && onNextFeaturesSubStep) {
      onNextFeaturesSubStep();
    }
  }, [ alignWithDirection, onNextFeaturesSubStep, demoOpen ]);
//                position={[size.width/2 - 95, size.height/2 - 310, 0]}
  const moveViewCubeDown = (window.innerWidth > window.innerHeight || window.innerWidth < 600) ? 0 : (window.innerHeight-window.innerWidth)*.3;

  return createPortal(
    <>
      <OrthographicCamera ref={hudCamera} position={[0,0,100]} makeDefault={false}/>
      <AnimatedViewCube ref={viewCubeRef}
                raycast={useCamera(hudCamera)}
                hitNormal={displayHitNormal}
                opacity={opacity}
                position={[size.width/2 - 95, size.height/2 - 60 - moveViewCubeDown, 0]}
                scale={[ 20, 20, 20 ]}
                onPointerMove={handlePointerMove}
                onPointerOut={handlePointerOut}
                onClick={handleClick}
                />
    </>,
    hudScene
  );
}

export default function Viewer3D(props) {
  const {
    demo,
    onNextFeaturesSubStep,
    children
  } = props;

  // Caution - React Redux says using ReactReduxContext directly isn't part of their public
  // API, so this could break in the future. 
  // https://react-redux.js.org/using-react-redux/accessing-store
  // The alternative, currently, seems to be to create our own context object and pass it 
  // in directly to all containers as well as the root Provider object. This seems easier 
  // for now and it allows react-three-fiber components to be wrapped in containers just 
  // like normal components. 

  return (
    <Canvas dpr={window.devicePixelRatio} linear flat>
      <color attach="background" args={[ 1, 1, 1 ]}/>
      <Suspense fallback={<LoaderProgress/>}>
        <Environment/>
        {children}
        <HUD demo={demo} onNextFeaturesSubStep={onNextFeaturesSubStep}/>
        <CameraControls/>
      </Suspense>
    </Canvas>
  )
}

