import { createActions } from 'redux-actions';

const actions = createActions({
  UI: {
    DEMO: {
      ENABLE: undefined,
      DISABLE: undefined,
      RESET: undefined,
      PREVIOUS: undefined,
      NEXT: undefined,
      SET: undefined,
      SET_QR: undefined,
      SET_FEATURES_SUB_STEP: undefined,
      NEXT_FEATURES_SUB_STEP: undefined
    }
  }
});

export default actions;
