import { connect } from 'react-redux';
import actions from '../actions';

import GCodePane from '../components/GCodePane';
import { messages as messagesSelectors } from '../selectors';

export default connect(
  (state, ownProps) => {
    const gcodePane = state.ui.gcodePane;
    return {
      width: gcodePane.width,
      visible: gcodePane.visible,
      annotations: messagesSelectors.annotations(state),
      currentLine: state.interpreter.currentLine,
      currentTab: gcodePane.currentTab,
      running: state.interpreter.running,
      hideTitleBar: !state.ui.titleBar.visible,
      fileName: state.interpreter.fileName
    }
  },
  (dispatch, ownProps) => ({
    onScroll: (deltaY) => dispatch(actions.ui.gcodePane.scroll(deltaY)),
    onTabChange: (e,i) => dispatch(actions.ui.gcodePane.setTab(i)),
    onSetCurrentLine: (line) => dispatch(actions.interpreter.setCurrentLine(line)),
    onSetFileName: (name) => dispatch(actions.interpreter.setFileName(name))
  })
)(GCodePane);
