import { useState, useEffect, useCallback, useRef } from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import SaveIcon from '@mui/icons-material/SaveAlt';
import Button from '@mui/material/Button';

import * as ace from 'ace-builds';
import 'ace-builds/src-noconflict/theme-chrome';
import 'ace-builds/src-noconflict/mode-gcode';
import 'ace-builds/src-noconflict/ext-searchbox';

import { InterpreterCache } from '../gcode/interpreter-cache';

const PREFIX = 'Editor';

const classes = {
  container: `${PREFIX}-container`,
  gcodeTitleBar: `${PREFIX}-gcodeTitleBar`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  width: "100%",
  height: "100%",
  [`& .${classes.container}`]: {
    width: "100%",
    height: "calc(100% - 39px)" // 39px is from gcodeTitleBar
  },

  [`& .${classes.gcodeTitleBar}`]: {
    width: "100%",
    fontSize: "15px",
    borderTop: "1px solid #dddddd",
    color: "gray",
    paddingLeft: "5px"
  }
}));

function Editor(props) {
  const {
    fileName,
    running,
    onSetFileName,
    annotations,
    markers,
    currentLine,
    onSetCurrentLine
  } = props;

  const handleFileNameChange = useCallback((e) => {
    if(onSetFileName) {
      onSetFileName(e.target.value);
    }
  }, [ onSetFileName ]);

  const editorRef = useRef();

  const [ editor, setEditor ] = useState(null);

  useEffect(() => {
    const editor = ace.edit(editorRef.current);
    editor.textInput.getElement().name = "textArea"
    setEditor(editor);
  }, [ editorRef, setEditor ]);

  useEffect(() => {
    if(editor && markers) {
      const session = editor.getSession();
      const ids = markers.map((marker) => session.addMarker(new ace.Range(marker.from, 0, marker.to, 1), marker.className, "fullLine"));
      return () => {
        ids.forEach((id) => {
          session.removeMarker(id);
        });
      };
    }
  }, [ editor, markers ]);

  const handleChange = () => {
  };

  useEffect(() => {
    if(editor) {
      const session = editor.getSession();
      const doc = session.getDocument();
      const interpreterCache = InterpreterCache.getInstance();

      interpreterCache.registerEditor(editor);

      session.setMode("ace/mode/gcode");
      editor.setTheme("ace/theme/chrome");
      editor.$blockScrolling = Infinity;
      editor.setShowPrintMargin(false);
      editor.setOptions({
        readOnly: true
      });

      const onBegin = () => {
        doc.off('change', handleChange);
        doc.setValue("");
      };

      const onUpdate = (update) => {
        const {
          lines
        } = update;
        doc.insertFullLines(session.getLength()-1, lines);

        if(running) {
          editor.setOptions({
            readOnly: true
          });
        } else {
          editor.setOptions({
            readOnly: false
          });
        }

        session.setAnnotations(annotations);
      };

      const onEnd = () => {
        doc.on('change', handleChange);
      };

      const onTerminated = () => {
        doc.on('change', handleChange);
      };

      interpreterCache.addEventListener("begin", onBegin);
      interpreterCache.addEventListener("update", onUpdate);
      interpreterCache.addEventListener("end", onEnd);
      interpreterCache.addEventListener("terminated", onTerminated);

      return () => {
        interpreterCache.unregisterEditor();
        interpreterCache.removeEventListener("begin", onBegin);
        interpreterCache.removeEventListener("update", onUpdate);
        interpreterCache.removeEventListener("end", onEnd);
        interpreterCache.removeEventListener("terminated", onTerminated);
      };
    }
  }, [ editor, running, annotations ]);

  useEffect(() => {
    if(editor) {
      if(running) {
        editor.setOptions({
          readOnly: true
        });
      } else {
        editor.setOptions({
          readOnly: false
        });
      }
    }
  }, [ editor, running ]);

  useEffect(() => {
    if(editor) {
      const session = editor.getSession();
      session.setAnnotations(annotations);
    }
  }, [ editor, annotations ]);

  useEffect(() => {
    if(editor) {
      editor.gotoLine(currentLine+1, Infinity);
    }
  }, [ editor, currentLine ]);

  useEffect(() => {
    if(editor) {
      editor.on("click", (e) => {
        const pos = editor.getCursorPosition();

        if(editor.getSelection().isEmpty()) {
          onSetCurrentLine(pos.row);
        }
      });
    }
  }, [ editor, onSetCurrentLine ]);

  const handleDownload = useCallback(() => {
    const gcode = editor.getValue();

    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(gcode));
    element.setAttribute('download', fileName);
    element.click();
  }, [ editor, fileName ]);


  return (
    <Root>
      <div ref={editorRef} className={classes.container}></div>
      <div className={classes.gcodeTitleBar}>
        <TextField variant="standard" disabled={running} value={fileName} onChange={handleFileNameChange} style={ { width: "calc(100% - 70px)" } }/> <Button size="small" onClick={handleDownload} color="inherit"><SaveIcon/></Button>
      </div>
    </Root>
  );
}

export default (Editor);
