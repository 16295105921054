import actions from '../../actions';
import { handleActions } from 'redux-actions';
import produce from 'immer';

const reducer = handleActions(
  {
    [actions.ui.titleBar.show]: produce((state, action) => { state.visible = true }),
    [actions.ui.titleBar.hide]: produce((state, action) => { state.visible = false })
  },
  {
    visible: true
  }
);

export default reducer;
