import { connect } from 'react-redux';

import actions from '../../actions';
import InterpreterBackPlot from '../../components/viewer3d/InterpreterBackPlot';

export default connect(
  (state, ownProps) => ({
    currentTime: state.interpreter.currentTime,

    showBetweenLines: state.viewer3d.showBetweenLines,
    showTimeSlice: state.viewer3d.showTimeSlice,
    showPastOnly: state.viewer3d.showPastOnly,
    showFutureOnly: state.viewer3d.showFutureOnly,

    minLine: state.viewer3d.minLine,
    maxLine: state.viewer3d.maxLine,

    timeSlice: state.viewer3d.timeSlice,

    rapidColor: state.viewer3d.rapidColor,
    feedColor: state.viewer3d.feedColor,

    offset: state.viewer3d.offset
  }),
  (dispatch, ownProps) => ({
    setRapidColor: (color) => dispatch(actions.viewer3d.setRapidColor(color)),
    setFeedColor: (color) => dispatch(actions.viewer3d.setFeedColor(color)),
    setMinLine: (line) => dispatch(actions.viewer3d.setMinLine(line)),
    setMaxLine: (line) => dispatch(actions.viewer3d.setMaxLine(line)),
    setTimeSlice: (time) => dispatch(actions.viewer3d.setTimeSlice(time)),
    setShowBetweenLines: (show) => dispatch(actions.viewer3d.setShowBetweenLines(show)),
    setShowTimeSlice: (show) => dispatch(actions.viewer3d.setShowTimeSlice(show)),
    setShowPastOnly: (show) => dispatch(actions.viewer3d.setShowPastOnly(show)),
    setShowFutureOnly: (show) => dispatch(actions.viewer3d.setShowFutureOnly(show)),
    setOffset: (offset) => dispatch(actions.viewer3d.setOffset(offset))
  })
)(InterpreterBackPlot);
