import React, { useEffect, useMemo, useCallback, useState } from 'react';

import useCallbackRef from '../hooks/useCallbackRef';
import { FEATURES, CAMERA, MAX_SUB_STEPS } from '../constants/demo';
import Popper from '@mui/material/Popper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MobileStepper from '@mui/material/MobileStepper';
import { styled } from '@mui/material/styles';
import classnames from 'classnames';
import Fab from '@mui/material/Fab';
import CameraIcon from '@mui/icons-material/CameraAlt';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { useCameraControls, selectSetActiveParent, selectParents, selectSetOrthographic, selectSetPerspective, selectSetAuto } from './viewer3d/CameraControls';
import { useTheme } from '@mui/material/styles';

const PREFIX = 'CameraButtons';

const classes = {
  button: `${PREFIX}-button`,
  extendedButton: `${PREFIX}-extendedButton`,
  marginLeft: `${PREFIX}-marginLeft`,
  active: `${PREFIX}-active`,
  marginTop: `${PREFIX}-marginTop`,
  cameraButtonsNoTitleBar: `${PREFIX}-cameraButtonsNoTitleBar`,
  cameraButtons: `${PREFIX}-cameraButtons`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.button}`]: {
    width: "30px",
    height: "30px",
    minHeight: "30px",
    opacity: .25,
    transition: "opacity .5s"
  },

  [`& .${classes.extendedButton}`]: {
    width: "110px",
    height: "30px",
    minHeight: "30px",
    opacity: .25,
    transition: "opacity .5s",
    textTransform: "none"
  },

  [`& .${classes.marginLeft}`]: {
    marginLeft: "10px"
  },

  [`& .${classes.active}`]: {
    opacity: 1
  },

  [`& .${classes.marginTop}`]: {
    marginTop: "5px"
  },

  [`& .${classes.cameraButtons}`]: {
    position: "absolute",
    right: "20px",
    top: "20px",
    textAlign: "right",
    zIndex: 10
  }
}));

function CameraButtons(props) {
  const {
    demo,
    onNextDemoStep
  } = props;
  const { enabled, step, featuresSubStep: subStep } = demo || {};
  const demoOpen = enabled && (step === FEATURES && subStep === CAMERA);

  const [ parentMenuOpen, setParentMenuOpen ] = useState(false);
  const [ perspectiveMenuOpen, setPerspectiveMenuOpen ] = useState(false);
  const [ active, setActive ] = useState(false);
  const [ parentAnchorEl, setParentAnchorEl ] = useState(null);
  const [ perspectiveAnchorEl, setPerspectiveAnchorEl ] = useState(null);
  const theme = useTheme();

  const handleClickOutOfMenu = useCallback(() => {
    setParentMenuOpen(false);
    setPerspectiveMenuOpen(false);
  }, [ setParentMenuOpen, setPerspectiveMenuOpen ]);

  const handleMouseOver = useCallback(() => {
    setActive(true);
  }, [ setActive ]);
  const handleMouseOut = useCallback(() => {
    setActive(false);
  }, [ setActive ]);
  const toggleParentMenu = useCallback((e) => {
    setParentMenuOpen(!parentMenuOpen);
    setParentAnchorEl(e.currentTarget);
  }, [ parentMenuOpen, setParentMenuOpen, setParentAnchorEl ]);
  const togglePerspectiveMenu = useCallback((e) => {
    setPerspectiveMenuOpen(!perspectiveMenuOpen);
    setPerspectiveAnchorEl(e.currentTarget);
  }, [ perspectiveMenuOpen, setPerspectiveMenuOpen, setPerspectiveAnchorEl ]);

  const setAuto = useCameraControls(selectSetAuto);
  const setPerspective = useCameraControls(selectSetPerspective);
  const setOrthographic = useCameraControls(selectSetOrthographic);
  const parents = useCameraControls(selectParents);
  const setActiveParent = useCameraControls(selectSetActiveParent);

  const sortedParents = useMemo(() => [ ...parents ].sort((a,b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if(nameA < nameB) {
      return -1
    } else if(nameA > nameB) {
      return 1;
    } else {
      return 0;
    }
  }), [ parents ]);

  const handlePerspective = useCallback(() => {
    setPerspective();
    setPerspectiveMenuOpen(false);
  }, [ setPerspective, setPerspectiveMenuOpen ]);
  const handleAuto = useCallback(() => {
    setAuto();
    setPerspectiveMenuOpen(false);
  }, [ setAuto, setPerspectiveMenuOpen ]);
  const handleOrthographic = useCallback(() => {
    setOrthographic();
    setPerspectiveMenuOpen(false);
  }, [ setOrthographic, setPerspectiveMenuOpen ]);
  const handleParentCamera = useCallback((parent) => {
    setActiveParent(parent);
    setParentMenuOpen(false);
  }, [ setActiveParent, setParentMenuOpen ]);

  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ demoContent, setDemoContent ] = useState(null);

  const id = demoOpen ? "camera-buttons-demo" : undefined;

  const [ containerRef, containerEl ] = useCallbackRef(null);

  useEffect(() => {
    if(subStep === CAMERA) {
      setAnchorEl(containerEl);
      setDemoContent(<><b style={{ color: theme.palette.primary.main }}>Press a face of the View Cube</b> to align the camera to that face.</>);
    }
  }, [ theme, containerEl, subStep, setAnchorEl, setDemoContent]);

  return (
    <Root>
      <div className={classes.cameraButtons} ref={containerRef}>
      <Tooltip disableInteractive title="Camera parenting options. Use these options to move the camera along with the specified parent. Parenting to the tool, for example, can be a helpful way to inspect the movement of the tool in a detailed way. Other options can provide helpful viewing angles to debug a program.">
      <Fab className={classnames(classes.button, { [classes.active]: active })} 
        onMouseOver={handleMouseOver} 
        onMouseOut={handleMouseOut}
        onClick={toggleParentMenu}>
        <GpsFixedIcon/>
      </Fab>
      </Tooltip>
      <br/>
      <Tooltip disableInteractive title="Camera projection options.">
      <Fab className={classnames(classes.button, classes.marginTop, { [classes.active]: active })} 
        onMouseOver={handleMouseOver} 
        onMouseOut={handleMouseOut}
        onClick={togglePerspectiveMenu}>
        <CameraIcon/>
      </Fab></Tooltip><br/>
      <Menu open={parentMenuOpen} anchorEl={parentAnchorEl} onClose={handleClickOutOfMenu}>
        { sortedParents.map((parent) => <MenuItem onClick={() => handleParentCamera(parent)} key={parent.name}>{parent.name}</MenuItem>) }
      </Menu>
      <Menu open={perspectiveMenuOpen} anchorEl={perspectiveAnchorEl} onClose={handleClickOutOfMenu}>
        <Tooltip disableInteractive title="Automatically switch to orthographic projection when aligned with front, back, left, right, top or bottom. Otherwise, use a perspective projection.">
          <MenuItem onClick={() => handleAuto()}>Auto</MenuItem>
        </Tooltip>
        <Tooltip disableInteractive title="Always use a perspective projection, so objects look smaller as they get further away.">
          <MenuItem onClick={() => handlePerspective()}>Perspective</MenuItem>
        </Tooltip>
        <Tooltip disableInteractive title="Always use an orthographic projection, so parallel lines always look parallel regardless of distance.">
          <MenuItem onClick={() => handleOrthographic()}>Orthographic</MenuItem>
        </Tooltip>
      </Menu>
      </div>
    { anchorEl ? 
    <Popper id={id} 
             open={demoOpen} 
             anchorEl={anchorEl} placement="right" 
             sx={{
               paddingBottom: 1,
               zIndex: 2000,
               pointerEvents: "none"
             }}>

    <Box sx={{ position: "relative", backgroundColor: "white", color: "black", borderRadius: "5px", right: "90px", border: "1px solid rgba(100,100,100,0.75)"}}>
      <Box sx={{ padding: 1, width: "300px" }}>
        { demoContent }
        <MobileStepper
          variant="text"
          steps={MAX_SUB_STEPS}
          position="static"
          activeStep={subStep}
          nextButton={<Button onClick={onNextDemoStep}>Exit Demo</Button>}
        />
      </Box>
      <Box sx={{ backgroundColor: "white", width: "12px", height: "12px", position: "absolute", right: "-8px", top: "50%", transform: "translate(0, 0) rotate(45deg) ", borderTop: "1px solid rgba(100,100,100,0.75)", borderRight: "1px solid rgba(100,100,100,0.75)", content: '""' }}/>
    </Box>

    </Popper>
    : null }
    </Root>
  );
}

export default (CameraButtons);
