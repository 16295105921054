import { createActions } from 'redux-actions';

const actions = createActions({
  UI: {
    OPTIONS: {
      EXPAND: undefined,
      COLLAPSE: undefined,
      TOGGLE: undefined
    }
  }
});

export default actions;
