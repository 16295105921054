import React, { useMemo, useRef, useEffect } from 'react'
import { useThree } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei'
import { useCameraControls, X, Y, selectSetUpAndForward, selectAddParent, selectRemoveParent } from './CameraControls';
import { checkLimits } from '../../machines';
import { V1 } from '../../constants/machine-state/machine';

export default function Model({ 
  joints = [ 0, 0, 0, 0, 0 ],
  tool = null,
  toolHolder = "NONE",
  spindleAngle = 0,
  model = null,
  modelOffsets = { X: 0, Y: 0, Z:0, A: 0, B:0, C: 0 },
  children,
  ...props 
}) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/gltf/v1.glb')

  const { scene } = useThree();

  const baseRef = useRef();
  const spindleRef = useRef();
  const tableRef = useRef();
  const trunnionRef = useRef();
  const partRef = useRef();

  const addParent = useCameraControls(selectAddParent);
  const removeParent = useCameraControls(selectRemoveParent);
  const setUpAndForward = useCameraControls(selectSetUpAndForward);

  const jointColors = useMemo(() => joints.map((joint, jointIndex) => checkLimits(V1, jointIndex, joint) ? 0x000000 : 0xaa0000 ), [ joints ]);

  const holder = nodes[toolHolder] ? <primitive object={nodes[toolHolder]} rotation-z={spindleAngle}/> : null;

  useEffect(() => {
    const intensity = 3;
    const intensity2 = 1.25;
    materials.BaseMaterial.envMap = scene.environment;
    materials.BaseMaterial.envMapIntensity = intensity2;

    materials.StepperMaterial.envMap = scene.environment;
    materials.StepperMaterial.envMapIntensity = intensity;

    materials.ButtonRingMaterial.envMap = scene.environment;
    materials.ButtonRingMaterial.envMapIntensity = intensity;

    materials.RailsMaterial.envMap = scene.environment;
    materials.RailsMaterial.envMapIntensity = intensity2;

    materials.ButtonMaterial.envMap = scene.environment;
    materials.ButtonMaterial.envMapIntensity = intensity2;

    materials.ColletMaterial.envMap = scene.environment;
    materials.ColletMaterial.envMapIntensity = intensity;

    materials['ColletMaterial.001'].envMap = scene.environment;
    materials['ColletMaterial.001'].envMapIntensity = intensity;

    materials.XCarriageMaterial.envMap = scene.environment;
    materials.XCarriageMaterial.envMapIntensity = intensity2;

    materials.ZRailMaterial.envMap = scene.environment;
    materials.ZRailMaterial.envMapIntensity = intensity2;

    materials.SpindleMaterial.envMap = scene.environment;
    materials.SpindleMaterial.envMapIntensity = intensity;

    materials.SpindleClearAnnodize.envMap = scene.environment;
    materials.SpindleClearAnnodize.envMapIntensity = intensity2;

    materials.ABackingPlateMaterial.envMap = scene.environment;
    materials.ABackingPlateMaterial.envMapIntensity = intensity;

    materials.ABackingPlateClearAnnodize.envMap = scene.environment;
    materials.ABackingPlateClearAnnodize.envMapIntensity = intensity2;

    materials.TableMaterial.envMap = scene.environment;
    materials.TableMaterial.envMapIntensity = intensity2;

    materials.TrunnionMaterial.envMap = scene.environment;
    materials.TrunnionMaterial.envMapIntensity = intensity;

    const base = { name: "Base", object: baseRef.current };
    const spindle = { name: "Spindle", object: spindleRef.current };
    const table = { name: "Table", object: tableRef.current };
    const part = { name: "Part", object: partRef.current };
    const trunnion = { name: "Trunnion", object: trunnionRef.current };

    addParent(base);
    addParent(spindle);
    addParent(table);
    addParent(trunnion);
    addParent(part);

    setUpAndForward(Y, X);

    return () => {
      removeParent(base);
      removeParent(spindle);
      removeParent(table);
      removeParent(trunnion);
      removeParent(part);
    };
  }, [ addParent, setUpAndForward, removeParent, materials, scene ]);

  return (
    <group ref={group} {...props} dispose={null}>
      <group ref={baseRef}>
        <spotLight intensity={0.15} angle={Math.PI / 8} penumbra={0.15} decay={2} position={[-27.51, 17.11, 13.31]} />
        <spotLight intensity={0.15} angle={Math.PI / 8} penumbra={0.15} decay={2} position={[-22.38, -37.04, 13.82]} />
        <spotLight intensity={0.05} angle={Math.PI / 8} penumbra={0.15} decay={2} position={[23.41, -31.99, 11.74]} />
        <spotLight intensity={0.05} angle={Math.PI / 8} penumbra={0.15} decay={2} position={[45.3, 28.49, 13.89]} />
        <spotLight intensity={0.02} angle={Math.PI / 8} penumbra={0.15} decay={2} position={[49.23, 10.06, -26.13]} />
        <mesh geometry={nodes.V1_Body_1.geometry} material={materials.BaseMaterial} />
        <mesh geometry={nodes.V1_Body_2.geometry} material={materials.StepperMaterial} />
        <mesh geometry={nodes.Button_Ring.geometry} material={materials.ButtonRingMaterial} />
        <mesh geometry={nodes.Rails.geometry} material={materials.RailsMaterial} />
        <mesh geometry={nodes.V1_Yellow_Button.geometry} material={materials.ButtonMaterial} />
        <mesh geometry={nodes.x.geometry} material={materials.XCarriageMaterial} position-x={joints[0]} material-emissive={jointColors[0]}>
          <mesh geometry={nodes.zrail.geometry} material={materials.ZRailMaterial}>
            <group position-z={6+joints[2]} ref={spindleRef}>
              { tool }
              { holder }
              <mesh geometry={nodes.z_1.geometry} material={materials.SpindleMaterial} material-emissive={jointColors[2]} />
              <mesh geometry={nodes.z_2.geometry} material={materials.SpindleClearAnnodize} material-emissive={jointColors[2]} />
            </group>
          </mesh>
        </mesh>
        <group position-y={-joints[1]}>
          <mesh geometry={nodes.y_1.geometry} material={materials.ABackingPlateMaterial} />
          <mesh geometry={nodes.y_2.geometry} material={materials.ABackingPlateClearAnnodize} />
          <group rotation-x={joints[3]*Math.PI/180} ref={trunnionRef}>
            <mesh geometry={nodes.trunnion_1.geometry} material={materials.TrunnionMaterial} material-emissive={jointColors[3]}/>
            <mesh geometry={nodes.trunnion_2.geometry} material={materials.TrunnionClearAnnodize} material-emissive={jointColors[3]}/>
            <mesh geometry={nodes.b.geometry} material={materials.TableMaterial}  rotation-y={joints[4]*Math.PI/180} ref={tableRef} material-emissive={jointColors[4]}>
              <group rotation-y={ -modelOffsets.B*Math.PI/180 }>
                <group rotation-x={ -modelOffsets.A*Math.PI/180 }>
                  <group position={[ modelOffsets.X, modelOffsets.Y, modelOffsets.Z ]}>
                    <group ref={partRef}></group>
                    {model}
                  </group>
                </group>
              </group>
              {children}
            </mesh>
          </group>
        </group>
      </group>
    </group>
  )
}
