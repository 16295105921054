import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import actions from '../actions';

import { FEATURES, OTHER_OPTIONS_OPEN, OTHER_OPTIONS_CLOSE, MAX_SUB_STEPS } from '../constants/demo';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import OtherOptions from '../containers/OtherOptions';
import classnames from 'classnames';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Popper from '@mui/material/Popper';
import useCallbackRef from '../hooks/useCallbackRef';
import { useTheme } from '@mui/material/styles';

const PREFIX = "OtherOptionsPane";

const classes = {
  expanded: `${PREFIX}-expanded`,
  collapsed: `${PREFIX}-collapsed`,
  extendedButton: `${PREFIX}-extendedButton`
};

const StyledDiv = styled('div')(({ theme }) => ({
  [`& .${classes.expanded}`]: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    height: "calc(100vh - 500px)",
    minHeight: 250,
    animation: "$fadeMenuIn .25s linear 1",
    overflow: "scroll",
    border: "1px solid rgba(0,0,0,.25)",
    borderRadius: "8px",
    background: "rgb(243,243,243)",
    opacity: 1
  },
  [`& .${classes.collapsed}`]: {
    opacity: 0,
    height: "0px",
    minHeight: 0,
    animation: "$fadeMenuOut .5s linear 1",
    marginTop: 0,
    padding: 0,
    overflow: "scroll",
    border: "1px solid rgba(0,0,0,.25)",
    borderRadius: "8px",
    background: "rgb(243,243,243)"
  },
  // These animations look like a simple fade in/fade out
  // but the fade out also ends the animation with height/padding/margin at 0
  // so it doesn't take up any screen real estate so mouse/pointer events
  // go through to the canvas. The height transition happens after the element
  // has already faded out completely so the user doesn't see it.
  '@keyframes fadeMenuIn': {
    from: {
      opacity: 0,
      height: "calc(100vh - 500px)",
      minHeight: 250,
      padding: theme.spacing(2),
      marginTop: theme.spacing(1)
    },
    to: {
      opacity: 1,
      minHeight: 250,
      height: "calc(100vh - 500px)",
      padding: theme.spacing(2),
      marginTop: theme.spacing(1)
    }
  },
  '@keyframes fadeMenuOut': {
    "0%": {
      opacity: 1,
      minHeight: 250,
      height: "calc(100vh - 500px)",
      padding: theme.spacing(2),
      marginTop: theme.spacing(1)
    },
    "50%": {
      opacity: 0,
      minHeight: 250,
      height: "calc(100vh - 500px)",
      padding: theme.spacing(2),
      marginTop: theme.spacing(1)
    },
    "100%": {
      opacity: 0,
      minHeight: 0,
      height: "0px",
      padding: 0,
      marginTop: 0
    }
  },
  [`& .${classes.extendedButton}`]: {
    width: "130px",
    height: "30px",
    minHeight: "30px",
    transition: "opacity .5s",
    textTransform: "none"
  },
  [`& .${classes.active}`]: {
    opacity: 1
  },
  [`& .${classes.inactive}`]: {
    opacity: .25
  }
}));

function OtherOptionsPane(props) {
  const {
    demo,
    onNextDemoStep,
    onNextFeaturesSubStep
  } = props;
  const { enabled, step, featuresSubStep: subStep } = demo || {};
  const demoOpen = enabled && (step === FEATURES && subStep >= OTHER_OPTIONS_OPEN && subStep <= OTHER_OPTIONS_CLOSE);

  const theme = useTheme();

  const [ active, setActive ] = useState(false);
  const buttonActive = active || demoOpen;
  const expanded = useSelector((state) => state.ui.options.expanded);
  const dispatch = useDispatch();
  const handleToggleExpand = useCallback(() => {
    dispatch(actions.ui.options.toggle());

    if(demoOpen) {
      if(subStep === OTHER_OPTIONS_OPEN) { 
        onNextFeaturesSubStep()
      } else if(subStep === OTHER_OPTIONS_CLOSE) {
        onNextDemoStep()
      }
    }
  }, [ dispatch, demoOpen, subStep, onNextFeaturesSubStep, onNextDemoStep ]);


  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ demoContent, setDemoContent ] = useState(null);

  const id = demoOpen ? "other-options-pane-demo" : undefined;

  const handleMouseOver = useCallback(() => {
    setActive(true);
  }, [ setActive ]);

  const handleMouseOut = useCallback(() => {
    setActive(false);
  }, [ setActive ]);

  const [ buttonRef, buttonEl ] = useCallbackRef(null);

  useEffect(() => {
    if(subStep === OTHER_OPTIONS_OPEN) {
      setAnchorEl(buttonEl);
      setDemoContent(<><b style={{ color: theme.palette.primary.main }}>Press Show Options</b> to customize the back plot. Try it now to move to the next step.</>);
    } else if(subStep === OTHER_OPTIONS_CLOSE) {
      setAnchorEl(buttonEl);
      setDemoContent(<>In the options pane, you can show the back plot for only specific lines and time frames, as well as change the plot's colors and other miscellaneous options. <b style={{ color: theme.palette.primary.main }}>Press Hide Options</b> to hide the options panel and complete the demo.</>);
    }
  }, [ theme, buttonEl, subStep, setAnchorEl, setDemoContent]);

  return (<StyledDiv sx={{
    position: "absolute",
    right: "20px",
    top: "231px",
    textAlign: "right",
    zIndex: 10
  }}>
    <Button variant="contained" 
            color={ demoOpen ? "primary" : "inherit"}
            className={classnames(classes.extendedButton, { [classes.active]: buttonActive, [classes.inactive]: !buttonActive })}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onClick={handleToggleExpand}
            ref={buttonRef}
            >{ expanded ? "Hide Options" : "Show Options" }</Button>
    <div className={ expanded ? classes.expanded : classes.collapsed }>
      <OtherOptions showAdvanced={true}/> 
    </div>
    { anchorEl ? 
    <Popper id={id} 
             open={demoOpen} 
             anchorEl={anchorEl} placement="right" 
             sx={{
               paddingBottom: 1,
               zIndex: 2000
             }}>

    <Box sx={{ position: "relative", backgroundColor: "white", color: "black", borderRadius: "5px", right: "10px", border: "1px solid rgba(100,100,100,0.75)"}}>
      <Box sx={{ padding: 1, width: "300px" }}>
        { demoContent }
        <MobileStepper
          variant="text"
          steps={MAX_SUB_STEPS}
          position="static"
          activeStep={subStep}
          nextButton={<Button onClick={onNextDemoStep}>Exit Demo</Button>}
        />
      </Box>
      <Box sx={{ backgroundColor: "white", width: "12px", height: "12px", position: "absolute", right: "-8px", top: "50%", transform: "translate(0, 0) rotate(45deg) ", borderTop: "1px solid rgba(100,100,100,0.75)", borderRight: "1px solid rgba(100,100,100,0.75)", content: '""' }}/>
    </Box>

    </Popper>
    : null }
  </StyledDiv>);
}

export default OtherOptionsPane;
